<template>
  <v-container fluid>
    <ForgotPassword
      @sendEmailToUser="sendEmailToUser"
      :type="currentDataType"
      @sendNewPassword="resetNewPassword"
      @goToLogin="goToLogin"
    />
  </v-container>
</template>
<script>
import ForgotPassword from "@components/ForgotPassword/ForgotPassword.vue";
import { resetUserPassword, sendNewPasswordData } from "@/api";
export default {
  name: "ForgotPasswordVue",
  components: {
    ForgotPassword,
  },
  data() {
    return {
      currentDataType: "forgot_password",
    };
  },
  mounted() {
    if (
      (this.$route.path == "/setup/password" ||
        this.$route.path == "/reset/password") &&
      this.$route.query.token
    ) {
      this.currentDataType = "set_password";
    } else {
      this.currentDataType = "forgot_password";
    }
  },
  methods: {
    async sendEmailToUser(email) {
      this.$loader.show();
      try {
        await resetUserPassword({ email: email });
        this.currentDataType = "check_email";
      } catch (e) {
        console.error(e);
      }
      this.$loader.hide();
    },
    async resetNewPassword(data) {
      try {
        const payload = {
          token: this.$route.query.token,
          password: data.newPass,
          password_confirm: data.confirmPass,
        };
        await sendNewPasswordData(payload);
        this.currentDataType = "password_reset";
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async goToLogin() {
      await this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style scoped></style>
