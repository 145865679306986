<template>
  <div>
    <ExSelectPicker
      :pickerLabel="pickerLabel"
      :label="label"
      :searchLabel="searchLabel"
      :items="currencies"
      :value="modelValue"
      :useCode="useCode"
      :isSelectedItemRemove="isSelectedItemRemove"
      @item-selected="handleItemSelected"
      @clear-selection="clearItemSelected"
      :isforceReadonly="isforceReadonly"
      :isShowDefautValue="isShowDefautValue"
    />
  </div>
</template>

<script setup>
import ExSelectPicker from "@components/SelectPicker/ExSelectPicker.vue";
import { defineProps, defineEmits } from "vue";

defineProps({
  modelValue: {
    type: Object,
    required: false,
    default: () => ({
      id: 1,
      name: "United States Dollar",
      code: "USD",
      icon: "https://flagcdn.com/us.svg",
    }),
  },
  pickerLabel: {
    type: String,
    required: false,
    default: "Search Currency",
  },
  label: {
    type: String,
    required: false,
    default: "Select Currency",
  },
  searchLabel: {
    type: String,
    required: false,
    default: "Search Currency",
  },
  useCode: {
    type: Boolean,
    required: false,
    default: true,
  },
  isSelectedItemRemove: {
    type: Boolean,
    required: false,
    default: false,
  },
  isforceReadonly: {
    type: Boolean,
    default: false,
  },
  isShowDefautValue: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["update:modelValue"]);

const currencies = [
  {
    id: 1,
    name: "United States Dollar",
    code: "USD",
    icon: "https://flagcdn.com/us.svg",
  },
  {
    id: 2,
    name: "Euro",
    code: "EUR",
    icon: "https://flagcdn.com/eu.svg",
  },
  {
    id: 3,
    name: "British Pound",
    code: "GBP",
    icon: "https://flagcdn.com/gb.svg",
  },
  {
    id: 4,
    name: "Japanese Yen",
    code: "JPY",
    icon: "https://flagcdn.com/jp.svg",
  },
  {
    id: 5,
    name: "Swiss Franc",
    code: "CHF",
    icon: "https://flagcdn.com/ch.svg",
  },
  {
    id: 6,
    name: "Canadian Dollar",
    code: "CAD",
    icon: "https://flagcdn.com/ca.svg",
  },
  {
    id: 7,
    name: "Australian Dollar",
    code: "AUD",
    icon: "https://flagcdn.com/au.svg",
  },
];

const handleItemSelected = (selectedItem) => {
  emit("item-currency-selected", selectedItem);
};

const clearItemSelected = () => {
  emit("clear-selection")
};
</script>

<style scoped>
::v-deep(.selected-currency-icon img) {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
  margin-left: 0px !important;
  z-index: 1;
}

::v-deep(.v-avatar) {
    overflow: none !important;
}

::v-deep .custom-response-picker { 
  width: 100%;
}
</style>
