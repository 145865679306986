import {
  getListOfRoles,
  getListofResponsibility,
  createRole,
  createResponsibility,
} from "@/api";
import {
  getRoles,
  updateRoles,
  getResponsibilities,
  updateResponsibilities,
} from "@components/helper/localStorageHelper";
export default {
  namespaced: true,
  state: {
    roles: [],
    responsibilities: [],
  },
  mutations: {
    setRoles(state, data) {
      state.roles = data;
    },
    setResponsibilities(state, data) {
      state.responsibilities = data;
    },
  },
  getters: {
    allRoles: (state) => state.roles,
    allResponsibilities: (state) => state.responsibilities,
    getRoleById: (state) => {
      return (roleId) => {
        const role = state.roles.find((role) => role.id == roleId);
        if (!role.code_str) {
          role.code_str = `#${role.code} - ${role.name}`;
        }
        return role;
      };
    },
    getAllRolesAndAttachedResponsibilities: (state) => {
      const rolesWithParentId = state.roles.map((role) => {
        // Check if code_str is null or does not exist
        if (!role.code_str) {
          role.code_str = `#${role.code} - ${role.name}`;
        }
        return {
          ...role,
          parentId: -1,
        };
      });

      const responsibilitiesWithParentId = state.responsibilities.map(
        (responsibility) => {
          const attachedRole = responsibility.attached_roles.find(
            (attachment) =>
              state.roles.some((role) => role.id === attachment.id)
          );
          // Check if code_str is null or does not exist
          if (!responsibility.code_str) {
            responsibility.code_str = `#${responsibility.code} - ${responsibility.name}`;
          }
          return {
            ...responsibility,
            parentId: attachedRole ? attachedRole.id : null,
          };
        }
      );

      return [...rolesWithParentId, ...responsibilitiesWithParentId];
    },
    getResponsibilitiesFromRoleId: (state) => (roleId) => {
      return state.responsibilities
        .filter((responsibility) => {
          return responsibility.attached_roles.some(
            (attachment) => attachment.id === roleId
          );
        })
        .map((responsibility) => {
          // Check if code_str is null or does not exist
          if (!responsibility.code_str) {
            responsibility.code_str = `#${responsibility.code} - ${responsibility.name}`;
          }
          return {
            ...responsibility,
            parentId: roleId,
          };
        });
    },
  },
  actions: {
    async loadRoles({ commit }) {
      try {
        let rolesList = getRoles();
        commit("setRoles", rolesList);
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        await getListOfRoles(accountId, 1, 500)
          .then((response) => {
            if (response.data && response.data.results) {
              const results = response.data.results.map((role) => {
                // Check if code_str is null or does not exist
                if (!role.code_str) {
                  role.code_str = `#${role.code} - ${role.name}`;
                }
                return {
                  ...role,
                };
              });
              updateRoles(results);
              commit("setRoles", results);
            }
          })
          .catch((error) => {
            console.error("error fetching employees: ", error);
          });
      } catch (e) {
        console.log("error fetching employees: ", e);
      }
    },
    async loadResponsibilities({ commit }) {
      try {
        let responsibilitiesList = getResponsibilities();
        commit("setResponsibilities", responsibilitiesList);
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        await getListofResponsibility(accountId, 1, 500)
          .then((response) => {
            if (response.data && response.data.results) {
              const results = response.data.results.map((responsibility) => {
                // Check if code_str is null or does not exist
                if (!responsibility.code_str) {
                  responsibility.code_str = `#${responsibility.code} - ${responsibility.name}`;
                }
                return {
                  ...responsibility,
                };
              });
              updateResponsibilities(results);
              commit("setResponsibilities", results);
            }
          })
          .catch((error) => {
            console.error("error fetching employees: ", error);
          });
      } catch (e) {
        console.log("error fetching employees: ", e);
      }
    },
    async createRole(context, { role, accountId }) {
      try {
        const { data } = await createRole(accountId, role);
        if (data) {
          return data;
        }
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async createResponsibility(context, { name, accountId }) {
      try {
        const payload = {
          name: name,
          account: accountId,
        };
        const newRes = await createResponsibility(accountId, payload);
        return newRes;
      } catch (e) {
        console.log("e: ", e);
      }
    },
  },
};
