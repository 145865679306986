import { computed } from "vue";

import { usePayRangeViewState } from "@/views/PayRanges/composables/usePayRangeViewState";
import { useDropdownItems } from "@/composables/dropdown/useDropdownItems";

export function useTableConfig() {
  const viewState = usePayRangeViewState();
  const dropdownItems = useDropdownItems();

  const tableParams = computed(() => {
    return {
      headers: [
        {
          text: "Pay range name/location/currency/recurrence",
          sortable: true,
          value: "",
        },
        {
          text: "Pay Range",
          sortable: false,
          value: "",
        },
        {
          text: "Used",
          sortable: false,
          value: "",
        },
        {
          text: "Status",
          sortable: false,
          value: "",
        },
        {
          text: "",
          sortable: false,
          value: "",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      columns: [
        {
          field: "",
          component: "PayRangeHeader",
          props: (item) => ({
            class: "my-4",
            ...viewState.getPayRangeHeaderProps(item),
          }),
          events: () => ({}),
        },
        {
          field: "",
          component: "PayRangeLine",
          props: (item) => ({
            class: "my-4",
            ...viewState.getPayRangeLineProps(item),
          }),
          events: () => ({}),
        },
        {
          field: "used",
          component: null,
          props: () => ({
            class: "my-4",
          }),
          events: () => ({}),
        },
        {
          field: "",
          component: "Badge",
          props: (item) => ({
            class: "my-4",
            ...viewState.getUserStatusBadgeProps(item),
          }),
          events: () => ({}),
        },
        {
          field: "",
          component: "PayRangeListButton",
          props: () => ({
            class: "my-4",
          }),
          events: () => ({}),
        },
        {
          field: "",
          component: "Dropdown",
          props: (item) => ({
            "data-testid": "employee-operations-menu",
            "translate-x-offset": 12,
            "translate-y-offset": 30,
            "is-open": false,
            "dropdown-items": dropdownItems.getEmployeeDropdownItems(item),
          }),
          events: (item) => ({
            onClick: async (operation) => {
              await viewState.handleEmployeeDropdownOperation(operation, item);
            },
          }),
        },
      ],
      filters: {
        values: viewState.state.filters.values,
        searchText: viewState.state.filters.searchText,
      },
      hideTopSection: false,
      hideActiveNo: true,
      activeRecords: {
        field: "",
        value: "  ",
      },
      actionLabel: "Add Pay Range",
      title: "Pay Ranges",
      getRowStyle: (item) => ({
        backgroundColor:
          item.position?.data?.status === "active" ? "#ECFDF3" : "#FFFFFF",
      }),
    };
  });
  return {
    tableParams,
  };
}
