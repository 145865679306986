import { useFiltersStore } from "@/store/pinia/filters";

export function useFilters() {
  const filtersStore = useFiltersStore();

  const setFilters = ({ key, filters }) => {
    filtersStore.setFilters({ key, filters });
  };

  const getFilters = (key) => {
    filtersStore.loadFilters(key);
    return filtersStore.filters;
  };

  return {
    setFilters,
    getFilters,
  };
}
