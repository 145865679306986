var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ex-proficiency-weight",class:{
  sliderWidthIcon: _vm.sliderIcon,
  sliderWidth: !_vm.sliderIcon,
  sliderWidthImportanceSelector: _vm.importanceSelector,
}},[_c('v-slider',{class:{
  customSlider: true,
  customSliderImportanceSelector: _vm.importanceSelector && !_vm.sliderIcon,
},attrs:{"thumb-label":_vm.thumbLabel,"min":_vm.minRange,"max":_vm.maxRange,"step":_vm.stepDivider,"thumb-size":0},on:{"change":function($event){return _vm.handleSliderChange(_vm.sliderValue)}},scopedSlots:_vm._u([(_vm.sliderIcon)?{key:"prepend",fn:function(){return [_c('Icon',{attrs:{"name":"sidebar_proficiency"}})]},proxy:true}:null,(_vm.sliderIcon)?{key:"append",fn:function(){return [_c('Icon',{attrs:{"name":"sidebar_knowledge"}})]},proxy:true}:null],null,true),model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}},[(_vm.importanceSelector)?_c('template',{slot:"thumb-label"},[_c('div',{staticClass:"importance-div"},[_c('Importance',{attrs:{"importance":_vm.sliderValue}})],1)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }