var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"text-medium-emphasis owner-label-name"},[_vm._v(_vm._s(_vm.label)+"*")]),_c('v-select',{ref:"selectRef",staticClass:"onwership-picker",attrs:{"outlined":"","menu-props":_vm.menuProps,"append-icon":_vm.isListVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'},on:{"click:append":_vm.toggleDropdown,"focus":_vm.onInputFocus},scopedSlots:_vm._u([(!_vm.selectedAccountingSubjects)?{key:"prepend-inner",fn:function(){return [_c('Icon',{attrs:{"name":"employee_search"}})]},proxy:true}:null,{key:"label",fn:function(){return [(_vm.isInputFocused)?_c('label',{staticClass:"label-class-owner"},[_vm._v(" Pick a user ")]):_vm._e(),(!_vm.isInputFocused && !_vm.selectedAccountingSubjects)?_c('label',{staticClass:"label-class-owner"},[_vm._v(" Pick a user ")]):_vm._e()]},proxy:true},{key:"append",fn:function(){return [(_vm.selectedAccountingSubjects)?_c('v-icon',{on:{"click":_vm.clearSelection}},[_vm._v(" mdi-close ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedAccountingSubjects),callback:function ($$v) {_vm.selectedAccountingSubjects=$$v},expression:"selectedAccountingSubjects"}}),(_vm.isListVisible)?_c('v-list',{staticClass:"owner-custom-list",class:{
      'owner-custom-list-change': _vm.isInputFocused 
    }},[_c('v-list-item',[_c('div',{staticClass:"search-container-owner"},[_c('ExTextInput',{staticClass:"owner-search",attrs:{"ghostText":`Search a user`,"placeholder":"","icon":"interface_search","showDownUpChevron":false},on:{"keydown":_vm.onKeyDown},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]),_c('div',{staticClass:"ownership-list-view"},[_vm._l((_vm.filteredAccountingSubjects),function(item,index){return _c('v-list-item',{key:item.id,staticClass:"owner-badge-list",class:{ 'selected-item-owner': _vm.isSelected(item.id) },on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-list-item-content',{staticClass:"content-item-owner"},[_c('v-list-item-title',{staticClass:"content-title-owner"},[_c('div',{staticClass:"title-wrapper-owner"},[_c('div',{staticClass:"main-wrap"},[_c('span',[_vm._v(_vm._s(item.fullName))]),_c('span',{staticClass:"item-id-owner"},[_vm._v(_vm._s(item.email))])]),_c('div',[(_vm.isSelected(item.id))?_c('img',{staticClass:"right-icon-owner",attrs:{"src":require('@components/assets/rightIcon.svg'),"alt":"icon"}}):_vm._e()])])])],1)],1)}),(_vm.filteredAccountingSubjects.length === 0)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"no-text"},[_vm._v(" Nothing matching your search ")])],1)],1):_vm._e()],2)],1):_vm._e(),_c('div',{staticClass:"selected-item-display",class:{
      'selected-item-dis': _vm.selectedAccountingSubjects && _vm.isListVisible,
      'selected-item-dis1': _vm.selectedAccountingSubjects && _vm.isListVisible && _vm.filteredAccountingSubjects.length === 1,
      'selected-item-dis2': _vm.selectedAccountingSubjects && _vm.isListVisible && _vm.filteredAccountingSubjects.length === 2,
      'selected-item-dis3': _vm.selectedAccountingSubjects && _vm.isListVisible && _vm.filteredAccountingSubjects.length === 0,
    },on:{"click":_vm.toggleMenu}},[(_vm.selectedAccountingSubjects)?[_c('Icon',{attrs:{"name":"employee_search"}}),_c('div',{staticClass:"select-item-owner ml-20"},[_vm._v(" "+_vm._s(_vm.selectedUser.fullName)+" "),_c('span',{staticClass:"item-id-owner ml-4"},[_vm._v(_vm._s(_vm.selectedUser.email))])])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }