var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"capacity_icon"},[_c('div',{staticClass:"square",class:{
      bg_grey: _vm.fte === 0.5 && _vm.type === 'half-filled',
      bg_grey_2: _vm.fte === 2,
      bg_grey_1: _vm.fte === 1,
      bg_empty: _vm.fte === 0.5 && _vm.type === 'empty',
      bg_filled: _vm.fte === 0.5 && _vm.type === 'filled'
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }