import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListOfPositions = async (account_id, page_no, size) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/position/`,
    params: { page: page_no, size: size },
  });
  return result;
};
export const createPosition = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position/`,
    data: data,
  });
  return result;
};
export const updatePosition = (account_id, position_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/position/${position_id}/`,
    data: data,
  });
  return result;
};
export const addPositionAssignment = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position-assignment/add/`,
    data: data,
  });
  return result;
};
export const deletePosition = (account_id, position_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/position/${position_id}/`,
  });
  return result;
};
export const attachRoleToPosition = (account_id, roleId, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position/${roleId}/attach/`,
    data: data,
  });
  return result;
};
export const getPositionChatter = (account_id, position_id, page_no) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/position/${position_id}/chatter/`,
    params: { page: page_no },
  });
  return result;
};
export const deActivatePosition = (account_id, position_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position/${position_id}/de-activate/`,
  });
  return result;
};
export const reActivatePosition = (account_id, position_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position/${position_id}/re-activate/`,
  });
  return result;
};
export const retirePosition = (account_id, position_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/position/${position_id}/retire/`,
  });
  return result;
};
export const archivePosition = (account_id, objType, orgId) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/${objType}/${orgId}/archive/`,
  });
  return result;
};
export const unArchivePosition = (account_id, objType, orgId) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/${objType}/${orgId}/unarchive/`,
  });
  return result;
};
