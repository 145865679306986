import { render, staticRenderFns } from "./TerminateEmployee.vue?vue&type=template&id=d161e866&scoped=true"
import script from "./TerminateEmployee.vue?vue&type=script&lang=js"
export * from "./TerminateEmployee.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d161e866",
  null
  
)

export default component.exports