import { reactive } from "vue";
import { useFilters } from "@/composables/filters/useFilters";

let instance = null;

export function usePayRangeViewState() {
  if (instance) return instance;
  const filter = useFilters();
  const state = reactive({
    filters: {
      values: [],
      searchText: "",
      showFilterDialog: false,
      key: "payRangeList",
      clearSelection: "",
      statusOptions: ["draft", "active", "terminated"],
      filterGroups: [
        {
          label: "Archived",
          items: [
            { label: "No Archived", value: "no_archived" },
            { label: "No Filter", value: "no_filter" },
            { label: "Show Archived", value: "show_archived" },
          ],
        },
      ],
    },
    loading: {
      table: false,
      assignmentWizard: false,
      relieveAssignment: false,
    },
    addPayRangeModal: {
      show: false,
      message: "",
    },
    table: {
      selectedEmployees: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
  });

  const applyFilters = (filters) => {
    const newArray = filters
      .map((item) => {
        if (item.value !== null) {
          if (typeof item === "string") {
            return item;
          } else if (
            typeof item === "object" &&
            // eslint-disable-next-line no-prototype-builtins
            item.hasOwnProperty("value")
          ) {
            return item.value;
          }
        }
      })
      .filter(Boolean);
    filter.setFilters({ key: state.filters.key, filters: newArray });
    state.filters.values = newArray;
    state.filters.showFilterDialog = false;
  };

  const removeFilters = (removedFilters) => {
    filter.setFilters({
      key: state.filters.key,
      filters: state.filters.values,
    });
    state.filters.clearSelection = removedFilters;
  };

  const getPayRangeHeaderProps = (payRange) => {
    return {
      countryFlag: payRange.pay_range_header.country_flag,
      payRangeName: payRange.pay_range_header.name,
      payRangeLocation: payRange.pay_range_header.location,
      payRangeExpressedAs: payRange.pay_range_header.pay_range_expressed_as,
      payRangeCurrencyCode: payRange.pay_range_header.pay_range_currency_code,
    };
  };

  const getPayRangeLineProps = (payRange) => {
    return {
      expressed: payRange?.pay_range_line?.expressed,
      zeroPenetration: payRange?.pay_range_line?.zeroPenetration,
      oneThirdPenetration: payRange?.pay_range_line?.oneThirdPenetration,
      twoThirdPenetration: payRange?.pay_range_line?.twoThirdPenetration,
      fullPenetration: payRange?.pay_range_line?.fullPenetration,
    };
  };

  const getUserStatusBadgeProps = (payRange) => {
    return {
      status: payRange.status,
    };
  };

  const getEmployeeAssigmentTagProps = {
    getTypeAssignmentTag: (positionData) => {
      if (positionData.vacant) {
        return "vacant";
      } else if (positionData.occupied) {
        return "occupied";
      } else if (positionData.blocked) {
        return "blocked";
      } else if (positionData.not_allowed) {
        return "not allowed";
      }
    },
    getBasicInfo: (item) => ({
      firstName: item.first_name,
      lastName: item.last_name,
      photoUrl: item.avatar,
      addHoverEffect: true,
    }),

    getPositionInfo: (item) => ({
      positionCode: item.primary_positions[0]?.code.split(" ")[0] || null,
      positionName: item.primary_positions[0]?.name,
      fte:
        item.primary_positions.length > 0
          ? item.primary_positions[0].allocation
          : null,
    }),

    getTypeInfo: (item) => ({
      type:
        item.current_status !== "active"
          ? "not allowed"
          : item.primary_positions?.length > 0
          ? getEmployeeAssigmentTagProps.getTypeAssignmentTag(
              item.primary_positions[0]
            )
          : "no primary assignment",
    }),
  };

  // Métodos para filtros
  const updateFilter = (filterName, value) => {
    if (filterName in state.filters) {
      state.filters[filterName] = value;
    }
  };

  const clearFilters = () => {
    state.filters = {
      status: "all",
      department: null,
      position: null,
    };
  };

  // Métodos para loading
  const setLoading = (key, value) => {
    if (key in state.loading) {
      state.loading[key] = value;
    }
  };

  instance = {
    state,
    applyFilters,
    removeFilters,
    updateFilter,
    clearFilters,
    setLoading,
    getPayRangeHeaderProps,
    getPayRangeLineProps,
    getUserStatusBadgeProps,
  };
  return instance;
}
