import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getAvailableApps = (account_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}`,
  });
  return result;
};
