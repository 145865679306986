<template>
  <div class="badge-container" data-testid="badge-container">
    <h1 class="badge-header-text">Be Master/Coach</h1>
    <EvaluationsList
      :items="items"
      :pendingHeaders="pendingHeaders"
      :pendingData="pendingData"
      :acceptHeaders="acceptHeaders"
      :acceptData="acceptData"
      class="badge-eval-view mt-5"
    />
  </div>
</template>
<script>
import EvaluationsList from "@components/EvaluationMenu/EvaluationsList.vue";
export default {
  name: "BeMasterCoachView",
  components: {
    EvaluationsList,
  },
  data: () => ({
    items: [
      { title: "Pending Proficiency Evaluations", count: 2 },
      { title: "Mastered Responsibilities", count: 15 },
    ],
    pendingHeaders: [
      {
        text: "Employee",
        sortable: false,
        value: "employee",
      },
      {
        text: "Responsibility",
        sortable: false,
        value: "responsibility",
      },
      {
        text: "Requested on",
        sortable: false,
        value: "requestedon",
      },
      {
        text: "Requested By",
        sortable: false,
        value: "requestedby",
        centerField: true,
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
      {
        text: "Last message",
        sortable: false,
        value: "lastmessage",
      },
    ],
    pendingData: [
      {
        id: 1,
        employee: {
          first_name: "Olivia",
          last_name: "Rhye",
          title: "HR Manager",
          avatar: "",
          availability: 1,
        },
        responsibility: {
          code: "12",
          name: "Test",
          type: "responsibility",
        },
        requestedOn: "01/05/2024",
        requestedBy: {
          id: 1,
          first_name: "Olivia",
          last_name: "Rhye",
          avatar: "",
        },
        lastMessage:
          "Abandoned by coach John Doe on 1-Dec-2027 because the coach lost coaching accreditation",
      },
      {
        id: 2,
        employee: {
          first_name: "Olivia",
          last_name: "Rhye",
          title: "HR Manager",
          avatar: "",
          availability: 1,
        },
        responsibility: {
          code: "12",
          name: "Test",
          type: "responsibility",
        },
        requestedOn: "01/05/2024",
        requestedBy: {
          id: 1,
          first_name: "Olivia",
          last_name: "Rhye",
          avatar: "",
        },
        lastMessage:
          "Abandoned by  coach John Doe on 1-Dec-2027 because the coach lost coaching accreditation",
      },
      {
        id: 3,
        employee: {
          first_name: "Olivia",
          last_name: "Rhye",
          title: "HR Manager",
          avatar: "",
          availability: 1,
        },
        responsibility: {
          code: "12",
          name: "Test",
          type: "responsibility",
        },
        requestedOn: "01/05/2024",
        requestedBy: {
          id: 1,
          first_name: "Olivia",
          last_name: "Rhye",
          avatar: "",
        },
        lastMessage:
          "Abandoned by  coach John Doe on 1-Dec-2027 because the coach lost coaching accreditation",
      },
    ],
    acceptHeaders: [
      {
        text: "Employee",
        sortable: false,
        value: "employee",
      },
      {
        text: "Responsibility",
        sortable: false,
        value: "responsibility",
      },
      {
        text: "Current Badge",
        sortable: false,
        value: "currentbadge",
        centerField: true,
      },
      {
        text: "Requested on",
        sortable: false,
        value: "requestedon",
      },
      {
        text: "Requested By",
        sortable: false,
        value: "requestedby",
        centerField: true,
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    acceptData: [
      {
        id: 1,
        employee: {
          first_name: "Olivia",
          last_name: "Rhye",
          title: "HR Manager",
          avatar: "",
          availability: 1,
        },
        responsibility: {
          code: "12",
          name: "Test",
          type: "responsibility",
        },
        requestedOn: "01/05/2024",
        currentBadge: {
          level: "Professional",
          dot: "Forced",
          view: "ReadOnly",
          expiration_date: null,
          evalRequest: "Not pending",
        },
        requestedBy: {
          first_name: "Olivia",
          last_name: "Rhye",
          avatar: "",
        },
      },
    ],
  }),
};
</script>
<style scoped>
.badge-container {
  width: 100%;
  padding: 25px;
}
.badge-header-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #101828;
}
::v-deep .badge-eval-view .eval-view .pending-alert {
  width: 97%;
  margin: auto;
  margin-top: 15px;
}
::v-deep .badge-eval-view .eval-table {
  padding: 15px;
}
</style>
