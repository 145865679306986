<template>
  <v-card
      flat
      class="ex-universal-table-container"
  >
    <div class="d-flex flex-column">
      <div v-if="!config?.hideTopSection" class="d-flex py-6 justify-space-between align-center">
        <div class="d-flex align-center">
          <span class="text-title mr-2 align-self-end">{{ config?.title || '' }}</span>
          <div class="active-badge-parent" v-if="items && items.length > 0">
            <span v-if="!config?.hideActiveNo" class="active-badge">
              {{ items && items.length > 0 && config?.activeRecords?.field && config?.activeRecords?.value ? items.filter(el => el[config?.activeRecords?.field] == config?.activeRecords?.value).length : 0 }} active
            </span>
          </div>
        </div>
      </div>
      <div
          class="table-content"
          :class="{ 'empty-background-pattern': isEmpty, 'py-8': isEmpty }"
      >
        <NoRecordFound
            v-if="items.length === 0 && (config.filters?.searchText.length > 0 || config.filters?.values.length > 0)"
            :title="`${props.emptyObjectData.plural}`"
            :message="`${props.emptyObjectData.plural}`"
            :btnTitle="`${props.emptyObjectData.singular}`"
            @onAction="$emit('onActionClick')"
            class="no-record-found"
            :clearLabel="clearState.label"
            :showClearAction="true"
            :description="clearState.description"
            :noBorder="true"
            @onClear="$emit('onSearch', ''); $emit('removeAllFilters')"
            :showActiveCounter="false"
        />
        <StandarPageWithListNoData v-if="isEmpty">
          <template #content>
            <div
                class="d-flex flex-column align-center"
            >
              <p class="mb-2 empty-content-title">No {{ props.emptyObjectData.plural }} found</p>
              <p class="text-center mb-8 empty-content-description">
                You need some {{ props.emptyObjectData.plural.toLowerCase() }} in your <br />
                organization
              </p>
              <Button
                  class="mb-6"
                  :plusIcon="'add-icon.svg'"
                  :general="true"
                  :label="`Add ${props.emptyObjectData.singular}`"
                  @onClick="$emit('onActionClick')"
              ></Button>
            </div>
          </template>
        </StandarPageWithListNoData>
        <div v-if="items.length > 0" class="px-5 mb-5 d-flex align-start">
          <SearchBar
              type="button"
              :label="config.filters?.values.length > 0 ? 'More Filters' : 'Filters'"
              :length="config.filters?.values.length"
              :chipText="config.filters?.values"
              ghostText="Search"
              :add="false"
              :valueSearch="config.filters?.searchText"
              @onSearch="emit('onSearch', $event)"
              @onClick="emit('onClick')"
              @removeFilter="emit('removeFilter', $event)"
              @clearSelection="toggleSelectAll(false, true)"
              class="flex-grow-1 mr-2"
              removeColumnClass
              :itemsSelectedNumber="selectedItems.length"
          />
          <Button
              v-if="!isMultiSelectMode"
              :icon="'add-icon.svg'"
              @onClick="$emit('onActionClick')"
              :normal="'normal-active'"
              :label="config?.actionLabel || 'Add Record'"
              class="mt-3"
          ></Button>
          <BulkActionsDropdown v-if="isMultiSelectMode" :disabled="selectedItems.length === 0" class="bulk-dropdown"/>
          <div class="d-flex align-center ml-2 mt-3">
            <UniversalTableViewModeSelector v-model="isMultiSelectMode"/>
          </div>
        </div>
        <div v-if="items.length > 0" class="d-flex flex-column">
          <v-data-table
              :headers='getTableHeaders'
              :items="paginatedItems"
              :items-per-page="-1"
              hide-default-footer
              hide-default-header
              class="ex-universal-table"
              :class="{ 'disable-mouse-events': isMultiSelectMode }"
              :loading="loading"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
              <tr>
                <th v-if="isMultiSelectMode" class="checkbox-column">
                  <v-btn
                      dense
                      v-if="selectAll"
                      icon
                      color="#D0D5DD"
                      @click="toggleSelectAll(false)"
                  >
                    <v-icon class="toggle-icon" color="transparent">$checkboxOn</v-icon>
                  </v-btn>
                  <v-btn
                      dense
                      v-else
                      icon
                      color="#D0D5DD"
                      @click="toggleSelectAll(true)"
                  >
                    <v-icon class="toggle-icon" color="transparent">$checkboxOff</v-icon>
                  </v-btn>
                </th>
                <th
                    v-for="(item, index) in headers"
                    :key="`header-${index}`"
                    :class="{
                      'highlight-table-header': item.sortable,
                      'curren-badge-header': item.centerField
                    }"
                >
                  <template v-if="item.img">
                    <div class="d-flex align-center">
                      <img class="mr-2" :src="require(`@components/assets/${item.img}`)" />
                      <span class="header-text text-xs">{{ item.text }}</span>
                    </div>
                  </template>
                  <template v-else-if="item.multiple">
                    <div class="d-flex">
                      <div
                          class="d-flex mr-3 align-center"
                          v-for="(dataItem, index) in item.data"
                          :key="`${dataItem.text}-${index}`"
                      >
                        <img v-if="dataItem.img" class="mr-2" :src="require(`@components/assets/${dataItem.img}`)" />
                        <span class="header-text text-xs">{{ dataItem.text }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <span class="header-text text-xs">{{ item.text }}</span>
                  </template>
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody v-if="items && items.length > 0">
              <tr
                  v-for="(item, rowIndex) in items"
                  :key="`row-${item.id || rowIndex}-${getItemVersion(item)}`"
                  :class="{ 'selected-row': item.isSelected }"
                  :style="getRowStyle(item)"
              >
                <td v-if="isMultiSelectMode" class="checkbox-column">
                  <v-btn
                      dense
                      v-if="item.isSelected"
                      icon
                      color="#D0D5DD"
                      @click="handleItemSelection(item, false)"
                  >
                    <v-icon class="toggle-icon" color="transparent">$checkboxOn</v-icon>
                  </v-btn>
                  <v-btn
                      dense
                      v-else
                      icon
                      color="#D0D5DD"
                      @click="handleItemSelection(item, true)"
                  >
                    <v-icon class="toggle-icon" color="transparent">$checkboxOff</v-icon>
                  </v-btn>
                </td>
                <td
                    v-for="(column, colIndex) in getTableColumns"
                    :key="`col-${colIndex}`"
                >
                  <template v-if="column.component">
                    <keep-alive :max="50">
                      <div>
                        <component
                            v-if="loadedComponents[column.component]"
                            :is="loadedComponents[column.component]"
                            :key="`${item.id}-${getItemVersion(item)}`"
                            v-bind="getComponentProps(column, item)"
                            v-on="getComponentEvents(column, item)"
                        >
                          <template
                              v-for="slotName in getAvailableSlots(column.component)"
                              :slot="slotName"
                          >
                            <slot
                                :name="`${column.component}-${slotName}`"
                                v-bind="getSlotProps(column, item, slotName)"
                            >
                              <template v-if="column.slots && column.slots[slotName]">
                                <component
                                    :is="column.slots[slotName]"
                                    v-bind="getSlotProps(column, item, slotName)"
                                />
                              </template>
                            </slot>
                          </template>
                        </component>
                      </div>
                    </keep-alive>
                  </template>
                  <template v-else>
                    <span>{{ item[column.field] }}</span>
                  </template>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="showPagination" class="d-flex justify-space-between align-center px-6 py-4">
            <Button
                :icon="'arrow-icon.svg'"
                @onClick="onPreviousPage"
                size="small"
                :outline="'outline-active'"
                label="Previous"
                :class="{'prev-hide': !showPreviousButton}"
            />
            <v-pagination
                class="data-table-pagination"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                @input="onChangePage"
            />
            <Button
                :rightIcon="'right-arrow-icon.svg'"
                @onClick="onNextPage"
                size="small"
                :outline="'outline-active'"
                label="Next"
                :class="{'next-hide': !showNextButton}"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted, watch, defineProps, defineEmits, nextTick } from 'vue'
import Button from "@components/buttons/Button.vue";
import SearchBar from "@components/SearchBar/SearchBar.vue";
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import BulkActionsDropdown from "@components/BulkActions/BulkActionsDropdown.vue";
import UniversalTableViewModeSelector from "@components/UniversalTable/UniversalTableViewModeSelector.vue";
import StandarPageWithListNoData from "@components/common/StandarPageWithListNoData.vue";

const ITEMS_PER_PAGE = 10;

const isMultiSelectMode = ref(false);
const selectAll = ref(false);

const itemsWithSelection = ref([])
const isLoading = ref(true);
const loadedComponentCount = ref(0);
const totalRequiredComponents = ref(0);

const bulkActions = ref([
  { text: 'Delete Selected', value: 'delete' },
  { text: 'Change Status', value: 'status' }
])

watch(() => props.items, (newItems) => {
  itemsWithSelection.value = newItems.map(item => ({
    ...item,
    isSelected: false
  }))
}, { immediate: true, deep: true })

const selectedItems = computed(() => {
  return itemsWithSelection.value.filter(item => item.isSelected)
})

const tableWidth = ref(0)
let resizeObserver = null

const tableWidthCSS = computed(() => {
  return `${400}px`
})

const getTableHeaders = computed(() => {
  return props.config.headers.filter((el) => {
    if (isMultiSelectMode.value) {
      return el.value !== "actions";
    } else {
      return true;
    }
  })
})

const getTableColumns = computed(() => {
  return props.config.columns.filter((el) => {
    if (isMultiSelectMode.value) {
      return el.component !== "Dropdown";
    } else {
      return true;
    }
  })
})

const COMPONENT_SLOTS = {
  'Dropdown': ['activator'],
}

const COMPONENT_MAP = {
  'EmployeeCard': () => import('@components/organizations/employees/EmployeeCard.vue'),
  'EmployeeAssigmentTag': () => import('@components/organizations/positions/EmployeeAssigmentTag.vue'),
  'EmployeeBadge': () => import('@components/organizations/employeeStatusBadge/EmployeeBadge.vue'),
  'Dropdown': () => import('@components/DropDownMenu/DropDownMenu.vue'),
  'PositionCard': () => import('@components/organizations/positions/PositionCard.vue'),
  'PrimaryPositionOccupationTag': () => import('@components/organizations/positions/PrimaryPositionOccupationTag.vue'),
  'StatusChip': () => import('@components/organizations/common/StatusChip.vue'),
  'PayRangeHeader': () => import('@components/PayRangeHeader/PayRangeHeader.vue'),
  'Badge': () => import('@components/organizations/statusBadge/Badge.vue'),
  'PayRangeLine': () => import('@components/PayRangeLine/PayRangeLine.vue'),
  'PayRangeListButton': () => import('@components/PayRangeListButton/PayRangeListButton.vue')
}

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  useStatusRowColoring: {
    type: Boolean,
    default: false,
  },
  noHoverEffect: {
    type: Boolean,
    default: false,
  },
  emptyObjectData: {
    type: Object,
    default: {
      singular: "Employee",
      plural: "Employees"
    },
  }
})

const emit = defineEmits(['onActionClick', 'onSearch', 'onClick', 'removeFilter', 'clearSearch', 'removeAllFilters'])

const currentPage = ref(1)
const loadedComponents = ref({})
const loadingComponents = ref(new Set())
const itemsVersionMap = ref(new Map())
const isInitialized = ref(false);

const isEmpty = computed(() => {
  return props.items.length === 0 && props.config.filters?.searchText.length === 0 && props.config.filters.values.length === 0
})

// Computed properties for pagination
const showPagination = computed(() => {
  return !props.config.filters?.searchText && props.items.length > ITEMS_PER_PAGE
})

const totalPages = computed(() => {
  if (props.config.filters?.searchText) return 1
  return Math.ceil(props.items.length / ITEMS_PER_PAGE)
})

const showPreviousButton = computed(() => {
  return showPagination.value && currentPage.value > 1
})

const showNextButton = computed(() => {
  return showPagination.value && currentPage.value < totalPages.value
})

const paginatedItems = computed(() => {
  if (isLoading.value || !isInitialized.value) return []

  if (props.config.filters?.searchText) return itemsWithSelection.value

  const start = (currentPage.value - 1) * ITEMS_PER_PAGE
  const end = start + ITEMS_PER_PAGE
  return itemsWithSelection.value.slice(start, end)
})

const handleItemSelection = (item, event) => {
  const index = itemsWithSelection.value.findIndex(i => i.id === item.id)
  if (event) {
    if (index > -1) {
      item.isSelected = true;
      itemsWithSelection.value.splice(index, item);
    }
  } else {
    if (index > -1) {
      item.isSelected = false;
      itemsWithSelection.value.splice(index, item);
    }
  }
}

const toggleSelectAll = (event, all = false) => {
  if (event) {
    if (!all) {
      const start = (currentPage.value - 1) * ITEMS_PER_PAGE
      const end = start + ITEMS_PER_PAGE
      itemsWithSelection.value.forEach((el, index) => {
        if (index >= start && index < end) {
          el.isSelected = true;
        }
      })
    } else {
      itemsWithSelection.value.forEach((el) => {
        el.isSelected = true;
      })
    }
    selectAll.value = true;
  } else  {
    if (!all) {
      const start = (currentPage.value - 1) * ITEMS_PER_PAGE
      const end = start + ITEMS_PER_PAGE
      itemsWithSelection.value.forEach((el, index) => {
        if (index >= start && index < end) {
          el.isSelected = false;
        }
      })
    } else {
      itemsWithSelection.value.forEach((el) => {
        el.isSelected = false;
      })
    }
    selectAll.value = false;
  }
}

const handleBulkAction = (action) => {
  const selectedItems = itemsWithSelection.value.filter(item => item.isSelected)
  emit('bulkAction', { action, items: selectedItems })
}

watch(isMultiSelectMode, (newVal) => {
  if (!newVal) {
    itemsWithSelection.value.forEach(item => {
      item.isSelected = false
    })
    selectAll.value = false
  }
})

// Component loading functions
const getAvailableSlots = (componentName) => {
  return COMPONENT_SLOTS[componentName] || []
}

const getSlotProps = (column, item, slotName) => {
  if (typeof column.slotProps === 'function') {
    return column.slotProps(item, slotName)
  }
  return column.slotProps?.[slotName] || {}
}

const getItemVersion = (item) => {
  const relevantData = JSON.stringify({
    ...item,
    _timestamp: Date.now()
  })
  return relevantData
}

const loadComponent = async (componentName) => {
  if (loadedComponents.value[componentName]) {
    loadedComponentCount.value++
    return;
  }
  if (loadingComponents.value.has(componentName)) {
    return
  }
  loadingComponents.value.add(componentName)
  try {
    const componentLoader = COMPONENT_MAP[componentName]
    if (componentLoader) {
      const component = await componentLoader()
      loadedComponents.value[componentName] = component.default || component
      loadedComponentCount.value++
    } else {
      console.warn(`Component ${componentName} not found in COMPONENT_MAP`);
    }
  } catch (error) {
    console.error(`Error loading component ${componentName}:`, error);
  } finally {
    loadingComponents.value.delete(componentName)
    checkAllComponentsLoaded()
  }
}

const checkAllComponentsLoaded = () => {
  if (loadedComponentCount.value === totalRequiredComponents.value) {
    isLoading.value = false
    isInitialized.value = true
  }
}

const loadAllRequiredComponents = async () => {
  if (!props.config?.columns || props.config.columns.length === 0) {
    isLoading.value = false
    isInitialized.value = true
    return
  }

  const componentNames = props.config.columns
    .filter(col => col.component)
    .map(col => col.component)

  totalRequiredComponents.value = componentNames.length;
  loadedComponentCount.value = 0
  isLoading.value = true
  isInitialized.value = false

  loadedComponents.value = {}
  await Promise.all(
    componentNames.map(name => loadComponent(name))
  )
}

const getRowStyle = (item) => {
  if (props.config.getRowStyle) {
    return props.config.getRowStyle(item)
  }
  return {}
}

const getComponentProps = (column, item) => {
  if (typeof column.props === 'function') {
    return column.props(item)
  }
  return column.props || {}
}

const getComponentEvents = (column, item) => {
  if (typeof column.events === 'function') {
    return column.events(item)
  }
  return column.events || {}
}

// Pagination handlers
const onNextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++
  }
}

const onPreviousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--
  }
}

const onChangePage = (page) => {
  currentPage.value = page
}

watch(() => props.config.columns, () => {
  loadAllRequiredComponents()
}, { immediate: true, deep: true })

watch(() => props.config.filters?.searchText, () => {
  currentPage.value = 1
})

watch(() => props.config.filters, () => {
  currentPage.value = 1
  loadAllRequiredComponents()
}, { deep: true });

watch(() => props.items, () => {
  if (currentPage.value > totalPages.value && totalPages.value > 0) {
    currentPage.value = totalPages.value
  }
}, { deep: true })

onBeforeUnmount(() => {
  itemsVersionMap.value.clear()
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

onMounted(async () => {
  await loadAllRequiredComponents()
  nextTick(() => {
    isInitialized.value = true
  });

  const tableElement = document.querySelector('.ex-universal-table')
  if (tableElement) {
    let timeout;
    resizeObserver = new ResizeObserver((entries) => {
      // Debounce las actualizaciones
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        for (const entry of entries) {
          if (entry.contentRect.width !== tableWidth.value) {
            tableWidth.value = entry.contentRect.width;
          }
        }
      }, 20);
    });

    resizeObserver.observe(tableElement);
  }
})

const clearState = computed(() => {
  if (props.config.filters?.searchText.length > 0 && props.config.filters.values?.length > 0) {
    return {
      label: "Clear All",
      description: `Clear All Filters & Search to view ${props.emptyObjectData.plural}`,
    };
  } else if (props.config.filters?.searchText.length > 0) {
    return {
      label: "Clear Search",
      description: `Clear Search to view ${props.emptyObjectData.plural}`,
    };
  } else if (props.config.filters.values?.length > 0) {
    return {
      label: "Clear Filter",
      description: `Clear Filters to view ${props.emptyObjectData.plural}`,
    };
  } else {
    return {
      label: "Clear",
      description: `Clear to view ${props.emptyObjectData.plural}`,
    };
  }
})

</script>
<style>
.bulk-actions__button {
  margin-top: 12px !important;
}
.data-table-pagination {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-table-pagination .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  box-shadow: none;
  color: #667085 !important;
  font-size: 14px;
}
.data-table-pagination .theme--light.v-pagination .v-pagination__item--active {
  background: #F9F5FF !important;
  color: #7F56D9 !important;
}
.data-table-pagination .theme--light.v-pagination .v-pagination__navigation {
  display: none !important;
}
</style>
<style scoped>
.ex-universal-table-container {
  overflow: hidden;
}
.ex-universal-table-container .table-content {
  padding-top: 8px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  width: 100%;
  overflow-x: hidden;
}
.ex-universal-table-container .toggle-icon {
  width: 20px !important;
  height: 20px !important;
}
.ex-universal-table-highlight-row-background {
  width: v-bind(tableWidthCSS);
}
.ex-universal-table thead {
  background-color: #f9fafb;
  border-radius: 20px;
}
.data-table .highlight-table-header {
  cursor: pointer;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-top: 1px solid #eaecf0 !important;
  border-bottom: 1px solid #eaecf0 !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:first-child {
  border-left: none !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
  border-right: none !important;
}
.header-text {
  font-weight: 500;
  line-height: 18px;
  color: #667085;
  white-space: nowrap;
}
.ex-universal-table .v-data-table__wrapper {
  border-bottom: 1px solid #eaecf0;
}
.user-tr .data-table {
  line-height: 5;
}
.prev-hide {
  visibility: hidden;
}
.next-hide {
  visibility: hidden;
}
.ex-universal-table-container .text-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  color: #101828;
}
.ex-universal-table-container .active-badge {
  padding: 2px 8px;
  background-color: #F9F5FF;
  color: #6941C6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #E9D7FE
}
.view-toggle {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}

.checkbox-column {
  width: 48px;
  padding: 0 8px;
}

.selected-row td:not(:first-child) {
  background: linear-gradient(to bottom, white 4px, #e2cffc 4px, #e2cffc calc(100% - 4px), white calc(100% - 4px));
}

.selected-row:hover td:not(:first-child) {
  background: linear-gradient(to bottom, white 4px, #e2cffc 4px, #e2cffc calc(100% - 4px), white calc(100% - 4px));
}

.bulk-actions {
  max-width: 200px;
}

.v-data-table ::v-deep tbody tr:hover {
  background-color: #F9FAFB;
}

.v-data-table ::v-deep .v-data-table__wrapper > table {
  border-spacing: 0;
}

.v-data-table ::v-deep .v-input--selection-controls {
  margin: 0;
  padding: 0;
}
.empty-content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #101828;
}
.empty-content-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #475467;
}
.empty-background-pattern {
  background-image: url("@components/assets/employee-organization/no_data_bg_pattern.svg");
  background-size: 480px 331px;
  background-position: 50% -45px;
  z-index: 2;
}
.ex-universal-table.disable-mouse-events tbody td:not(:first-child) {
  pointer-events: none !important;
  opacity: 0.7!important;
}
</style>
