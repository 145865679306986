var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employee_availability_icon",class:{no_padding: !_vm.isAllocated && _vm.value === 1}},[_c('div',{staticClass:"circle",class:{
      bg_white_image: !_vm.isAllocated && _vm.value === 1,
      bg_gray: _vm.isAllocated && _vm.value === 1,
      bg_black_white: !_vm.isAllocated && _vm.value === 0.5,
      bg_black_gray: _vm.isAllocated && _vm.value === 0.5,
      bg_gray_image: !_vm.isAllocated && _vm.value === 0,
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }