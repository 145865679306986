var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{staticClass:"horizontal-tab",class:{
      'horizontal-small-tab': (_vm.size === 'small' && !_vm.fixedTabs),
      'horizontal-large-tab': (_vm.size === 'large' && !_vm.fixedTabs),
      'horizontal-button-small-tab': (_vm.size === 'small' && _vm.fixedTabs),
      'horizontal-button-large-tab': (_vm.size === 'large' && _vm.fixedTabs),
      'menu-border': (!_vm.bottomLine && !_vm.fixedTabs && !_vm.hideLines)
    },attrs:{"fixed-tabs":_vm.fixedTabs},on:{"change":function($event){return _vm.$emit('onChange', $event)}},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.items),function(item,index){return _c('v-tab',{key:index,staticClass:"menu-tabs"},[_vm._v(" "+_vm._s(item.title)+" "),(item.count != null || item.count != undefined)?_c('v-badge',{staticClass:"count-badge",class:{
            'long-count-badge': (item.count > 9)
          },attrs:{"content":item.count + '',"inline":""}}):_vm._e()],1)}),1),((_vm.bottomLine && !_vm.fixedTabs && !_vm.hideLines))?_c('v-divider',{staticClass:"my-5",staticStyle:{"border-bottom":"1px solid #EAECF0"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }