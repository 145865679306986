import "devextreme/dist/css/dx.light.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import axios from "axios";
import VueAxios from "vue-axios";

import ElementUI from "element-ui";
import { Button, Input, Form, Alert, FormItem } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/css/inter.css";
import "./customstyles.css";
import VueCodeHighlight from "vue-code-highlight";

import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

import loaderPlugin from "./plugins/loaderPlugin.js";
Vue.use(loaderPlugin, { store });

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
// Vue.component(Dialog.name, Dialog);
Vue.component(Form.name, Form);
Vue.component(Alert.name, Alert);
Vue.component(FormItem.name, FormItem);
Vue.use(VueAxios, axios);
Vue.use(VueCodeHighlight);

const options = {
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  closeButton: "button",
  icon: true,
};
Vue.use(Toast, options);

new Vue({
  router,
  store,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
