import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  getListOfPositions,
  createPosition,
  updatePosition,
  deletePosition,
  attachRoleToPosition,
  deActivatePosition,
  reActivatePosition,
  // retirePosition,
  removePositionAssignmentForEmployee,
  archivePosition,
  unArchivePosition,
} from "@/api";

import {
  getPositions as getLocalStoragePositions,
  updatePositions,
} from "@components/helper/localStorageHelper";

import { useReadinessCatalogsStore } from "@/store/pinia/readinessCatalog";

export const usePositionStore = defineStore("positions", () => {
  // State as refs
  const positions = ref([]);
  const currentPosition = ref(null);
  const activePositionsCount = ref(0);
  const totalPositionsCount = ref(0);
  const error = ref(null);
  const loading = ref(false);

  // Getters as computed
  const getPositions = computed(() => positions.value);
  const getActivePositionsCount = computed(() => activePositionsCount.value);
  const getTotalPositionsCount = computed(() => totalPositionsCount.value);
  const getCurrentPosition = computed(() => currentPosition.value);
  const getError = computed(() => error.value);
  const isLoading = computed(() => loading.value);

  const getPositionsByStatus = computed(() => {
    return (status) =>
      positions.value.filter((position) => position.status === status);
  });

  const getPositionById = computed(() => {
    return (id) => positions.value.find((position) => position.id === id);
  });

  const getArchivedPositions = computed(() =>
    positions.value.filter((position) => position.archived)
  );

  const getUnarchivedPositions = computed(() =>
    positions.value.filter((position) => !position.archived)
  );

  const getOccupiedPositions = computed(() =>
    positions.value.filter(
      (position) =>
        position.assigned_employees && position.assigned_employees.length > 0
    )
  );

  const getVacantPositions = computed(() =>
    positions.value.filter(
      (position) =>
        !position.assigned_employees || position.assigned_employees.length === 0
    )
  );

  // Functions for role-related getters that use other stores
  function getAttachedRolesFromPositionId(positionId) {
    const readinessCatalogsStore = useReadinessCatalogsStore();
    if (!positionId) return [];

    return readinessCatalogsStore.roles
      .filter((role) => {
        return role.attachments.some(
          (attachment) =>
            attachment.type === "position" && attachment.id === positionId
        );
      })
      .map((role) => ({
        ...role,
        parentId: -1,
      }));
  }

  function getAttachedRolesAndResponsibilitiesFromPositionId(positionId) {
    const readinessCatalogsStore = useReadinessCatalogsStore();
    if (!positionId) return [];

    const rolesForPosition = readinessCatalogsStore.roles
      .filter((role) => {
        return role.attachments.some(
          (attachment) =>
            attachment.type === "position" && attachment.id === positionId
        );
      })
      .map((role) => ({
        ...role,
        type: "role",
        parentId: -1,
      }));

    const responsibilitiesForPosition = readinessCatalogsStore.responsibilities
      .filter((responsibility) => {
        return responsibility.attached_roles.some((attachment) =>
          rolesForPosition.some((role) => role.id === attachment.id)
        );
      })
      .map((responsibility) => {
        const attachedRole = responsibility.attached_roles.find((attachment) =>
          rolesForPosition.some((role) => role.id === attachment.id)
        );
        return {
          ...responsibility,
          type: "responsibility",
          parentId: attachedRole ? attachedRole.id : -1,
        };
      });

    return [...rolesForPosition, ...responsibilitiesForPosition];
  }

  // State management functions
  function setPositions(data) {
    positions.value = data;
    activePositionsCount.value = data.filter(
      (el) => el.status === "active"
    ).length;
  }

  function setCurrentPosition(data) {
    currentPosition.value = data;
  }

  function setActivePositionsCount(count) {
    activePositionsCount.value = count;
  }

  function setTotalPositionsCount(count) {
    totalPositionsCount.value = count;
  }

  function setError(err) {
    error.value = err;
  }

  function setLoading(isLoading) {
    loading.value = isLoading;
  }

  // CRUD Operations
  async function loadPositions() {
    setLoading(true);
    try {
      setPositions(getLocalStoragePositions());

      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const response = await getListOfPositions(accountId, 1, 500);

      if (response.data?.count) {
        totalPositionsCount.value = response.data.count;
      }

      if (response.data?.results) {
        updatePositions(response.data.results);
        setPositions(response.data.results);
      }
    } catch (err) {
      console.error("Error fetching positions:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  async function createNewPosition(accountId, positionData) {
    setLoading(true);
    try {
      const response = await createPosition(accountId, positionData);
      await loadPositions();
      return response.data;
    } catch (err) {
      console.error("Error creating position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function updateExistingPosition(accountId, positionId, positionData) {
    setLoading(true);
    try {
      const response = await updatePosition(
        accountId,
        positionId,
        positionData
      );
      await loadPositions();
      return response.data;
    } catch (err) {
      console.error("Error updating position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function deleteExistingPosition(accountId, positionId) {
    setLoading(true);
    try {
      await deletePosition(accountId, positionId);
      // removePositionFromStorage(positionId);
      await loadPositions();
    } catch (err) {
      console.error("Error deleting position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  // Status Management Functions
  async function activatePosition(accountId, positionId, positionName) {
    setLoading(true);
    try {
      await updatePosition(accountId, positionId, {
        name: positionName,
        status: "active",
        account: accountId,
      });
      await loadPositions();
    } catch (err) {
      console.error("Error activating position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function deactivatePosition(accountId, positionId) {
    setLoading(true);
    try {
      await deActivatePosition(accountId, positionId);
      await loadPositions();
    } catch (err) {
      console.error("Error deactivating position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function reactivatePosition(accountId, positionId) {
    setLoading(true);
    try {
      await reActivatePosition(accountId, positionId);
      await loadPositions();
    } catch (err) {
      console.error("Error reactivating position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function retirePosition(accountId, positionId) {
    setLoading(true);
    try {
      await retirePosition(accountId, positionId);
      await loadPositions();
    } catch (err) {
      console.error("Error retiring position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  // Archive Management Functions
  async function archiveExistingPosition(accountId, positionId) {
    setLoading(true);
    try {
      await archivePosition(accountId, "position", positionId);
      await loadPositions();
    } catch (err) {
      console.error("Error archiving position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function unarchiveExistingPosition(accountId, positionId) {
    setLoading(true);
    try {
      await unArchivePosition(accountId, "position", positionId);
      await loadPositions();
    } catch (err) {
      console.error("Error unarchiving position:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  // Role Management Functions
  async function attachRole(accountId, positionId, roleId) {
    setLoading(true);
    try {
      const response = await attachRoleToPosition(accountId, positionId, {
        attachment_id: roleId,
      });
      await loadPositions();
      return response.data;
    } catch (err) {
      console.error("Error attaching role:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  // Assignment Management Functions
  async function removePositionAssignment(accountId, assignmentId) {
    setLoading(true);
    try {
      await removePositionAssignmentForEmployee(accountId, assignmentId);
      await loadPositions();
    } catch (err) {
      console.error("Error removing position assignment:", err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return {
    // State
    positions,
    currentPosition,
    activePositionsCount,
    totalPositionsCount,
    error,
    loading,

    // Getters
    getPositions,
    getActivePositionsCount,
    getTotalPositionsCount,
    getCurrentPosition,
    getError,
    isLoading,
    getPositionsByStatus,
    getPositionById,
    getArchivedPositions,
    getUnarchivedPositions,
    getOccupiedPositions,
    getVacantPositions,
    getAttachedRolesFromPositionId,
    getAttachedRolesAndResponsibilitiesFromPositionId,

    // State Management Functions
    setPositions,
    setCurrentPosition,
    setActivePositionsCount,
    setTotalPositionsCount,
    setError,
    setLoading,

    // CRUD Operations
    loadPositions,
    createNewPosition,
    updateExistingPosition,
    deleteExistingPosition,

    // Status Management
    activatePosition,
    deactivatePosition,
    reactivatePosition,
    retirePosition,

    // Archive Management
    archiveExistingPosition,
    unarchiveExistingPosition,

    // Role Management
    attachRole,

    // Assignment Management
    removePositionAssignment,
  };
});
