import { render, staticRenderFns } from "./BeMasterCoachView.vue?vue&type=template&id=64dc2eea&scoped=true"
import script from "./BeMasterCoachView.vue?vue&type=script&lang=js"
export * from "./BeMasterCoachView.vue?vue&type=script&lang=js"
import style0 from "./BeMasterCoachView.vue?vue&type=style&index=0&id=64dc2eea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64dc2eea",
  null
  
)

export default component.exports