// store/modules/positionRoleData.js
const state = {
  positionRoleData: {},
};

const mutations = {
  setPositionRoleData(state, payload) {
    state.positionRoleData = payload;
  },
};

const actions = {
  updatePositionRoleData({ commit }, payload) {
    commit("setPositionRoleData", payload);
  },
};

const getters = {
  positionRoleData: (state) => state.positionRoleData,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
