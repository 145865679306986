var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c(_setup.UniversalTable,{attrs:{"config":_setup.tableConfig.tableParams.value,"items":_setup.filteredPositions,"emptyObjectData":{ singular: 'Position', plural: 'Positions' }},on:{"onActionClick":function($event){_setup.viewState.state.addPositionModal.show = true},"onSearch":function($event){_setup.viewState.state.filters.searchText = $event},"onClick":function($event){_setup.viewState.state.filters.showFilterDialog = true},"removeFilter":_setup.viewState.removeFilters,"removeAllFilters":function($event){_setup.viewState.state.filters.values = []}},scopedSlots:_vm._u([{key:"Dropdown-activator",fn:function(){return [_c('div',{staticClass:"icon-container"},[_c('svg',{staticClass:"icon-svg",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]},proxy:true}])}),_c(_setup.AddOrgObject,{attrs:{"show":_setup.viewState.state.addPositionModal.show,"OrgObjectType":"Position","ghostText":"+ Add","description":"You are about to add a new","type":"add","button-label":_setup.proxy.$loader.isAppLoading ? 'Saving' : 'Save'},on:{"onAdd":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.CREATE_POSITION,
        _setup.accountID,
        $event
      )},"close":function($event){_setup.viewState.state.addPositionModal.show = false}}}),_c(_setup.ExOrgObjectActions,{attrs:{"objectType":"Position","name":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.ACTIVATE_POSITION,
        _setup.accountID
      )}},model:{value:(_setup.viewState.state.activatePositionModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.activatePositionModal, "show", $$v)},expression:"viewState.state.activatePositionModal.show"}}),_c(_setup.ExDeleteOrgObject,{attrs:{"OrgObjectType":"Position","name":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.DELETE_POSITION,
        _setup.accountID
      )}},model:{value:(_setup.viewState.state.deletePositionModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.deletePositionModal, "show", $$v)},expression:"viewState.state.deletePositionModal.show"}}),(_setup.viewState.state.renamePositionModal.show)?_c(_setup.RenameOrgObject,{attrs:{"openDialog":_setup.viewState.state.renamePositionModal.show,"name":_setup.positions.getCurrentPosition()?.name || '',"OrgObjectType":"Position","placeholder":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.RENAME_POSITION,
        _setup.accountID,
        $event
      )},"onClose":function($event){_setup.viewState.state.renamePositionModal.show = false}}}):_vm._e(),_c(_setup.ArchivePopUp,{attrs:{"action":_setup.viewState.state.archiveAction,"type":"position","show":_setup.viewState.state.archivePositionModal.show,"curretObj":_setup.positions.getCurrentPosition()},on:{"onClose":function($event){_setup.viewState.state.archivePositionModal.show = false},"onArchive":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.ARCHIVE_POSITION,
        _setup.accountID
      )}}}),_c(_setup.AttachedRole,{attrs:{"OrgObjectType":"Role to Position","items":_setup.viewState.state.attachRoleModal.roles,"parent-name":_setup.positions.getCurrentPosition()?.name || '',"existingNamesList":_setup.viewState.state.attachRoleModal.attachedRoles},on:{"onSave":function($event){return _setup.viewState.attachRole(_setup.accountID)},"item-selected":_setup.viewState.handleRoleSelected,"add-item-selected":_setup.viewState.handleAddRoleSelected},model:{value:(_setup.viewState.state.attachRoleModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.attachRoleModal, "show", $$v)},expression:"viewState.state.attachRoleModal.show"}}),_c(_setup.SearchModal,{attrs:{"dialog":_setup.viewState.state.filters.showFilterDialog,"clearSelection":_setup.viewState.state.filters.clearSelection,"filterType":"Positions","filterGroups":_setup.viewState.state.filters.filterGroups,"statusOptions":_setup.viewState.state.filters.statusOptions,"modalWidth":620,"defaultSelected":_setup.viewState.state.filters.values},on:{"back":function($event){_setup.viewState.state.filters.showFilterDialog = false},"checkedItemsChanged":_setup.viewState.applyFilters}}),(_setup.viewState.state.relieveAssignment.show)?_c(_setup.RelievePrimaryAssignment,{attrs:{"show":_setup.viewState.state.relieveAssignment.show,"avatar":_setup.viewState.getRelieveAssignmentAvatar(),"current-position":_setup.positions.getCurrentPosition()?.name || ''},on:{"onClose":function($event){return _setup.viewState.closeRelieveAssignment()},"onAction":function($event){return _setup.viewState.relievePrimaryAssignment(_setup.accountID)}}}):_vm._e(),_c(_setup.RetirePositionPopup,{attrs:{"OrgObjectType":"Position","name":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.RETIRE_POSITION,
        _setup.accountID
      )}},model:{value:(_setup.viewState.state.retirePositionModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.retirePositionModal, "show", $$v)},expression:"viewState.state.retirePositionModal.show"}}),_c(_setup.ReActivatePosition,{attrs:{"OrgObjectType":"Position","name":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.REACTIVATE_POSITION,
        _setup.accountID
      )}},model:{value:(_setup.viewState.state.reactivatePositionModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.reactivatePositionModal, "show", $$v)},expression:"viewState.state.reactivatePositionModal.show"}}),_c(_setup.ExDeActivatePopup,{attrs:{"OrgObjectType":"Position","name":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.DEACTIVATE_POSITION,
        _setup.accountID
      )}},model:{value:(_setup.viewState.state.deactivatePositionModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.deactivatePositionModal, "show", $$v)},expression:"viewState.state.deactivatePositionModal.show"}}),_c(_setup.ExCloneOrgObject,{attrs:{"OrgObjectType":"Position","name":_setup.positions.getCurrentPosition()?.name || ''},on:{"onAction":function($event){return _setup.viewState.handlePositionOperation(
        _setup.positions.operations.CLONE_POSITION,
        _setup.accountID
      )}},model:{value:(_setup.viewState.state.clonePositionModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.clonePositionModal, "show", $$v)},expression:"viewState.state.clonePositionModal.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }