<template>
  <div class="readiness-container">
    <PageTitle class="page-title" title="As Employee" />
    <EmployeeReadinessMenu :items="menuItems" />
    <v-tabs-items v-model="selectedTab" class="custom-tabs">
      <v-tab-item v-for="(item, index) in menuItems" :key="index">
        <v-card
          elevation="0"
          class="employee-readiness-container"
          v-if="selectedTab === 0"
        >
          <div class="employee-path-to-readiness">
            <div class="employee-readiness-title">My Readiness</div>
            <div class="employee-readiness">
              <ReadinessBar
                :barValue="currentReadiness?.current_readiness"
                :maxValue="100"
                type="readiness"
              />
            </div>
          </div>
          <div class="employee-path">
            <PathToReadinessTreeList
              :dataSource="dataSource"
              :topLevel="topLevel"
              :removeBorder="removeBorder"
              :dropdownItems="dropdownItems"
              :pathToReadinessColumnName="'Position/Role/ Responsibility / Knowledge / Learning'"
              :showReadinessButtons="true"
              :showDropDown="false"
              :showVideo="true"
            />
          </div>
        </v-card>
        <v-card v-if="selectedTab === 2"> </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PageTitle from "@components/common/PageTitle.vue";
import EmployeeReadinessMenu from "@components/EmployeeReadinessMenu/EmployeeReadinessMenu.vue";
import PathToReadinessTreeList from "@components/DataTable/PathToReadinessTreeList.vue";
import ReadinessBar from "@components/ReadinessBar/ReadinessBar.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import { getEmployeeAssignment, recalculateEmployeeReadiness } from "@/api";
export default {
  components: {
    PageTitle,
    EmployeeReadinessMenu,
    ReadinessBar,
    PathToReadinessTreeList,
  },
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.authUser = JSON.parse(localStorage.getItem("user"));
    this.$loader.show();
    await this.getEmployeesAssignments();
    await this.getAuthUserReadiness();
    this.$loader.hide();
  },
  data() {
    return {
      menuItems: [
        { title: "My Readiness" },
        { title: "My Responsibilities", count: 0 },
        { title: "My Inspection Requests", count: 0 },
        { title: "My Badges", count: 0 },
        { title: "My Info", count: 0 },
      ],

      dataSource: [],
      accountID: null,
      topLevel: "position",
      removeBorder: false,

      selectedTab: 0,
      currentReadiness: null,
    };
  },
  mixins: [dropdownMixin],
  methods: {
    async getEmployeesAssignments() {
      const empId = this.authUser?.id;
      const val = false;
      const { data } = await getEmployeeAssignment(this.accountID, empId, val);
      if (data.length > 0) {
        this.dataSource = this.transformData(data);
      }
    },

    async getAuthUserReadiness() {
      const empId = this.authUser?.id;
      const val = false;
      const { data } = await recalculateEmployeeReadiness(
        this.accountID,
        empId,
        val
      );
      this.currentReadiness = data;
    },

    transformData(dataArray) {
      const result = [];
      const today = new Date();

      dataArray.forEach((data) => {
        const isNew =
          new Date(data.assigned_on).getTime() + 30 * 24 * 60 * 60 * 1000 >
          today.getTime();
        const mainObject = {
          id: data.id,
          parentId: -1,
          data: {
            type: data.assignment_type,
            commitmentLevel: data.commitment_level,
            assignmentType: data.assignment_type,
            importanceLevel: data.importance,
            importanceSelector: true,
            totalSteps: 3,
            code: data.position_code,
            name: data.position_name,
            isNew: isNew,
            isAccepted: false,
          },
          readiness: data.current_readiness,
        };
        result.push(mainObject);

        // Process role_assignments
        if (data.role_assignments) {
          data.role_assignments.forEach((role) => {
            const isRoleNew =
              new Date(role.assigned_on).getTime() + 30 * 24 * 60 * 60 * 1000 >
              today.getTime();
            const roleObject = {
              id: role.id + 1,
              parentId: data.id,
              data: {
                type: data.assignment_type,
                commitmentLevel: role.commitment_level,
                assignmentType: role.assignment_type,
                importanceLevel: role.importance,
                importanceSelector: true,
                totalSteps: 3,
                code: role.role_code,
                name: role.role_name,
                isNew: isRoleNew,
                isAccepted: isRoleNew,
              },
              readiness: role.current_readiness,
            };
            result.push(roleObject);

            // Process responsibility_assignments within each role
            if (role.responsibility_assignments) {
              role.responsibility_assignments.forEach((resp) => {
                const isRespNew =
                  new Date(resp.assigned_on).getTime() +
                    30 * 24 * 60 * 60 * 1000 >
                  today.getTime();
                const responsibilityObject = {
                  id: resp.id + 2,
                  parentId: role.id + 1,
                  data: {
                    type: data.assignment_type,
                    commitmentLevel: resp.commitment_level,
                    assignmentType: resp.assignment_type,
                    importanceLevel: resp.importance,
                    importanceSelector: true,
                    totalSteps: 3,
                    code: resp.responsibility_code,
                    name: resp.responsibility_name,
                    isNew: isRespNew,
                    isAccepted: isRespNew,
                  },
                  status: resp.status,
                  readiness: resp.current_readiness,
                };
                result.push(responsibilityObject);
              });
            }
          });
        }
      });

      return result;
    },

    dropdownItems() {
      const items = [
        {
          name: "Activate",
          value: "activate_action",
          icon: "icons/dropdown/activate.svg",
          order: 1,
        },
        {
          name: "Delete",
          value: "delete_action",
          icon: "icons/dropdown/delete.svg",
          negative: true,
          order: 3,
        },
      ];
      return items;
    },
  },
};
</script>

<style lang="css" scoped>
.readiness-container {
  margin: 15px;
  padding-top: 15px;
  width: 100%;
}

.page-title {
  margin-top: 16px;
}

.employee-path-to-readiness {
  /* box-shadow: 0px 1px 2px 0px #1018280d; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
}
.employee-readiness-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
  min-width: 125px;
}
.employee-readiness {
  width: 100%;
  margin-left: 15px;
}
.employee-readiness .progress-bar-container {
  width: 100%;
}
.employee-readiness .progress-value {
  font-weight: 600;
}
.employee-path {
  border-radius: 8px;
}
::v-deep .progress-bar-container {
  width: 100%;
}
.employee-readiness-container {
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: none;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

#treelist {
  max-width: 100%;
}

::v-deep(.v-tabs) {
  padding: 13px 0px !important;
  border-bottom: 1px solid #eaecf0 !important;
}

::v-deep(.custom-tabs) {
  top: 30px;
}
</style>
