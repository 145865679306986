import { render, staticRenderFns } from "./RenameResponsibility.vue?vue&type=template&id=6e191eb2&scoped=true"
import script from "./RenameResponsibility.vue?vue&type=script&lang=js"
export * from "./RenameResponsibility.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e191eb2",
  null
  
)

export default component.exports