var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":_vm.pagination.perPage,"page":_vm.pagination.page,"hide-default-footer":"","hide-default-header":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(item,index){return _c('th',{key:`header-${index}`,class:{
              'highlight-table-header': item.sortable,
              'curren-badge-header': item.centerField
            }},[(item.img)?[_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require(`@components/assets/${item.img}`)}}),_c('span',{staticClass:"header-text text-xs"},[_vm._v(_vm._s(item.text))])])]:(item.multiple)?[_c('div',{staticClass:"d-flex"},_vm._l((item.data),function(dataItem,index){return _c('div',{key:`${dataItem.text}-${index}`,staticClass:"d-flex mr-3 align-center"},[(dataItem.img)?_c('img',{staticClass:"mr-2",attrs:{"src":require(`@components/assets/${dataItem.img}`)}}):_vm._e(),_c('span',{staticClass:"header-text text-xs"},[_vm._v(_vm._s(dataItem.text))])])}),0)]:[_c('span',{staticClass:"header-text text-xs"},[_vm._v(_vm._s(item.text))])]],2)}),0)])]}},{key:"body",fn:function({ items }){return [(items && items.length > 0)?_c('tbody',_vm._l((items),function(item,rowIndex){return _c('tr',{key:`row-${rowIndex}`,style:(_vm.noHoverEffect ? { backgroundColor: 'white' } : (_vm.useStatusRowColoring ? { backgroundColor: _vm.getRowBackgroundColor(item.position.data.status) } : {}))},_vm._l((Object.values(item)),function(itemVal,colIndex){return _c('td',{key:`col-${colIndex}-${itemVal}`},[(itemVal.slot)?_c('div',[_vm._t(itemVal.slot,null,{"row":item})],2):[_vm._v(_vm._s(itemVal))]],2)}),0)}),0):_c('tbody',[_vm._t("no-data")],2)]}}],null,true)}),(_vm.totalPages > 1)?_c('div',{staticClass:"d-flex justify-space-between align-center px-6 py-4"},[_c('Button',{class:{
        'prev-hide': !_vm.showPreviousButton
      },attrs:{"icon":'arrow-icon.svg',"size":"small","outline":'outline-active',"label":"Previous"},on:{"onClick":_vm.onPreviousPage}}),(_vm.totalPages > 1)?_c('v-pagination',{staticClass:"data-table-pagination",attrs:{"length":_vm.totalPages,"total-visible":7},on:{"input":_vm.onChangePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('Button',{class:{
        'next-hide': !_vm.showNextButton
      },attrs:{"rightIcon":'right-arrow-icon.svg',"size":"small","outline":'outline-active',"label":"Next"},on:{"onClick":_vm.onNextPage}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }