export function getAppData() {
  return JSON.parse(localStorage.getItem("exlAppData"));
}

export function setAppData(data) {
  localStorage.setItem("exlAppData", JSON.stringify(data));
}

export function updateEmployees(employees) {
  let exlAppData = getAppData();
  if (!exlAppData) {
    exlAppData = {};
  }
  exlAppData.employees = employees;
  setAppData(exlAppData);
}

export function getEmployees() {
  let exlAppData = getAppData();
  if (exlAppData && exlAppData.employees) {
    return exlAppData.employees;
  } else {
    return [];
  }
}

export function updatePositions(positions) {
  let exlAppData = getAppData();
  if (!exlAppData) {
    exlAppData = {};
  }
  exlAppData.positions = positions;
  setAppData(exlAppData);
}

export function getPositions() {
  let exlAppData = getAppData();
  if (exlAppData && exlAppData.positions) {
    return exlAppData.positions;
  } else {
    return [];
  }
}

export function updateRoles(roles) {
  let exlAppData = getAppData();
  if (!exlAppData) {
    exlAppData = {};
  }
  exlAppData.roles = roles;
  setAppData(exlAppData);
}

export function getRoles() {
  let exlAppData = getAppData();
  if (exlAppData && exlAppData.roles) {
    return exlAppData.roles;
  } else {
    return [];
  }
}

export function updateResponsibilities(responsibilities) {
  let exlAppData = getAppData();
  if (!exlAppData) {
    exlAppData = {};
  }
  exlAppData.responsibilities = responsibilities;
  setAppData(exlAppData);
}

export function getResponsibilities() {
  let exlAppData = getAppData();
  if (exlAppData && exlAppData.responsibilities) {
    return exlAppData.responsibilities;
  } else {
    return [];
  }
}

export function updateFilters(key, filters) {
  let exlAppData = getAppData() || {};
  exlAppData.filters = exlAppData.filters || {};
  exlAppData.filters[key] = filters;
  setAppData(exlAppData);
}

export function getFilters(key) {
  const exlAppData = getAppData();
  if(key == "redinessResponsibilityChecklist" && exlAppData && exlAppData.filters){
    return typeof exlAppData.filters[key] === 'undefined' ? ["active", "draft"] : exlAppData.filters[key];
  }
  if (exlAppData && exlAppData.filters && exlAppData.filters[key]) {
    return exlAppData.filters[key];
  }
  return [];
}
