<template>
  <div>
    <h1>Admin Page</h1>
  </div>
</template>

<script>
export default {
  name: "AdminView",
};
</script>
