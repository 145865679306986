var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c(_setup.UniversalTable,{attrs:{"config":_setup.tableConfig.tableParams.value,"items":_setup.filteredEmployees},on:{"onActionClick":function($event){_setup.viewState.state.addEmployeeModal.show = true},"onSearch":function($event){_setup.viewState.state.filters.searchText = $event},"onClick":function($event){_setup.viewState.state.filters.showFilterDialog = true},"removeFilter":_setup.viewState.removeFilters,"removeAllFilters":function($event){_setup.viewState.state.filters.values = []}},scopedSlots:_vm._u([{key:"Dropdown-activator",fn:function(){return [_c('div',{staticClass:"icon-container"},[_c('svg',{staticClass:"icon-svg",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]},proxy:true}])}),_c(_setup.AddEmployeeModal,{attrs:{"show":_setup.viewState.state.addEmployeeModal.show,"propEmployee":_setup.employees.getCurrentEmployee()
        ? {
            id: _setup.employees.getCurrentEmployee()?.id || 0,
            first_name: _setup.employees.getCurrentEmployee()?.first_name || '',
            last_name: _setup.employees.getCurrentEmployee()?.last_name || '',
            title: _setup.employees.getCurrentEmployee()?.title || '',
            availability: _setup.employees.getCurrentEmployee()?.availability || 0,
            avatar: _setup.employees.getCurrentEmployee()?.avatar || '',
          }
        : null},on:{"close":function($event){_setup.viewState.state.addEmployeeModal.show = false},"onSave":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.EDIT_EMPLOYEE,
        _setup.accountID,
        $event
      )}}}),_c(_setup.ExEmployeePrimaryAssignmentWizard,{attrs:{"label":'Position',"items":_setup.employees.eligiblePositions.value,"currentEmployee":_setup.employees.getCurrentEmployee(),"value":_setup.viewState.state.assignmentWizard.show},on:{"input":function($event){!$event ? _setup.viewState.closeAssignmentWizard() : ''},"onAssignPosition":function($event){return _setup.viewState.onAssignPositionToEmployee({
        position: $event,
        accountId: _setup.accountID,
      })}}}),_c(_setup.SearchModal,{attrs:{"dialog":_setup.viewState.state.filters.showFilterDialog,"clearSelection":_setup.viewState.state.filters.clearSelection,"filterType":"Employee","filterGroups":_setup.viewState.state.filters.filterGroups,"statusOptions":_setup.viewState.state.filters.statusOptions,"modalWidth":800,"defaultSelected":_setup.viewState.state.filters.values},on:{"back":function($event){_setup.viewState.state.filters.showFilterDialog = false},"checkedItemsChanged":_setup.viewState.applyFilters}}),(_setup.viewState.state.relieveAssignment.show)?_c(_setup.RelievePrimaryAssignment,{attrs:{"show":_setup.viewState.state.relieveAssignment.show,"avatar":{
      picture: _setup.employees.getCurrentEmployee()?.avatar || '',
      firstName: _setup.employees.getCurrentEmployee()?.first_name || '',
      lastName: _setup.employees.getCurrentEmployee()?.last_name || '',
    },"current-position":_setup.viewState.state.relieveAssignment.currentPosition.name},on:{"onAction":function($event){return _setup.viewState.relievePrimaryPositionFromEmployee(_setup.accountID)},"onClose":function($event){return _setup.viewState.closeRelieveAssignment()}}}):_vm._e(),_c(_setup.TerminateEmployee,{attrs:{"show":_setup.viewState.state.terminateEmployeeModal.show,"info":_setup.viewState.state.terminateEmployeeModal.message,"avatar":{
      picture: '',
      firstName: `${_setup.employees.getCurrentEmployee()?.first_name || 'U'}`,
      lastName: `${_setup.employees.getCurrentEmployee()?.last_name || 'S'}`,
    }},on:{"onAction":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.TERMINATE_EMPLOYEE,
        _setup.accountID
      )},"onClose":function($event){_setup.viewState.state.terminateEmployeeModal.show = false}}}),_c(_setup.DeleteEmployee,{attrs:{"show":_setup.viewState.state.deleteEmployeeModal.show,"info":_setup.viewState.state.deleteEmployeeModal.message,"avatar":{
      picture: '',
      firstName: `${_setup.employees.getCurrentEmployee()?.first_name || 'U'}`,
      lastName: `${_setup.employees.getCurrentEmployee()?.last_name || 'S'}`,
    }},on:{"onAction":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.DELETE_EMPLOYEE,
        _setup.accountID
      )},"onClose":function($event){_setup.viewState.state.deleteEmployeeModal.show = false}}}),_c(_setup.ActivateEmployee,{attrs:{"show":_setup.viewState.state.activeEmployeeModal.show,"employee":_setup.employees.getCurrentEmployee()},on:{"onClose":function($event){_setup.viewState.state.activeEmployeeModal.show = false},"onActiveEmployee":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.ACTIVATE_EMPLOYEE,
        _setup.accountID
      )}}}),_c(_setup.ReActivateEmployee,{attrs:{"show":_setup.viewState.state.reactivateEmployeeModal.show,"employee":{
      firstName: _setup.employees.getCurrentEmployee()?.first_name || '',
      lastName: _setup.employees.getCurrentEmployee()?.last_name || '',
      ..._setup.employees.getCurrentEmployee(),
    }},on:{"onClose":function($event){_setup.viewState.state.reactivateEmployeeModal.show = false},"onActiveEmployee":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.REACTIVATE_EMPLOYEE,
        _setup.accountID
      )}}}),_c(_setup.EditModalPicture,{attrs:{"dialog":_setup.viewState.state.changePictureModal.show,"avatar":_setup.employees.getCurrentEmployee()?.avatar || ''},on:{"croppedImage":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.CHANGE_EMPLOYEE_PICTURE,
        _setup.accountID,
        $event
      )},"closeModal":function($event){_setup.viewState.state.changePictureModal.show = false},"removeModal":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.CHANGE_EMPLOYEE_PICTURE,
        _setup.accountID,
        null
      )}}}),_c(_setup.GiveAccessToEmployeeModal,{attrs:{"contentTypeProp":_setup.viewState.state.giveAccessModal.dataType,"existingUser":_setup.viewState.state.giveAccessModal.existingUser,"current-employee":{
      id: _setup.employees.getCurrentEmployee()?.id || 0,
      firstName: _setup.employees.getCurrentEmployee()?.first_name || '',
      lastName: _setup.employees.getCurrentEmployee()?.last_name || '',
      availabilityVal: _setup.employees.getCurrentEmployee()?.availability,
      avatar: _setup.employees.getCurrentEmployee()?.avatar || '',
    }},on:{"checkEmail":function($event){return _setup.viewState.checkUserEmail({ accountId: _setup.accountID, email: $event })},"onSave":function($event){return _setup.viewState.giveAccessToEmployee({
        accountId: _setup.accountID,
        payload: $event,
      })},"onChangeContentType":function($event){_setup.viewState.state.giveAccessModal.dataType = $event}},model:{value:(_setup.viewState.state.giveAccessModal.show),callback:function ($$v) {_vm.$set(_setup.viewState.state.giveAccessModal, "show", $$v)},expression:"viewState.state.giveAccessModal.show"}}),_c(_setup.ArchivePopUp,{attrs:{"action":_setup.viewState.state.archiveAction,"type":"employee","show":_setup.viewState.state.archiveEmployeeModal.show,"curretObj":{
      firstName: _setup.employees.getCurrentEmployee()?.first_name || '',
      lastName: _setup.employees.getCurrentEmployee()?.last_name || '',
    }},on:{"onClose":function($event){_setup.viewState.state.archiveEmployeeModal.show = false},"onArchive":function($event){return _setup.viewState.handleEmployeeOperation(
        _setup.employees.operations.ARCHIVE_EMPLOYEE,
        _setup.accountID
      )}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }