import axios from "./_axios";
import { API } from "./apiEndPoints";

//https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_role_list
export const getListOfRoles = async (account_id, page_no, size) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/role/`,
    params: { page: page_no, size: size },
  });
  return result;
};
// https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_role_read
export const getRole = async (account_id, role_id) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}`,
    params: {},
  });
  return result;
};
// https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_role_create
export const addRole = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/`,
    data: data,
  });
  return result;
};
// https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_role_update
export const updateRole = (account_id, role_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}/`,
    data: data,
  });
  return result;
};
// https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_attach_create
export const attachResponsibilityToRole = (
  account_id,
  responsibilityId,
  data
) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/${responsibilityId}/attach/`,
    data: data,
  });
  return result;
};
// https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_role_update
export const updateRetiredRole = (account_id, role_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}/retire/`,
    data: data,
  });
  return result;
};
export const updateArchiveRole = (account_id, objType, orgId) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/${objType}/${orgId}/archive/`,
  });
  return result;
};
export const updateUnArchiveRole = (account_id, objType, orgId) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/${objType}/${orgId}/unarchive/`,
  });
  return result;
};
// https://api.dev.exiqtive.com/redoc/#tag/common/operation/common_account_role_delete
export const deleteReadinessRole = (account_id, role_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}/`,
  });
  return result;
};
// https://api.dev.exiqtive.com/api/common/account/{account_id}/{type}/{obj_id}/de-activate/
export const updateDeActiveRole = (account_id, role_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}/de-activate/`,
    data: data,
  });
  return result;
};
// https://api.dev.exiqtive.com/api/common/account/{account_id}/{type}/{obj_id}/re-activate/
export const updateReActiveRole = (account_id, role_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}/re-activate/`,
    data: data,
  });
  return result;
};
//https://api.dev.exiqtive.com/api/common/account/{account_id}/position/{id}/
export const getPositionId = (account_id, pos_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/position/${pos_id}/`,
  });
  return result;
};
//https://api.dev.exiqtive.com/redoc/#tag/proficiency/operation/Get%20role%20chatter
//https://api.dev.exiqtive.com/api/proficiency/account/{account_id}/role/{role_id}/chatter/
export const getRoleChatter = (account_id, role_id, page = 1) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/role/${role_id}/chatter/`,
    params: { page: page },
  });
  return result;
};

export const getRoleId = (account_id, role_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/role/${role_id}/`,
  });
  return result;
};

export const detachAttachments = (account_id, role_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/responsibility/${role_id}/detach/`,
    data: data,
  });
  return result;
};

export const detachRoleAttachment = (account_id, obj_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/${obj_id}/detach/`,
    data: data,
  });
  return result;
};

export const detachOrgObject = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/${data.childType}/${data.childId}/detach/`,
    data: data.parentData,
  });
  return result;
};
