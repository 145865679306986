import { useEmployeeStore } from "@/store/pinia/employee";
import { usePositionStore } from "@/store/pinia/position";
import {
  addPositionAssignment,
  createEmployee,
  createUser,
  deleteEmployee,
  archiveEmployee,
  unArchiveEmployee,
  getListofUser,
  reactivateEmployee,
  removePositionAssignmentForEmployee,
  terminateEmployee,
  updateEmployee,
  userCheckEmail,
} from "@/api";
import { computed } from "vue";

export function useEmployees() {
  const employeeStore = useEmployeeStore();
  const positionStore = usePositionStore();

  const allEmployees = computed(() => employeeStore.employees);

  const operations = {
    TERMINATE_EMPLOYEE: "terminate_employee",
    ACTIVATE_EMPLOYEE: "activate_employee",
    DELETE_EMPLOYEE: "delete_employee",
    REACTIVATE_EMPLOYEE: "reactivate_employee",
    EDIT_EMPLOYEE: "edit_employee",
    CHANGE_EMPLOYEE_PICTURE: "change_employee_picture",
    GIVE_ACCESS_TO_EMPLOYEE: "give_access_to_employee",
    ARCHIVE_EMPLOYEE: "archive_employee",
  };

  const setCurrentEmployee = (employee) => {
    employeeStore.setCurrentEmployee(employee);
  };

  const getCurrentEmployee = () => {
    return employeeStore.currentEmployee;
  };
  const loadAllEmployees = async () => {
    await employeeStore.loadEmployees();
  };
  const eligiblePositions = computed(() => {
    if (!employeeStore.currentEmployee) return [];

    const allPositions = positionStore.positions;
    let allocationSum = 0;

    if (employeeStore.currentEmployee.primary_positions?.length > 0) {
      employeeStore.currentEmployee.primary_positions.forEach((el) => {
        allocationSum += el.allocation;
      });
    }

    const employeeVacant =
      employeeStore.currentEmployee.availability - allocationSum;
    const allocationsArray = [];

    employeeStore.employees.forEach((item) => {
      if (item.primary_positions?.length > 0) {
        item.primary_positions.forEach((positionAssignment) => {
          const newEl = {
            id: positionAssignment.position,
            allocation: positionAssignment.allocation,
          };
          const itemExist = allocationsArray.some((el) => el.id === newEl.id);
          if (!itemExist) {
            allocationsArray.push(newEl);
          }
        });
      }
    });

    if (allPositions) {
      const positionsWithAllocation = allPositions.map((el) => {
        const allocationItem = allocationsArray.find(
          (allocItem) => allocItem.id === el.id
        );
        return {
          ...el,
          allocation: allocationItem ? allocationItem.allocation : 0,
        };
      });

      return positionsWithAllocation.filter((el) => {
        const positionVacancy = (el?.capacity || 0) - (el?.occupied || 0);
        return (
          positionVacancy &&
          employeeVacant >= positionVacancy &&
          positionVacancy >= 1
        );
      });
    }
    return [];
  });

  const saveEmployee = async ({ accountId, employee }) => {
    try {
      if (employee && employee?.id) {
        let payload = {
          first_name: employee.first_name,
          last_name: employee.last_name,
          title: employee.title,
        };
        if (employee.avatar) {
          payload.avatar = employee.avatar;
        }
        await updateEmployee(accountId, employee.id, payload);
      } else {
        const newEmployee = await createEmployee(accountId, employee);
        return newEmployee;
      }
    } catch (e) {
      console.log("e: ", e);
    }
  };

  const saveEmployeeWithAvatar = async ({ accountId, employee }) => {
    try {
      let payload = {
        first_name: employee.first_name,
        last_name: employee.last_name,
        title: employee.title,
        avatar: employee.avatar,
      };
      await updateEmployee(accountId, employee.id, payload);
    } catch (e) {
      console.log("e: ", e);
    }
  };

  const checkEmailUser = async ({ accountId, email }) => {
    const { data } = await userCheckEmail(accountId, { email });
    const emailIsBlocked = data?.access;
    const emailIsUsed = data?.user;
    if (emailIsUsed) {
      const { data } = await getListofUser(accountId);
      const userList = data.results;
      const user = userList.find((el) => el.user.email == email);
      const existingUser = {
        firstName: user.user.first_name,
        lastName: user.user.last_name,
      };
      return {
        user: existingUser,
        data: "emailUsed",
      };
    } else if (emailIsBlocked) {
      return {
        data: "emailBlocked",
      };
    } else {
      return {
        data: "step2",
      };
    }
  };

  const giveAccessToEmployee = async ({ accountId, payload }) => {
    await createUser(accountId, payload);
  };

  const onUpdateAvailability = async ({ accountId, data }) => {
    try {
      const { id, first_name, last_name, title } = data.employee;
      const payload = {
        first_name,
        last_name,
        title,
        availability: data.value,
      };
      await updateEmployee(accountId, id, payload);
    } catch (e) {
      console.error("error: ", e);
    }
  };

  const onAssignPositionToEmployee = async ({
    employee,
    position,
    accountID,
  }) => {
    try {
      const payload = {
        employee: employee.id,
        position: position.id,
        commitment: employee.availability,
        allocation: employee.availability,
      };
      await addPositionAssignment(accountID, payload);
    } catch (e) {
      console.error("error: ", e);
    }
  };

  const removePositionAssignmentToEmployee = async ({
    accountId,
    assignment_id,
  }) => {
    try {
      await removePositionAssignmentForEmployee(accountId, assignment_id);
    } catch (e) {
      console.error("error: ", e);
    }
  };

  const terminate = async ({ accountId, employee }) => {
    try {
      const { id } = employee;
      if (id) {
        await terminateEmployee(accountId, id);
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };
  const reactivate = async ({ accountId, employee }) => {
    try {
      const { id } = employee;
      if (id) {
        await reactivateEmployee(accountId, id);
      }
    } catch (e) {
      console.log("error: ", e);
    }
  };
  const activate = async ({ accountId, employee }) => {
    try {
      const { id, first_name, last_name, title } = employee;
      const payload = {
        current_status: "active",
        first_name,
        last_name,
        title,
      };
      if (id) {
        await updateEmployee(accountId, id, payload);
      }
    } catch (e) {
      console.log("e: ", e);
    }
  };
  const deleteEmp = async ({ accountId, employee }) => {
    try {
      const { id } = employee;
      if (id) {
        await deleteEmployee(accountId, id);
      }
    } catch (e) {
      console.log("e: ", e);
    }
  };
  const archiveEmp = async ({ accountId, employee }) => {
    try {
      const { id } = employee;
      if (id) {
        await archiveEmployee(accountId, id);
      }
    } catch (e) {
      console.log("e: ", e);
    }
  };
  const unArchiveEmp = async ({ accountId, employee }) => {
    try {
      const { id } = employee;
      if (id) {
        await unArchiveEmployee(accountId, id);
      }
    } catch (e) {
      console.log("e: ", e);
    }
  };

  return {
    eligiblePositions,
    onAssignPositionToEmployee,
    removePositionAssignmentToEmployee,
    allEmployees,
    loadAllEmployees,
    getCurrentEmployee,
    onUpdateAvailability,
    setCurrentEmployee,
    terminate,
    reactivate,
    activate,
    deleteEmp,
    archiveEmp,
    unArchiveEmp,
    operations,
    saveEmployee,
    saveEmployeeWithAvatar,
    checkEmailUser,
    giveAccessToEmployee,
  };
}
