import axios from "./_axios";
import { API } from "./apiEndPoints";

export const loginEmailPW = async (params) => {
  let result = await axios(false)({
    method: "post",
    url: API.LOGIN,
    data: params,
  });
  return result;
};

export const getListofAccount = (user_id) => {
  let result = axios()({
    method: "get",
    url: `${API.USER}${user_id}/account/`,
  });
  return result;
};
