import { defineStore } from "pinia";
import {
  updateFilters,
  getFilters,
} from "@components/helper/localStorageHelper";

import { ref } from "vue";

export const useFiltersStore = defineStore("filters", () => {
  const filters = ref([]);

  const setFilters = ({ key, filters }) => {
    filters.value = filters;
    updateFilters(key, filters);
  };

  const loadFilters = (key) => {
    const savedFilters = getFilters(key);
    filters.value = savedFilters;
  };

  return {
    filters,
    setFilters,
    loadFilters,
  };
});
