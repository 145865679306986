import { computed } from "vue";

import { useEmployeeViewState } from "@/views/Employees/composables/useEmployeeViewState";
import { useEmployees } from "@/composables/employees/useEmployees";
import { useDropdownItems } from "@/composables/dropdown/useDropdownItems";

export function useTableConfig() {
  const viewState = useEmployeeViewState();
  const employee = useEmployees();
  const dropdownItems = useDropdownItems();
  const accountID = JSON.parse(localStorage.getItem("currentAccountID"));

  const tableParams = computed(() => {
    return {
      headers: [
        {
          text: "Employee",
          sortable: true,
          value: "first_name",
        },
        {
          text: "Primary",
          sortable: false,
          value: "primary",
          img: "icons/briefcase.svg",
        },
        //-----------------------|commented in ER-3439 for v2|-------------------------
        // {
        //   text: "Acting As",
        //   sortable: false,
        //   value: "actingas",
        //   img: "icons/theater.svg",
        // },
        // {
        //   text: "Provides backup to",
        //   sortable: false,
        //   value: "backedup",
        //   img: "icons/briefcaseoutlined.svg",
        // },
        // {
        //   text: "Prepares for",
        //   sortable: false,
        //   value: "prepares",
        //   img: "icons/preparing.svg",
        // },
        //-----------------------|commented in ER-3439 for v2|-------------------------
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      columns: [
        {
          field: "",
          component: "EmployeeCard",
          props: (item) => ({
            class: "my-4",
            ...viewState.getEmployeeCardProps(item),
          }),
          events: (item) => ({
            "update:availability": async (availabilityValue) => {
              const payload = {
                employee: item,
                value: availabilityValue,
              };
              await employee.onUpdateAvailability({
                accountId: accountID,
                data: payload,
              });
              await employee.loadAllEmployees();
            },
          }),
        },
        {
          field: "",
          component: "EmployeeAssigmentTag",
          props: (item) => ({
            class: "my-4",
            ...viewState.getEmployeeAssigmentTagProps.getBasicInfo(item),
            ...viewState.getEmployeeAssigmentTagProps.getPositionInfo(item),
            ...viewState.getEmployeeAssigmentTagProps.getTypeInfo(item),
          }),
          events: (item) => ({
            onPrimaryAssign: () => {
              employee.setCurrentEmployee(item);
              viewState.openAssignmentWizard();
            },
            onClose: () => {
              employee.setCurrentEmployee(item);
              viewState.state.relieveAssignment.currentPosition =
                item.primary_positions[0] || null;
              viewState.state.relieveAssignment.show = true;
            },
          }),
        },
        //-----------------------|commented in ER-3439 for v2|-------------------------
        // {},
        // {},
        // {},
        //-----------------------|commented in ER-3439 for v2|-------------------------
        {
          field: "",
          component: "EmployeeBadge",
          props: (item) => ({
            ...viewState.getStateBadgeProps(item),
          }),
        },
        {
          field: "",
          component: "Dropdown",
          props: (item) => ({
            "data-testid": "employee-operations-menu",
            "translate-x-offset": 12,
            "translate-y-offset": 30,
            "is-open": false,
            "dropdown-items": dropdownItems.getEmployeeDropdownItems(item),
          }),
          events: (item) => ({
            onClick: async (operation) => {
              await viewState.handleEmployeeDropdownOperation(operation, item);
            },
          }),
        },
      ],
      filters: {
        values: viewState.state.filters.values,
        searchText: viewState.state.filters.searchText,
      },
      hideTopSection: false,
      hideActiveNo: false,
      activeRecords: {
        field: "current_status",
        value: "active",
      },
      actionLabel: "Add Employee",
      title: "Employees Organization",
      getRowStyle: (item) => ({
        backgroundColor:
          item.position?.data?.status === "active" ? "#ECFDF3" : "#FFFFFF",
      }),
    };
  });
  return {
    tableParams,
  };
}
