export default {
  install(Vue, options = {}) {
    const loaderMethods = {
      show() {
        options.store.commit("isLoading/setLoading", true);
      },
      hide() {
        options.store.commit("isLoading/setLoading", false);
      },
      setType(type) {
        options.store.commit("isLoading/setLoaderType", type);
      },
      setAppLoading(value) {
        options.store.commit("isLoading/setAppLoading", value);
      },
    };

    const reactiveData = new Vue({
      computed: {
        isAppLoading() {
          return options.store.state.isLoading.appLoading;
        },
      },
    });

    Object.defineProperty(Vue.prototype, "$loader", {
      get() {
        return {
          ...loaderMethods,
          get isAppLoading() {
            return reactiveData.isAppLoading;
          },
        };
      },
    });
  },
};
