export default {
  namespaced: true,
  state: {
    error: {
      code: null,
      message: "",
      visibility: false,
    },
  },
  mutations: {
    setError(state, { code, message }) {
      state.error.code = code;
      state.error.message = message;
      state.error.visibility = true;
    },
    clearError(state) {
      state.error.code = null;
      state.error.message = "";
      state.error.visibility = false;
    },
  },
  actions: {
    setError({ commit }, { code, message }) {
      commit("setError", { code, message });
    },
    clearError({ commit }) {
      commit("clearError");
    },
  },
  getters: {
    getError(state) {
      return state.error;
    },
  },
};
