import Vue from "vue";
import Vuex from "vuex";
import vuexPersist from "./vuexPersist";
import _ from "lodash";

import isLoading from "./isLoading";
import positionStore from "./positionStore";
import positionRoleData from "./positionRoleData";
import employeeStore from "./employeeStore";
import readinessCatalogsStore from "./readinessCatalogsStore";
import filterStore from "./filterStore";
import routeErrorHandling from "./routeErrorHandling";

Vue.use(Vuex);

export const initialStoreModules = {
  isLoading,
  positionStore,
  positionRoleData,
  employeeStore,
  readinessCatalogsStore,
  filterStore,
  routeErrorHandling,
};

export default new Vuex.Store({
  modules: _.cloneDeep(initialStoreModules),
  mutations: {
    resetState(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
    },
  },
  plugins: [vuexPersist.plugin],
});
