// clearStateMixin.js
export default {
  computed: {
    // Helper to get search text dynamically, checking for filterText or searchQuery
    searchText() {
      return this.filterText || this.searchQuery || this.search || "";
    },

    clearState() {
      if (this.searchText.length > 0 && this.appliedFilters?.length > 0) {
        return {
          label: "Clear All",
          description: `Clear All Filters & Search to view ${this.title}`,
        };
      } else if (this.searchText.length > 0) {
        return {
          label: "Clear Search",
          description: `Clear Search to view ${this.title}`,
        };
      } else if (this.appliedFilters?.length > 0) {
        return {
          label: "Clear Filter",
          description: `Clear Filters to view ${this.title}`,
        };
      } else {
        return {
          label: "Clear",
          description: `Clear to view ${this.title}`,
        };
      }
    },

    clearBtnLabel() {
      return this.clearState.label;
    },

    emptyRecordDescription() {
      return this.clearState.description;
    },
  },
};
