<template>
  <v-menu
      offset-y
      :nudge-bottom="8"
      :close-on-content-click="false"
      max-width="300"
      class="bulk-actions"
      v-model="showMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="bulk-actions__button"
          :class="{ '__disabled': props.disabled }"
          v-bind="attrs"
          v-on="on"
          outlined
          flat
          :disabled="props.disabled"
      >
        <span
            class="font-weight-regular bulk-actions__button__text"
            :class="{ '__disabled': props.disabled }"
        >
          Bulk Actions
        </span>
        <v-icon
            :color="props.disabled ? '#E0E0E0' : '#344054'"
            class="bulk-actions__icon ml-2"
            :class="{ 'bulk-actions__icon--rotated': showMenu }"
        >
          $ex-chevron-down-rounded
        </v-icon>
      </v-btn>
    </template>

    <v-card class="bulk-actions__menu pa-0">
      <v-list>
        <v-list-item
            v-for="(action, index) in actions"
            :key="index"
            @click="handleAction(action.type)"
            class="bulk-actions__item"
        >
          <v-list-item-icon>
            <v-icon class="text-white mr-2">{{ `$${ action.icon }` }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="action-text font-weight-regular">
              {{ action.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import { ref } from 'vue'

const showMenu = ref(false)
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const actions = [
  {
    text: 'Archive',
    icon: 'ex-archive',
    iconColor: 'grey darken-1',
    type: 'archive'
  },
  {
    text: 'Delete',
    icon: 'ex-bulk-delete',
    iconColor: 'grey darken-1',
    type: 'delete'
  }
]

const handleAction = (type) => {
  console.log(`Action ${type} triggered`)
  // Implement your action logic here
}
</script>

<style scoped>
.bulk-actions__button {
  background: white !important;
  border: 1px solid #D0D5DD;
  border-radius: 8px !important;
  padding: 19px 18px !important;
}
.bulk-actions__button.__disabled {
  border: 1px solid #E0E0E0;
}
.bulk-actions__button::before {
  background-color: white !important;
}
.bulk-actions__button__text {
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 20px;
  text-decoration-skip-ink: none;
  color: #344054;
  text-transform: capitalize;
  letter-spacing: 0 !important;
}

.bulk-actions__button__text.__disabled {
  color: #E0E0E0;
}

.bulk-actions__menu {
  border-radius: 12px !important;
  box-shadow: none !important;
}

.bulk-actions__item {
  transition: background-color 0.2s ease;
  margin: 4px !important;
  box-shadow: none;
  border-radius: 12px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  max-height: 48px !important;
}

.bulk-actions__item:hover {
  background-color: #F9FAFB !important;
}

.bulk-actions__item::before {
  background-color: white !important;
}

.bulk-actions__item .action-text {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  color: #344054;
}

.bulk-actions__icon {
  transition: transform 0.2s ease;
}

.bulk-actions__icon--rotated {
  transform: rotate(180deg);
}

.v-list-item__icon {
  margin: 8px 0 !important;
}

.v-list-item__content {
  padding: 8px 0 !important;
}
.v-menu__content {
  border: 1px solid #ebecf0;
  border-radius: 12px !important;
  box-shadow: none !important;
}
</style>
