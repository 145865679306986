import { reactive, getCurrentInstance } from "vue";
import { usePositions } from "@/composables/positions/usePositions";
import { useFilters } from "@/composables/filters/useFilters";
import { useRoles } from "@/composables/catalogs/useRoles";

let instance = null;

export function usePositionViewState() {
  if (instance) return instance;

  const positions = usePositions();
  const filter = useFilters();
  const roles = useRoles();
  // const router = useRouter();
  const app = getCurrentInstance();
  const { proxy } = app;

  const state = reactive({
    filters: {
      values: [],
      searchText: "",
      showFilterDialog: false,
      key: "organizationPositions",
      clearSelection: "",
      statusOptions: ["draft", "active", "retired", "inactive"],
      filterGroups: [
        {
          label: "Archived",
          items: [
            { label: "Do not Show", value: "no_archived" },
            { label: "Show", value: "show_archived" },
          ],
        },
      ],
    },
    archiveAction: null,
    addPositionModal: {
      show: false,
    },
    activatePositionModal: {
      show: false,
    },
    deletePositionModal: {
      show: false,
      message: "",
    },
    renamePositionModal: {
      show: false,
    },
    attachRoleModal: {
      show: false,
      roles: [],
      attachedRoles: [],
      selectedRoleId: null,
    },
    relieveAssignment: {
      show: false,
      currentPosition: null,
      assignedEmployee: null,
    },
    archivePositionModal: {
      show: false,
    },
    retirePositionModal: {
      show: false,
    },
    reactivatePositionModal: {
      show: false,
    },
    deactivatePositionModal: {
      show: false,
    },
    clonePositionModal: {
      show: false,
    },
    errorPopup: {
      show: false,
      title: "",
      message: "",
    },
  });

  // Filter Operations
  const getFilteredPositions = (positions) => {
    let filteredPositions = [...positions];

    // Apply text search filter
    if (state.filters.searchText?.trim()) {
      const searchText = state.filters.searchText.toUpperCase();
      filteredPositions = filteredPositions.filter((position) => {
        return (
          position.name.toUpperCase().includes(searchText) ||
          position.code?.toString().includes(searchText)
        );
      });
    }

    // Apply status filters
    if (
      state.filters.values.some((val) =>
        state.filters.statusOptions.includes(val)
      )
    ) {
      if (state.filters.values.includes("Show")) {
        filteredPositions = filteredPositions.filter((position) =>
          state.filters.values.includes(position.status)
        );
      } else {
        filteredPositions = filteredPositions.filter(
          (position) =>
            state.filters.values.includes(position.status) && !position.archived
        );
      }
    }

    // Apply archived filters
    const archivedFilters = ["Show", "Do not Show"];
    if (state.filters.values.some((val) => archivedFilters.includes(val))) {
      filteredPositions = filteredPositions.filter((position) => {
        return state.filters.values.some((filter) => {
          if (filter === "Show") {
            return true; // Show all
          } else if (filter === "Do not Show") {
            return !position.archived;
          }
          return false;
        });
      });
    }

    // Default archived filter if no filters applied
    if (state.filters.values.length === 0) {
      filteredPositions = filteredPositions.filter(
        (position) => !position.archived
      );
    }

    return filteredPositions.sort((a, b) => a.name.localeCompare(b.name));
  };

  const applyFilters = (filters) => {
    const newFilters = filters
      .map((item) => {
        if (item.value !== null) {
          return typeof item === "string" ? item : item.value;
        }
        return null;
      })
      .filter(Boolean);

    state.filters.values = newFilters;
    filter.setFilters({
      key: state.filters.key,
      filters: newFilters,
    });
    state.filters.showFilterDialog = false;
  };

  const removeFilters = (removedFilter) => {
    filter.setFilters({
      key: state.filters.key,
      filters: state.filters.values,
    });
    state.filters.clearSelection = removedFilter;
  };

  const handlePositionOperation = async (
    operation,
    accountId,
    payload = null
  ) => {
    try {
      proxy.$loader.setAppLoading(true);
      let newPosition;
      let clonedPosition;

      switch (operation) {
        case positions.operations.CREATE_POSITION:
          if (!payload) return;
          newPosition = await positions.createPosition({
            accountId,
            name: payload,
          });
          state.addPositionModal.show = false;
          await positions.loadAllPositions();
          state.filters.searchText = newPosition.code_str
            .split(" ")[0]
            .toString();
          break;

        case positions.operations.ACTIVATE_POSITION:
          await positions.activate({
            accountId,
            position: positions.getCurrentPosition(),
          });
          state.activatePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.DELETE_POSITION:
          await positions.deletePosition({
            accountId,
            position: positions.getCurrentPosition(),
          });
          state.deletePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.RENAME_POSITION:
          if (!payload) return;
          await positions.rename({
            accountId,
            position: positions.getCurrentPosition(),
            newName: payload,
          });
          state.renamePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.ARCHIVE_POSITION:
          if (state.archiveAction === "archive") {
            await positions.archive({
              accountId,
              position: positions.getCurrentPosition(),
            });
          } else if (state.archiveAction === "unarchive") {
            await positions.unarchive({
              accountId,
              position: positions.getCurrentPosition(),
            });
          }
          state.archivePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.RETIRE_POSITION:
          await positions.retire({
            accountId,
            position: positions.getCurrentPosition(),
          });
          state.retirePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.REACTIVATE_POSITION:
          await positions.reactivate({
            accountId,
            position: positions.getCurrentPosition(),
          });
          state.reactivatePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.DEACTIVATE_POSITION:
          await positions.deactivate({
            accountId,
            position: positions.getCurrentPosition(),
          });
          state.deactivatePositionModal.show = false;
          await positions.loadAllPositions();
          break;

        case positions.operations.CLONE_POSITION:
          clonedPosition = await positions.clonePosition({
            accountId,
            position: positions.getCurrentPosition(),
          });

          for (const element of positions.getCurrentPosition()?.attachments) {
            await positions.attachRole({
              accountId,
              positionId: clonedPosition.id,
              roleId: element.id,
            });
          }
          state.clonePositionModal.show = false;
          await positions.loadAllPositions();
          await roles.loadAllRoles();
          state.filters.searchText = clonedPosition.code_str
            .split(" ")[0]
            .toString();
          break;
      }
      positions.setCurrentPosition(null);
    } catch (error) {
      console.error("Error in position operation:", error);
      if (error.status === "400") {
        state.errorPopup.title = "Operation Failed";
        state.errorPopup.message =
          error.response?.data.join(" ") || "Operation failed";
        state.errorPopup.show = true;
      }
    } finally {
      proxy.$loader.setAppLoading(false);
    }
  };

  const handlePositionDropdownOperation = async (operation, position) => {
    if (!operation || !position) return;

    positions.setCurrentPosition(position);

    switch (operation.value) {
      case "activate_action":
        state.activatePositionModal.show = true;
        break;
      case "delete_action":
        state.deletePositionModal.show = true;
        break;
      case "rename_action":
        state.renamePositionModal.show = true;
        break;
      case "attach_position_role":
        await prepareAttachRole();
        break;
      case "archive_action":
        state.archiveAction = "archive";
        state.archivePositionModal.show = true;
        break;
      case "unarchive_action":
        state.archiveAction = "unarchive";
        state.archivePositionModal.show = true;
        break;
      case "retire_action":
        state.retirePositionModal.show = true;
        break;
      case "reactivate_action":
        state.reactivatePositionModal.show = true;
        break;
      case "deactivate_action":
        state.deactivatePositionModal.show = true;
        break;
      case "cloned_action":
        state.clonePositionModal.show = true;
        break;
    }
  };

  // UI Helpers
  const getPositionCode = (position) => {
    if (position?.code_str) {
      const match = position.code_str.match(/#\d+/);
      return match ? match[0] : "";
    }
    return "#";
  };

  const getStatusColor = (status) => {
    return status === "active" ? "green" : "gray";
  };

  const getPrimaryPositionOccupationProps = (position) => {
    const baseProps = {
      employeeName: "",
      employeePicture: "",
      hasPrimaryOccupation: false,
      hasVacant: false,
      label: "",
    };

    if (!position || position.status !== "active") {
      return { ...baseProps, label: "Not allowed" };
    }

    const vacantCapacity = (position.capacity || 0) - (position.occupied || 0);

    if (vacantCapacity > 0) {
      if (!position.assigned_employees?.length) {
        return {
          ...baseProps,
          hasVacant: true,
          label: "Occupy",
        };
      } else if (
        position.assigned_employees.some((emp) => emp.availability === 1)
      ) {
        const primaryEmployee = position.assigned_employees[0];
        return {
          ...baseProps,
          hasPrimaryOccupation: true,
          employeeName: `${primaryEmployee.first_name} ${primaryEmployee.last_name}`,
          employeePicture: primaryEmployee.avatar || "",
        };
      }
    }

    return baseProps;
  };

  const prepareAttachRole = async () => {
    const currentPosition = positions.getCurrentPosition();
    if (!currentPosition?.id) return;
    state.attachRoleModal.roles = roles.allRoles.value.map((role) => ({
      id: role.id,
      code: role.code_str.match(/\d+/)[0],
      name: role.name,
      status: role.status,
    }));
    state.attachRoleModal.attachedRoles = roles
      .getAttachedRolesFromPositionId(currentPosition.id)
      .map((role) => ({
        id: role.id,
        name: role.name,
      }));
    state.attachRoleModal.show = true;
  };

  const handleRoleSelected = (role) => {
    if (role?.id) {
      state.attachRoleModal.selectedRoleId = role.id;
    }
  };

  const handleAddRoleSelected = async (data) => {
    proxy.$loader.show();
    try {
      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const newRole = await roles.saveNewRole({
        accountId: accountId,
        roleName: data.name,
      });
      if (newRole) {
        state.attachRoleModal.selectedRoleId = newRole.id;
      }
      await attachRole(accountId);
    } finally {
      proxy.$loader.hide();
    }
  };

  const attachRole = async (accountId) => {
    if (
      !state.attachRoleModal.selectedRoleId ||
      !positions.getCurrentPosition()?.id
    )
      return;
    try {
      proxy.$loader.show();
      await positions.attachRole({
        accountId,
        positionId: positions.getCurrentPosition().id,
        roleId: state.attachRoleModal.selectedRoleId,
      });
      state.attachRoleModal.show = false;
      await positions.loadAllPositions();
      await roles.loadAllRoles();
    } catch (e) {
      console.error(e);
    } finally {
      proxy.$loader.hide();
    }
  };

  // Assignment Management
  const onRelievePrimaryAssignment = (position) => {
    if (position.assigned_employees?.length > 0) {
      state.relieveAssignment.show = true;
      state.relieveAssignment.assignedEmployee = position.assigned_employees[0];
      state.relieveAssignment.currentPosition = position;
    }
  };

  const closeRelieveAssignment = () => {
    state.relieveAssignment.show = false;
    state.relieveAssignment.currentPosition = null;
    state.relieveAssignment.assignedEmployee = null;
  };

  const getRelieveAssignmentAvatar = () => {
    const employee = state.relieveAssignment.assignedEmployee;
    return employee
      ? {
          picture: employee.avatar,
          firstName: employee.first_name,
          lastName: employee.last_name,
        }
      : null;
  };

  const relievePrimaryAssignment = async (accountId) => {
    const position = state.relieveAssignment.currentPosition;
    if (!position?.assigned_employees?.length) return;

    proxy.$loader.setAppLoading(true);
    try {
      const employee = position.assigned_employees[0];
      const storeEmployee = proxy.$store.state.employeeStore.employees.find(
        (emp) => emp.id === employee.id
      );

      if (!storeEmployee?.primary_positions?.length) return;

      const assignmentId = storeEmployee.primary_positions[0].id;
      await positions.relievePrimaryAssignment({
        accountId,
        assignmentId,
      });

      await positions.loadAllPositions();
      await proxy.$store.dispatch("employeeStore/loadEmployees");
      closeRelieveAssignment();
    } catch (error) {
      console.error("Error relieving primary assignment:", error);
    } finally {
      proxy.$loader.setAppLoading(false);
    }
  };

  // Capacity Management
  const handleCapacityChange = async (capacity, position, accountId) => {
    proxy.$loader.setAppLoading(true);
    try {
      await positions.updateCapacity({
        accountId,
        positionId: position.id,
        capacity,
        name: position.name,
      });
      await positions.loadAllPositions();
    } finally {
      proxy.$loader.setAppLoading(false);
    }
  };

  const openPositionDetails = async (position) => {
    await proxy.$router.push(`/organization/positions/position/${position.id}`);
  };

  instance = {
    state,
    getFilteredPositions,
    applyFilters,
    removeFilters,
    handlePositionOperation,
    handlePositionDropdownOperation,
    getPositionCode,
    getStatusColor,
    getPrimaryPositionOccupationProps,
    handleRoleSelected,
    handleAddRoleSelected,
    attachRole,
    onRelievePrimaryAssignment,
    closeRelieveAssignment,
    getRelieveAssignmentAvatar,
    relievePrimaryAssignment,
    handleCapacityChange,
    openPositionDetails,
  };

  return instance;
}
