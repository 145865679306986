import { defineStore } from "pinia";
import { getListofResponsibility, createResponsibility } from "@/api";
import {
  getResponsibilities as getLocalStorageResponsibilities,
  updateResponsibilities,
} from "@components/helper/localStorageHelper";
import { ref, computed } from "vue";

export const useResponsibilitiesStore = defineStore("responsibilities", () => {
  const responsibilities = ref([]);
  const currentResponsibility = ref(null);
  const totalResponsiblitiesCount = ref(0);

  const getResponsibilities = computed(() => responsibilities.value);

  function setCurrentResponsibility(data) {
    currentResponsibility.value = data;
  }

  function resetCurrentResponsiblity() {
    currentResponsibility.value = null;
  }

  function setResponsiblities(data) {
    responsibilities.value = data;
    totalResponsiblitiesCount.value = data.length;
  }

  async function getResponsiblityById(id) {
    const responsibility = responsibilities.value.find((item) => item.id == id);
    if (!responsibility.code_str) {
      responsibility.code_str = `#${responsibility.code} - ${responsibility.name}`;
    }
    return responsibility;
  }

  async function loadResponsibilities() {
    try {
      setResponsiblities(getLocalStorageResponsibilities);
      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const response = await getListofResponsibility(accountId, 1, 500);
      if (response.data?.count) {
        totalResponsiblitiesCount.value = response.data.count;
      }

      if (response.data?.results) {
        const sanitizedResults = response.data.results.map((item) => {
          if (!item.code_str) {
            item.code_str = `#${item.code} - ${item.name}`;
          }
          return {
            ...item,
          };
        });
        updateResponsibilities(sanitizedResults);
        setResponsiblities(sanitizedResults);
      }
    } catch (e) {
      console.error("error fetching roles: ", e);
    }
  }

  async function loadResponsibilitiesFromServer() {
    try {
      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const { data } = await getListofResponsibility(accountId, 1, 500);
      return data?.results || [];
    } catch (e) {
      console.error("error fetching roles from server: ", e);
      return [];
    }
  }

  async function createNewResponsibility(responsibility, accountId) {
    try {
      const { data } = await createResponsibility(accountId, responsibility);
      if (data) {
        responsibilities.value.push(data);
        return data;
      }
    } catch (e) {
      console.error("error creating role: ", e);
      throw e;
    }
  }

  return {
    getResponsibilities,
    setCurrentResponsibility,
    resetCurrentResponsiblity,
    setResponsiblities,
    getResponsiblityById,
    loadResponsibilities,
    loadResponsibilitiesFromServer,
    createNewResponsibility,
    responsibilities,
  };
});
