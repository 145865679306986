<template>
  <v-container fluid>
    <PathToReadinessBar
      :readiness="currentReadiness?.current_readiness"
      :needsRecalc="currentReadiness?.needs_recalc"
      @onRecalc="loadData()"
    />
    <div class="custom-path">
      <PathToReadinessTreeList
        :dataSource="dataSource"
        :topLevel="topLevel"
        :removeBorder="removeBorder"
        :dropdownItems="dropdownItems"
      />
    </div>
  </v-container>
</template>
<script>
import PathToReadinessTreeList from "@components/DataTable/PathToReadinessTreeList.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import PathToReadinessBar from "@components/PathToReadinessBar/PathToReadinessBar.vue";
import { getEmployeeAssignment, recalculateEmployeeReadiness } from "@/api";

export default {
  name: "EmployeePathToReadinessView",
  components: {
    PathToReadinessTreeList,
    PathToReadinessBar,
  },
  data() {
    return {
      dataSource: [],
      topLevel: "position",
      removeBorder: false,
      currentReadiness: null,
    };
  },
  mixins: [dropdownMixin],
  async mounted() {
    this.loadData();
  },
  methods: {
    dropdownItems() {
      const items = [
        {
          name: "Activate",
          value: "activate_action",
          icon: "icons/dropdown/activate.svg",
          order: 1,
        },
        {
          name: "Delete",
          value: "delete_action",
          icon: "icons/dropdown/delete.svg",
          negative: true,
          order: 3,
        },
      ];
      return items;
    },
    async loadData() {
      const empId = this.$route?.params?.id;
      const authUser = JSON.parse(localStorage.getItem("user"));
      this.$loader.show();

      await this.getEmpAssignments(authUser?.id, empId);
      await this.getAuthUserReadiness(authUser?.id, empId);
      this.$loader.hide();
    },
    async getEmpAssignments(userId, empId) {
      const val = true;
      const { data } = await getEmployeeAssignment(userId, empId, val);
      console.log("this is data source before change", data);

      if (data.length > 0) {
        this.dataSource = this.transformData(data);
        console.log("this is data source after change", this.dataSource);
      }
    },
    async getAuthUserReadiness(userId, empId) {
      const val = true;
      const { data } = await recalculateEmployeeReadiness(userId, empId, val);
      this.currentReadiness = data;
    },
    transformData(dataArray) {
      const result = [];
      const today = new Date();
      dataArray.forEach((data) => {
        const isNew =
          new Date(data.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
          today.getTime();
        const mainObject = {
          id: data.id,
          parentId: -1,
          data: {
            type: data.assignment_type,
            commitmentLevel: data.commitment_level,
            assignmentType: data.assignment_type,
            importanceLevel: data.importance,
            importanceSelector: true,
            totalSteps: 3,
            code: data.position_code,
            name: data.position_name,
            isNew: isNew,
            isAccepted: false,
            acceptanceStatus: "pending",
          },
          readiness: data.current_readiness,
        };
        result.push(mainObject);

        if (data.role_assignments) {
          data.role_assignments.forEach((role) => {
            const isRoleNew =
              new Date(role.assigned_on).getTime() + 10 * 24 * 60 * 60 * 1000 >
              today.getTime();
            const roleObject = {
              roleId: role.id,
              id: role.id + 1,
              parentId: mainObject.id,
              data: {
                type: role.assignment_type,
                commitmentLevel: role.commitment_level,
                assignmentType: role.assignment_type,
                importanceLevel: role.importance,
                importanceSelector: true,
                totalSteps: 3,
                code: role.role_code,
                name: role.role_name,
                isNew: isRoleNew,
                isAccepted: isRoleNew,
                acceptanceStatus: "pending",
              },
              readiness: role.current_readiness,
            };
            result.push(roleObject);

            if (role.responsibility_assignments) {
              role.responsibility_assignments.forEach((resp) => {
                const isRespNew =
                  new Date(resp.assigned_on).getTime() +
                    10 * 24 * 60 * 60 * 1000 >
                  today.getTime();
                const responsibilityObject = {
                  respId: resp.id,
                  id: resp.id + 2,
                  parentId: roleObject.id,
                  data: {
                    type: resp.assignment_type,
                    commitmentLevel: resp.commitment_level,
                    assignmentType: resp.assignment_type,
                    importanceLevel: resp.importance,
                    importanceSelector: true,
                    totalSteps: 3,
                    code: resp.responsibility_code,
                    name: resp.responsibility_name,
                    isNew: isRespNew,
                    isAccepted: isRespNew,
                    acceptanceStatus: "pending",
                  },
                  status: resp.status,
                  readiness: resp.current_readiness,
                };
                result.push(responsibilityObject);
              });
            }
          });
        }
      });
      return result;
    },
  },
};
</script>
<style>
.custom-path-readiness {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.readiness-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #344054;
}
.custom-readiness {
  width: 100%;
  margin-left: 15px;
}
.custom-readiness .progress-bar-container {
  width: 100%;
}
.custom-readiness .progress-value {
  font-weight: 600;
}
.custom-path {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  padding: 15px;
}
</style>
