<template>
  <div class="position-container">
    <ExOrgPosition
      ref="exorgposition"
      :modalHeaderState="positionHeaderState"
      :items="menuItemsProp"
      :tableData="paginatedAttachedRoles"
      :dataStream="positionChatter"
      @onButtonAction="openRoleToPosition"
      @onRequest="requestRoleData"
      @onSearchMode="searchMode = $event"
      @onLoadMore="getPositionChatter(accountId)"
      :show-load-more="totalChatterData > positionChatter.length"
      @optionClick="handleDropdownClick($event, currentPosition)"
      :pagination="paginationRoleModel"
      @roleDropdownClick="handleRoleDropdownClick($event)"
      :currentPosition="currentPosition"
      @openAssignmentModal="openAssignmentModal"
      @detachItem="callDetachItems($event)"
      :detachLoading="detachLoader"
    />
    <AttachedRole
      v-model="showRoleToPosition"
      OrgObjectType="Role to Position"
      @onSave="attachedRole"
      :items="sanitizedRoles"
      @item-selected="handleItemSelected"
      @add-item-selected="addHandleItemSelected"
      :parent-name="currentPosition?.name || ''"
    />
    <ExOrgObjectActions
      v-model="showActivatePositionDialog"
      objectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="activatePosition"
    />
    <RenameOrgObject
      v-if="showRenamePositionDialog"
      :openDialog="showRenamePositionDialog"
      :name="currentPosition?.name || ''"
      OrgObjectType="Position"
      :placeholder="currentPosition?.name || ''"
      @onAction="renamePosition($event)"
      @onClose="showRenamePositionDialog = false"
    />
    <ExDeActivatePopup
      v-model="showDeActivatePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="deActivatePosition"
    />
    <ReActivatePosition
      v-model="showReActivatePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="reActivatePosition"
    />
    <ExCloneOrgObject
      v-model="showClonedPositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="clonePosition"
    />
    <ExDeleteOrgObject
      v-model="showDeletePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="deletePosition"
    />
    <RetirePositionPopup
      v-model="showRetirePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="retirePosition"
    />
    <ExAssignmentsModal
      :dialog="showAssignmentsModal"
      @cancel="showAssignmentsModal = false"
      :assignedEmployees="assignments"
    />
    <DetachDialog
      v-model="showDetachDialog"
      :position="parentName || ''"
      :role="currentRole || {}"
      @onAction="detachAction"
    />
  </div>
</template>
<script>
import ExOrgPosition from "@components/OrgnationPosition/ExOrgPosition.vue";
import {
  getListOfRoles,
  // createRole,
  attachRoleToPosition,
  getListofResponsibility,
  getPositionChatter,
  getPositionId,
  createPosition,
  updatePosition,
  deletePosition,
  deActivatePosition,
  reActivatePosition,
  retirePosition,
  detachRoleAttachment,
  detachAttachments,
} from "@/api";
import AttachedRole from "@components/RolesCatalog/AttachedRole.vue";
import responsibilityMixin from "@components/views/mixins/responsibilityMixin";
import roleMixin from "@components/views/mixins/roleMixin";
import { mapState } from "vuex";
import ExOrgObjectActions from "@components/common/ExOrgObjectActions.vue";
import RenameOrgObject from "@components/RenameOrgObject/RenameOrgObject.vue";
import ExDeActivatePopup from "@components/DeActivatePosition/DeActivatePositionPopup.vue";
import ReActivatePosition from "@components/ReActivatePosition/ReActivatePositionPopup.vue";
import ExCloneOrgObject from "@components/CloneOrgObject/ExCloneOrgObject.vue";
import ExDeleteOrgObject from "@components/DeleteOrgObject/ExDeleteOrgObject.vue";
import RetirePositionPopup from "@components/RetirePositionPopup/RetirePositionPopup.vue";
import ExAssignmentsModal from "@components/ExAssignmentModal/ExAssignmentModal.vue";
import DetachDialog from "@components/DetachDialog/DetachDialog.vue";
export default {
  components: {
    ExOrgPosition,
    AttachedRole,
    ExOrgObjectActions,
    RenameOrgObject,
    ExDeActivatePopup,
    ReActivatePosition,
    ExCloneOrgObject,
    ExDeleteOrgObject,
    RetirePositionPopup,
    ExAssignmentsModal,
    DetachDialog,
  },
  mixins: [responsibilityMixin, roleMixin],
  data() {
    return {
      positionHeaderState: null,
      currentPosition: null,
      searchMode: false,
      matchedData: [],
      paginationRoleModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      showRoleToPosition: false,
      positionChatter: [],
      totalChatterData: 0,
      positionId: null,
      items: [],
      renderIndex: 0,
      showActivatePositionDialog: false,
      showRenamePositionDialog: false,
      showDeActivatePositionDialog: false,
      showReActivatePositionDialog: false,
      showClonedPositionDialog: false,
      showDeletePositionDialog: false,
      showRetirePositionDialog: false,
      showDetachDialog: false,
      currentRole: null,
      showAssignmentsModal: false,
      assignments: [],
      parentName: null,
    };
  },
  computed: {
    ...mapState("readinessCatalogsStore", {
      storeRoles: (state) => state.roles,
      storeResponsibilities: (state) => state.responsibilities,
    }),
    paginatedAttachedRoles() {
      if (!this.searchMode) {
        return this.sanitizedItems.slice(
          (this.paginationRoleModel.page - 1) * 10,
          (this.paginationRoleModel.page - 1) * 10 + 10
        );
      } else {
        return this.sanitizedItems;
      }
    },
    sanitizedItems() {
      let roleList = [];
      if (this.storeRoles && this.storeRoles.length > 0) {
        roleList = this.storeRoles.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.parentId = -1;
          newEl.status = el.status;
          newEl.archived = el.archived;
          newEl.data = {
            type: "role",
            code: el.code + "",
            name: el.name,
            status: el.status,
          };
          newEl.info = el;
          newEl.attachedTo = {
            data: el.attachments,
          };
          newEl.types = "rolePosition";
          const totalCount = Object.values(el.attachments_count).reduce(
            (sum, count) => sum + count,
            0
          );
          newEl.assignment = totalCount;
          return newEl;
        });
        const roleData = this.getMatchedData(
          roleList,
          this.currentPosition?.attachments ?? []
        );
        // this.paginationRoleModel.total = roleData.length;
        const matchedRoleData = this.transformData(roleData);
        const itemsToAdd = [];
        const responsibilities = this.storeResponsibilities;
        let responsibilitiesCopy = [];
        if (responsibilities && responsibilities.length > 0) {
          responsibilitiesCopy = responsibilities.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.realId = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            newEl.archived = el.archived;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            newEl.types = "responsibilityPosition";
            const typeCountRes = el.attachments.reduce((acc, att) => {
              acc[att.type] = (acc[att.type] || 0) + 1;
              return acc;
            }, {});
            el.attachments = Object.entries(typeCountRes).map(
              ([type, quantity]) => ({ type, quantity })
            );
            const totalCount = Object.values(el.attachments_count).reduce(
              (sum, count) => sum + count,
              0
            );
            newEl.attachedTo = {
              data: el.attachments,
            };
            newEl.assignment = totalCount;
            return newEl;
          });
        }
        matchedRoleData.forEach((el) => {
          const responsibilitiesAttachments =
            el.attachedToItems.data?.filter(
              (ra) => ra.type == "responsibility"
            ) || [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            if (!tempItem) return;
            const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
            if (tempItemCopy) {
              tempItemCopy.id = itemsToAdd.length + 400;
              tempItemCopy.parentId = el.id;
              itemsToAdd.push(tempItemCopy);
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(matchedRoleData.concat(itemsToAdd))
        );
        return finalArray;
      } else {
        return [];
      }
    },
    sanitizedRoles() {
      if (this.storeRoles && this.storeRoles.length > 0) {
        const attachedResponsibilityIds = new Set(
          this.currentPosition.attachments.map((attachment) => attachment.id)
        );
        const unmatchedResponsibilities = this.storeRoles
          .filter((item) => !attachedResponsibilityIds.has(item.id))
          .filter((item) => item.status !== "retired");
        return unmatchedResponsibilities.map((item) => ({
          id: item.id,
          code: item.code_str.match(/\d+/)[0],
          name: item.name,
          status: item.status,
        }));
      } else {
        return [];
      }
    },
    menuItemsProp() {
      return [
        {
          title: "Roles attached",
          count: this.sanitizedItems.filter(
            (el) => el?.status && el?.status == "active"
          ).length,
        },
        {
          title: "Assignments",
          count: 0,
        },
        { title: "Description", count: 0 },
        { title: "Chatter", count: this.totalChatterData ?? 0 },
      ];
    },
  },
  watch: {
    totalChatterData(newVal) {
      if (newVal) {
        const index = this.menuItemsProp.findIndex(
          (el) => el.title == "Chatter"
        );
        this.menuItemsProp[index].count = newVal;
        this.renderIndex++;
      }
    },
    sanitizedItems(newVal) {
      if (newVal) {
        this.paginationRoleModel.total = newVal.filter(
          (el) => el.parentId == -1
        ).length;
      }
    },
  },
  async mounted() {
    this.$loader.show();
    await this.loadData();
    await this.getPositionByID();
    this.$loader.hide();
  },
  methods: {
    callDetachItems(data) {
      this.detachItems(data, () => {
        this.loadData();
      });
    },
    handleDropdownClick(data, row) {
      switch (data.item.value) {
        case "activate_action":
          this.showActivatePosition(row);
          break;
        case "delete_action":
          this.showDeletePosition(row);
          break;
        case "rename_action":
          this.showRenamePosition(row);
          break;
        case "attach_position_role":
          this.openRoleToPosition(row);
          break;
        case "retire_action":
          this.showRetirePosition(row);
          break;
        case "deactivate_action":
          this.showDeActivatePosition(row);
          break;
        case "reactivate_action":
          this.showReActivatePosition(row);
          break;
        case "cloned_action":
          this.showClonedPosition(row);
          break;
        default:
          break;
      }
    },
    onCloseModal() {
      this.showAddPositionDialog = false;
      this.showActivatePositionDialog = false;
      this.showDeletePositionDialog = false;
      this.showRenamePositionDialog = false;
      this.currentPosition = null;
      this.showPositionRoleDialog = false;
      this.showClonedPositionDialog = false;
      this.showDeActivatePositionDialog = false;
      this.showReActivatePositionDialog = false;
      this.showRetirePositionDialog = false;
    },
    showActivatePosition(data) {
      this.currentPosition = data;
      this.showActivatePositionDialog = true;
    },
    showRenamePosition(data) {
      this.currentPosition = data;
      this.showRenamePositionDialog = true;
    },
    showDeActivatePosition(data) {
      this.currentPosition = data;
      this.showDeActivatePositionDialog = true;
    },
    showReActivatePosition(data) {
      this.currentPosition = data;
      this.showReActivatePositionDialog = true;
    },
    showClonedPosition(data) {
      this.currentPosition = data;
      this.showClonedPositionDialog = true;
    },
    showDeletePosition(data) {
      this.currentPosition = data;
      this.showDeletePositionDialog = true;
    },
    showRetirePosition(data) {
      this.currentPosition = data;
      this.showRetirePositionDialog = true;
    },
    async activatePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          const payload = {
            name: this.currentPosition.name,
            status: "active",
            account: this.accountId,
          };
          await updatePosition(this.accountId, id, payload);
        }
        this.onCloseModal();
        await this.loadData();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async renamePosition(newName) {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          const payload = {
            name: newName,
            account: this.accountId,
          };
          await updatePosition(this.accountId, id, payload);
        }
        this.onCloseModal();
        await this.loadData();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async deActivatePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          await deActivatePosition(this.accountId, id);
        }
        this.onCloseModal();
        await this.loadData();
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async reActivatePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          await reActivatePosition(this.accountId, id);
        }
        this.onCloseModal();
        await this.$parent.loadData();
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async clonePosition() {
      try {
        const name = this.currentPosition?.name;
        const payload = {
          name: "Clone of " + name,
          account: this.accountId,
        };
        const { data } = await createPosition(this.accountId, payload);
        const objId = data.id;
        const attachmentPromises = this.currentPosition?.attachments.map(
          (att) => {
            const payload1 = {
              attachment_id: att.id,
            };
            return attachRoleToPosition(this.accountId, objId, payload1);
          }
        );
        await Promise.all(attachmentPromises);
        this.onCloseModal();
        this.$router.push(`/organization/positions/position/${objId}`);
        await this.loadData();
        this.filterText = data.code.toString();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async deletePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          await deletePosition(this.accountId, id);
        }
        this.$router.push("/organization/positions");
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async retirePosition() {
      try {
        await retirePosition(this.accountId, this.currentPosition?.id);
        this.onCloseModal();
        await this.loadData();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async loadData() {
      this.accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      await this.loadPositionByID(this.accountId, this.$route.params.pId);
      await this.loadNewDataSource();
      await this.getPositionChatter(this.accountId);
    },
    async getPositionByID() {
      this.accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const { data } = await getPositionId(
        this.accountId,
        this.$route.params.pId
      );
      if (data) {
        this.currentPosition = data;
        this.positionHeaderState = {
          status: data.status,
          icon: "org_icon_position",
          label: "Position",
          code: data.code ? "#" + data.code : "null",
          name: data.name,
        };
      }
    },
    async loadRoles(pageNo = 1) {
      const { data } = await getListOfRoles(this.accountId, pageNo, 500);
      if (data && data.results) {
        this.dataSourceRoles = data.results.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.parentId = -1;
          newEl.status = el.status;
          newEl.data = {
            type: "role",
            code: el.code + "",
            name: el.name,
            status: el.status,
          };
          newEl.info = el;
          newEl.attachedTo = {
            data: el.attachments,
          };
          newEl.dashboard = {
            data: {
              countApprentice: 0,
              countProfessional: 0,
              countCoach: 0,
            },
          };
          newEl.types = "rolePosition";
          const totalCount = Object.values(el.attachments_count).reduce(
            (sum, count) => sum + count,
            0
          );
          newEl.assignment = totalCount;
          return newEl;
        });
        const roleData = this.getMatchedData(
          this.dataSourceRoles,
          this.currentPosition?.attachments
        );
        this.paginationRoleModel.total = roleData.length;
        const matchedRoleData = this.transformData(roleData);
        const itemsToAdd = [];
        const responsibilities = await getListofResponsibility(
          this.accountId,
          1,
          500
        );
        let responsibilitiesCopy = [];
        if (responsibilities.data && responsibilities.data.results) {
          responsibilitiesCopy = responsibilities.data.results.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.realId = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            newEl.types = "responsibilityPosition";
            const typeCountRes = el.attachments.reduce((acc, att) => {
              acc[att.type] = (acc[att.type] || 0) + 1;
              return acc;
            }, {});
            el.attachments = Object.entries(typeCountRes).map(
              ([type, quantity]) => ({ type, quantity })
            );
            const totalCount = Object.values(el.attachments_count).reduce(
              (sum, count) => sum + count,
              0
            );
            newEl.attachedTo = {
              data: el.attachments,
            };
            newEl.assignment = totalCount;
            return newEl;
          });
        }
        matchedRoleData.forEach((el) => {
          const responsibilitiesAttachments =
            el.attachedToItems.data?.filter(
              (ra) => ra.type == "responsibility"
            ) || [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
            if (tempItemCopy) {
              tempItemCopy.id = itemsToAdd.length + 400;
              tempItemCopy.parentId = el.id;
              itemsToAdd.push(tempItemCopy);
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(matchedRoleData.concat(itemsToAdd))
        );
        this.matchedData = finalArray;
        const attachedResponsibilityIds = new Set(
          this.currentPosition.attachments.map((attachment) => attachment.id)
        );
        const unmatchedResponsibilities = this.dataSourceRoles
          .filter(
            (item) =>
              item.data.type === "role" &&
              !attachedResponsibilityIds.has(item.id)
          )
          .filter((item) => item.status !== "retired");
        this.items = unmatchedResponsibilities.map((item) => ({
          id: item.data.code,
          code: item.data.code,
          name: item.data.name,
          status: item.status,
        }));
      }
    },
    getMatchedData(data, attachments) {
      const attachmentIds = attachments.map((att) => att.id);
      return data.filter((item) => attachmentIds.includes(item.id));
    },
    async attachedRole() {
      try {
        this.$loader.setAppLoading(true);
        const objId = this.currentPosition?.id;
        const payload = {
          attachment_id: this.attachmentId,
        };
        await attachRoleToPosition(this.accountId, objId, payload);
        this.$loader.setAppLoading(false);
        this.showRoleToPosition = false;
        await this.loadData();
      } catch (e) {
        this.$loader.setAppLoading(false);
        console.log("e: ", e);
      }
    },
    async requestRoleData(data) {
      if (data && data.page) {
        this.paginationRoleModel.page = data.page;
      }
    },
    transformData(input) {
      return input.map((item) => {
        const transformedItem = {
          id: item.id,
          parentId: item.parentId,
          archived: item.archived,
          data: {
            type: item.data.type,
            code: item.data.code ? item.data.code : "null",
            name: item.data.name,
            status: item?.data?.status,
          },
          attachedTo: {
            data: [],
          },
          attachedToItems: {
            ...item.attachedTo,
          },
          assignment: item.assignment,
          status: item.status,
          types: item.types,
          dashboard: item.dashboard,
          info: item.info,
        };
        const typeCountMap = item.attachedTo.data.reduce((acc, att) => {
          acc[att.type] = (acc[att.type] || 0) + 1;
          return acc;
        }, {});
        transformedItem.attachedTo.data = Object.entries(typeCountMap).map(
          ([type, quantity]) => ({ type, quantity })
        );
        return transformedItem;
      });
    },
    openRoleToPosition() {
      // this.loadRoles();
      this.showRoleToPosition = true;
    },
    handleItemSelected(data) {
      if (data && Object.prototype.hasOwnProperty.call(data, "id")) {
        this.attachmentId = data.id;
      }
    },
    async addHandleItemSelected(data1) {
      try {
        const role = {
          name: data1.name,
          account: this.accountId,
        };
        const newRole = await this.$store.dispatch(
          "readinessCatalogsStore/createRole",
          { role, accountId: this.accountId }
        );
        this.attachmentId = newRole.id;
        this.attachedRole();
        await this.refreshData();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async refreshData() {
      this.accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      await this.loadPositionByID(this.accountId, this.$route.params.pId);
      await this.loadNewDataSource();
    },
    async getPositionChatter(account_id) {
      let page = Math.ceil(this.positionChatter.length / 10);
      const selectedTab = this.$refs.exorgposition?.selectedTab;
      if (selectedTab == 0) {
        page = 0;
      }
      const { data } = await getPositionChatter(
        account_id,
        this.$route.params.pId,
        page + 1
      );
      if (data && data.count) {
        this.totalChatterData = data.count;
      }
      if (data && data.results) {
        let chatterItems = data.results.map((el) => {
          const date = new Date(el.recorded_on);
          const milliseconds = date.getTime();
          const millisecondsString = milliseconds.toString();
          let nameParts = el.recorded_by_name.split(" ");
          const newEl = {};
          newEl.id = el.id;
          if (nameParts.length === 2) {
            newEl.avatar = {
              picture: "",
              firstName: nameParts[0],
              lastName: nameParts[1],
            };
          } else {
            newEl.avatar = {
              picture: "",
              firstName: el.recorded_by_name,
              lastName: "",
            };
          }
          newEl.IsExIQtiveBot = false;
          newEl.message = el.note;
          newEl.created_on = millisecondsString;
          newEl.IsHistory = el.is_history;
          return newEl;
        });
        this.positionChatter.push(...chatterItems);
      }
    },
    openAssignmentModal(data) {
      this.showAssignmentsModal = true;
      const positions =
        JSON.parse(localStorage.getItem("exlAppData") || "{}").positions || [];
      let assignedEmployees = [];
      if (data.info) {
        const positionIds = data.info.attachments
          .filter((attachment) => attachment.type === "position")
          .map((attachment) => attachment.id);

        positionIds.forEach((posId) => {
          const position = positions.find((p) => p.id === posId);
          if (position && position.assigned_employees) {
            assignedEmployees = assignedEmployees.concat(
              position.assigned_employees
            );
          }
        });
      }
      this.assignments = assignedEmployees;
    },
    detachAction() {
      if (this.currentRole.type === "responsibility") {
        this.detachResponsibilityAction(this.currentRole);
      } else if (this.currentRole.type === "position") {
        this.detachRoleAction(this.currentRole);
      }
      this.showDetachDialog = false;
    },
    async detachRoleAction(data) {
      try {
        const payload = {
          parent_id: data.id,
        };
        await detachRoleAttachment(this.accountId, data.parentId, payload);
        await this.loadData();
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async detachResponsibilityAction(data) {
      try {
        const payload = {
          parent_id: data.parentId,
        };
        await detachAttachments(this.accountId, data.id, payload);
        await this.loadData();
      } catch (e) {
        console.error("e: ", e);
      }
    },
  },
};
</script>

<style scoped>
.position-container {
  width: 100%;
  padding: 25px;
}
</style>
