import VuexPersist from "vuex-persist";
import localforage from "localforage";

const vuexPersist = new VuexPersist({
  key: "clear_intent",
  storage: localforage,
  // storage: window.localStorage,
});

export default vuexPersist;
