<template>
  <v-dialog v-model="showDialog" persistent max-width="500px">
    <div class="pop-up">
      <div class="modal_header">
        <div class="d-flex flex-row align-items-center">
          <PayRangeEditorIcon />
          <v-spacer />
          <v-btn icon color="grey" @click="closeDialog">
            <Icon class="cursor-pointer" name="popup_close" />
          </v-btn>
        </div>
        <h3 class="text-black-600 main-text px-6 text-center">
          {{ isEdit ? "Edit" : "Create"}} Pay Range {{ isEdit && stepNumber == 2 ? 'Values' : '' }}
        </h3>
      </div>
      <div
        class="text-center d-flex align-center justify-center head-radio-wizard"
      >
        <div class="step-label mr-2">Step {{ localStepNumber }} of 2</div>
        <v-icon size="24px" class="mr-2" style="border-radius: 50%">
          {{ localStepNumber === 1 ? "$exRadioCurrentCheck" : "$exRadioChecked" }}
        </v-icon>
        <v-icon size="24px" class="mr-2" style="border-radius: 50%">
          {{ localStepNumber === 1 ? "$exRadioCheckOff" : "$exRadioCurrentCheck" }}
        </v-icon>
      </div>
      <div v-if="localStepNumber === 1">
        <div class="step-one-container">
          <div class="modal_content">
            <p class="text-black-500 mb-2">Name*</p>
            <ExTextInput
              ref="nameInput"
              class="input"
              :class="{ 'changed-field': changedFieldsStepOne.payRangeName && isEdit }"
              required
              placeholder="Enter name"
              outlined
              color="rgba(208, 213, 221, 1)"
              base-color="rgba(208, 213, 221, 1)"
              name="payRangeName"
              v-model="payRangeName"
              id="name"
              type="text"
              :hide-details="false"
              @input="handleInputChange('payRangeName')"
            />
          </div>
          <div class="modal_content">
            <CountryPicker
              pickerLabel="Location in Country"
              label="Country"
              searchLabel="Select Country"
              @item-country-selected="handleCountryChange"
              v-model="selectedCountry"
              :class="{ 'changed-field': changedFieldsStepOne.selectedCountry && isEdit }"
              @clear-selection="clearSelectedCountry"
              :isSelectedItemRemove="true"
              :isforceReadonly="false"
            />
          </div>
          <div class="modal_content">
            <CheckboxInput
              class="pay-range-checkbox"
              v-model="isApplicableCountryWide"
              label="Pay Range applicable country wide"
            />
            <span class="checkbox-description"
              >Unselect to specify a Region</span
            >
          </div>
          <div v-if="!isApplicableCountryWide" class="modal_content">
            <p class="text-black-500 mb-2">Location in Region*</p>
            <ExTextInput
              ref="nameInput"
              class="input"
              :class="{ 'changed-field': changedFieldsStepOne.locationInRegion && isEdit }"
              required
              placeholder="Enter Location Region"
              outlined
              color="rgba(208, 213, 221, 1)"
              base-color="rgba(208, 213, 221, 1)"
              name="locationInRegion"
              v-model="locationInRegion"
              id="name"
              type="text"
              :hide-details="false"
              @input="handleInputChange('locationInRegion')"
            />
          </div>
          <div class="modal_content">
            <CurrencyPicker
              pickerLabel="Used Currency"
              label="Currency"
              searchLabel="Select Currency"
              @item-currency-selected="handleCurrencyChange"
              v-model="selectedCurrency"
              :class="{ 'changed-field': changedFieldsStepOne.selectedCurrency && isEdit }"
              @clear-selection="clearSelectedCurrency"
              :isSelectedItemRemove="true"
              :isforceReadonly="false"
            />
          </div>
          <div class="expressed-range-container">
            <ExSelectPicker
            :class="[
                'expressed-selector',
                selectedExpressOption ? 'margin-left-zero' : '',
                changedFieldsStepOne.selectedExpressOption && isEdit ? 'changed-field' : '',
              ]"
              pickerLabel="Range Expressed"
              label="Range Expressed"
              searchLabel="Select Range Expressed"
              :items="expressedOptions"
              v-model="selectedExpressOption"
              @item-selected="handleExpressRange"
              @clear-selection="clearExpressRange"
              :isSelectedItemRemove="true"
              :hasImageItem="false"
            />
          </div>
          <div class="d-flex justify-end py-6">
            <ExButton
              :label="isEdit ? 'Save' : 'Next'"
              class="step-one-btn"
              :class="!isEdit ? 'mr-3' : ''"
              size="normal"
              :normal="!isStepOneValid ? 'normal-disable' : 'normal-hover'"
              @onClick="isEdit ? update() : next()"
              :disabled="!isStepOneValid"
               v-bind="{ fullwidth: isEdit ? 'default' : '' }"
              :rightIcon="!isEdit ? 'right-icon.svg' : ''"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="step-two-header text-center">
          <span class="secondary-text">You are adding this pay range</span>
          <PayRangeHeader
            :hoverEnabled="false"
            :countryFlag="selectedCountry.icon"
            :payRangeName="payRangeName"
            :payRangeLocation="'Region-specific'"
            :payRangeCurrencyCode="selectedCurrency.code"
            :payRangeExpressedAs="payRangeExpressed"
            :isEdit="isEdit"
            class="d-flex justify-center"
          />
          <span class="secondary-text">Values are for Full Time (1 FTE)</span>
          <PayRangeEditor :isEditable="isEdit" :topRange="topRange" :bottomRange="bottomRange" @topRangeUpdated="handleTopRangeChange" @bottomRangeUpdated="handleBottomRangeChange" />
          <div class="d-flex justify-end py-6">
            <ExButton
              v-if="!isEdit"
              class="mr-3"
              size="normal"
              outline="outline-hover"
              icon="arrow-icon.svg"
              :label="'Back'"
              @onClick="goBack()"
            />
            <ExButton
              label="Save"
              class="step-two-btn"
              :class="!isEdit ? 'mr-3' : ''"
              size="normal"
              :normal="!isStepTwoValid ? 'normal-disable' : 'normal-hover'"
              @onClick="isEdit ? update() : next()"
              :disabled="!isStepTwoValid"
              v-bind="{ fullwidth: isEdit ? true : '' }"
            />
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import ExSelectPicker from "@components/SelectPicker/ExSelectPicker.vue";
import CurrencyPicker from "../CurrencyPicker/CurrencyPicker.vue";
import PayRangeEditorIcon from "@components/svgs/PayRangeEditorIcon.vue";
import ExTextInput from "@components/TextInput/ExTextInput.vue";
import ExButton from "@components/buttons/Button.vue";
import Icon from "@components/icons/Icon.vue";
import CheckboxInput from "@components/Checkbox/CheckboxInput.vue";
import CountryPicker from "@components/CountryPicker/CountryPicker.vue";
import PayRangeHeader from "@components/PayRangeHeader/PayRangeHeader.vue";
import PayRangeEditor from "@components/PayRange/PayRangeEditor.vue";

const props = defineProps({
  showDialog: { type: Boolean, default: false },
  isEdit: { type: Boolean, default: false },
  stepNumber: { type: Number, default: 1 }
});

const emit = defineEmits(["closeAddDialog"]);
const payRangeName = ref("");
const topRange = ref(0);
const bottomRange = ref(0);
const locationInRegion = ref("");
const localStepNumber = ref(props.stepNumber);
const selectedExpressOption = ref({ id: 3, name: "Per Year", code: "per_year" });
const isApplicableCountryWide = ref(true);
const selectedCountry = ref({
  id: 1,
  name: "United States",
  icon: "https://flagcdn.com/us.svg",
});
const selectedCurrency = ref({
  id: 1,
  name: "United States Dollar",
  code: "USD",
  icon: "https://flagcdn.com/us.svg",
});

const isStepTwoValid = ref(false);

const changedFieldsStepOne = ref({
  payRangeName: false,
  selectedCountry: false,
  selectedCurrency: false,
  selectedExpressOption: false,
  locationInRegion: false,
});

const expressedOptions = ref([
  { id: 1, name: "Per Hour", code: "per_hour" },
  { id: 2, name: "Per Month", code: "per_month" },
  { id: 3, name: "Per Year", code: "per_year" },
]);

const handleExpressRange = (item) => {
  selectedExpressOption.value = item;
  changedFieldsStepOne.value.selectedExpressOption = true;
};

const handleCurrencyChange = (item) => {
  selectedCurrency.value = item;
  changedFieldsStepOne.value.selectedCurrency = true;
};

const handleCountryChange = (item) => {
  selectedCountry.value = item;
  changedFieldsStepOne.value.selectedCountry = true;
};

const clearExpressRange = () => {
  selectedExpressOption.value = null;
  changedFieldsStepOne.value.selectedExpressOption = false;
};

const clearSelectedCountry = () => {
  selectedCountry.value = null;
  changedFieldsStepOne.value.selectedCountry = false;
}

const clearSelectedCurrency = () => {
  selectedCurrency.value = null;
  changedFieldsStepOne.value.selectedCurrency = false;
}

const handleInputChange = (field) => {
  changedFieldsStepOne.value[field] = true;
};

const handleTopRangeChange = (field) => {
  topRange.value = field;
  isRangeValid();
}

const handleBottomRangeChange = (field) => {
  bottomRange.value = field;
  isRangeValid();
}

const isStepOneValid = computed(() => {
  const hasChangedFields = Object.values(changedFieldsStepOne.value).includes(true);
  const isNameValid = payRangeName.value.trim() !== '';
  const isLocationInRegionValid = locationInRegion.value.trim() !== '';
  const isCurrencySelected = selectedCurrency.value !== null;
  const isCountrySelected = selectedCountry.value !== null;
  const isExpressOptionSelected = selectedExpressOption.value !== null;

  if (props.isEdit) {
    return hasChangedFields && isCurrencySelected && isCountrySelected && isExpressOptionSelected;
  } else {
    return (
      hasChangedFields &&
      isNameValid &&
      isCurrencySelected &&
      isCountrySelected &&
      isExpressOptionSelected &&
      (isApplicableCountryWide.value || isLocationInRegionValid)
    );
  }
});

const isRangeValid = () => {
  const isTopRangePositive = topRange.value > 0;
  const isTopGreaterThanBottom = topRange.value > bottomRange.value;
  isStepTwoValid.value = isTopRangePositive && isTopGreaterThanBottom;
};

const payRangeExpressed = computed(() => {
  return selectedExpressOption?.value.code.replace("per_", "") || "hour";
});

const goBack = () => {
  localStepNumber.value = 1;
};

const next = () => {
  localStepNumber.value = 2;
};

const closeDialog = () => {
  emit('closeAddDialog')
}
</script>

<style scoped>
.modal_header {
  display: flex;
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  gap: 16px;
}

.main-text {
  padding-bottom: 20px;
}

.head-radio-wizard {
  padding-bottom: 24px;
}

.text-black-600 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}
.text-black-500 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}
.input {
  border-radius: 8px;
  margin: 0;
}
.pop-up {
  width: 500px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
  background-image: url("../assets/pay-range-editor-background.svg");
}
.second-bg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  width: auto;
  height: auto;
}

.background-popup {
  position: absolute;
  z-index: 0;
}
.main-text {
  margin-top: -5px;
}
.modal_header {
  z-index: 1;
  position: relative;
}
.modal_content {
  z-index: 1;
  position: relative;
}
.font-weight-400 {
  font-weight: 400;
}
.font-size-14 {
  font-size: 14px;
}
.text-disabled {
  color: rgba(102, 112, 133, 1);
}

::v-deep(.custom-response-picker.v-input input) {
  color: #101828;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0px !important;
}

.checkbox-description {
  color: #475467;
  font-size: 14px;
  position: relative;
  top: -10px;
  left: 28px;
}

::v-deep(.expressed-selector) {
  left: 0px !important  ;
}

::v-deep(.container) {
  padding: 0px;
}

.step-two-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 37px;
}

.secondary-text {
  color: #666;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

::v-deep(.pay-range-header .pay-range-expressed) {
  margin-left: 0px !important;
}

::v-deep(.readiness) {
  text-align: start !important;
}

::v-deep .changed-field fieldset {
  border: 1px solid #7f56d9 !important;
  background: #f2edff !important;
}

::v-deep .custom-response-picker {
  width: 100%;
}

::v-deep .list-picker-container {
  width: 100%;
}
.step-one-container {
  padding: 0px 37px;
}

::v-deep .full-width-btn {
  width: 100% !important;
}

::v-deep .res-custom-list-picker {
  width: 100% !important;
}

::v-deep .pay-range-checkbox .icon-check .v-btn {
  width: 24px !important;
}

::v-deep .v-input__slot {
  height: 44px !important;
}
.expressed-range-container { margin-top: 4px;}
</style>
<style>
.pop-up .v-input__slot {
  min-height: 44px !important;
}
</style>
