<template>
  <div>
    <ExSelectPicker
      :pickerLabel="pickerLabel"
      :label="label"
      :searchLabel="searchLabel"
      :items="countries"
      :value="modelValue"
      :useCode="useCode"
      :isSelectedItemRemove="isSelectedItemRemove"
      @item-selected="handleItemSelected"
      @clear-selection="clearItemSelected"
      :isforceReadonly="isforceReadonly"
    />
  </div>
</template>

<script setup>
import ExSelectPicker from "@components/SelectPicker/ExSelectPicker.vue";
import { defineProps, defineEmits } from "vue";

defineProps({
  modelValue: {
    type: Object,
    required: false,
    default: () => ({
      id: 1,
      name: "United States",
      icon: "https://flagcdn.com/us.svg",
    }),
  },
  label: {
    type: String,
    required: false,
    default: "Select Currency",
  },
  searchLabel: {
    type: String,
    required: false,
    default: "Search Currency",
  },
  pickerLabel: {
    type: String,
    required: false,
    default: "Search Currency",
  },
  useCode: {
    type: Boolean,
    required: false,
    default: true,
  },
  isSelectedItemRemove: {
    type: Boolean,
    required: false,
    default: false,
  },
  isforceReadonly: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["item-selected-country"]);

const countries = [
  { id: 1, name: "United States", icon: "https://flagcdn.com/us.svg" },
  { id: 2, name: "Europe", icon: "https://flagcdn.com/eu.svg" },
  { id: 3, name: "United Kingdom", icon: "https://flagcdn.com/gb.svg" },
  { id: 4, name: "Japan", icon: "https://flagcdn.com/jp.svg" },
  { id: 5, name: "Canada", icon: "https://flagcdn.com/ca.svg" },
  { id: 6, name: "Australia", icon: "https://flagcdn.com/au.svg" },
];

const handleItemSelected = (selectedItem) => {
  emit("item-country-selected", selectedItem);
};

const clearItemSelected = () => {
  emit("clear-selection")
}
</script>

<style scoped>
::v-deep(.selected-currency-icon img) {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
  margin-left: 0px !important;
  z-index: 1;
}

::v-deep(.v-avatar) {
  overflow: none !important;
}

::v-deep(.custom-response-picker) { 
  width: 100%;
}
</style>
