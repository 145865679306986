<template>
  <v-container fluid>
    <StandarPageWithList title="Dashboard" />
  </v-container>
</template>
<script>
import StandarPageWithList from "@components/common/PageHeaderWithButton.vue";
export default {
  name: "DashboardView",
  components: {
    StandarPageWithList,
  },
};
</script>
<style scoped></style>
