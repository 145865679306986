<template>
  <div>
    <div class="reference-country-view">
      <div class="reference-country-header">
        <div class="back-icon" @click="$emit('go-back')">
          <v-img
            max-height="24px"
            max-width="24px"
            :src="require('@components/assets/side-bar/backIcon.svg')"
          ></v-img>
        </div>
        <span class="country-view-title">Countries used in this account</span>
      </div>
      <span class="currecy-view-desc">
        This configuration enables features in the compensation app
      </span>
      <hr class="hr mb-3 mt-6" />
    </div>
    <div>
      <CountryTable
        :dataSource="operatingCountries"
        :dataSourceObj="operatingCountriesObj"
        @get-chatter-list="getChatterList($event, 1)"
        :chatterList="chatterList"
        :totalCheckpointChatter="totalCountryChatter"
        @requestMoreChatter="getChatterList($event, chatterPage + 1)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed } from "vue";
import CountryTable from "@components/CountryTable/CountryTable.vue";
import { getOperatingCountry, getCountryChatterList } from "@/api";

const operatingCountries = ref([]);
const operatingCountriesObj = ref({});
const chatterList = ref([]);
const totalCountryChatter = ref(0);
const emit = defineEmits(["showLoader", "hideLoader"]);
onMounted(async () => {
  emit("showLoader");
  await fetchOperatingCountry();
  setTimeout(() => {
    emit("hideLoader");
  }, 1000);
});
const chatterPage = computed(() => {
  return Math.ceil(chatterList.value.length / 10);
});
const fetchOperatingCountry = async () => {
  const accountID = JSON.parse(localStorage.getItem("currentAccountID"));
  const { data } = await getOperatingCountry(accountID);
  if (data) {
    operatingCountriesObj.value = data;
    operatingCountries.value = data.results;
  }
};
const getChatterList = async (item, page) => {
  const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
  if (page === 1) {
    chatterList.value = [];
  }
  const { data } = await getCountryChatterList(accountId, item.id, page);
  if (data && data.count) {
    totalCountryChatter.value = data.count;
  }
  if (data && data.results) {
    let chatterItems = data.results.map((el) => {
      const date = new Date(el.recorded_on);
      const milliseconds = date.getTime();
      const millisecondsString = milliseconds.toString();
      let nameParts = el.recorded_by_name.split(" ");
      const newEl = {};
      newEl.id = el.id;
      if (nameParts.length === 2) {
        newEl.avatar = {
          picture: "",
          firstName: nameParts[0],
          lastName: nameParts[1],
        };
      } else {
        newEl.avatar = {
          picture: "",
          firstName: el.recorded_by_name,
          lastName: "",
        };
      }
      newEl.IsExIQtiveBot = false;
      newEl.message = truncateHtml(el.note);
      newEl.created_on = millisecondsString;
      newEl.IsHistory = el.is_history;
      return newEl;
    });
    chatterList.value.push(...chatterItems);
  }
};
const truncateHtml = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  let text = div.textContent || div.innerText || "";
  return text;
};
</script>

<style scoped>
.reference-country-view {
  margin: 0px 8px 0px 8px;
}
.reference-country-header {
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.country-view-title {
  color: #101828;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.currecy-view-desc {
  overflow: hidden;
  color: #475467;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.reference-country-view .hr {
  border: 1px solid #f2f4f7;
}
.default-country-view {
  display: flex;
  min-width: 250px;
  max-width: 280px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: 14px;
  gap: 4px;
}
.country-text {
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.country-text-desc {
  color: var(--colors-text-text-tertiary-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.back-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: url("../../assets/cursorArrow.svg"), auto;
  padding: 8px 12px 8px 10px;
}
.back-icon:hover {
  background-color: #f4f4f4;
  border-radius: 100%;
}
</style>
