var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},_vm._l((_vm.items),function(item,index){return _c('div',{key:`${index}-${item}`,staticClass:"d-flex align-center"},[_c('Icon',{attrs:{"name":item.type == 'position' ? 'org_icon_position' :
                  item.type == 'role' ? 'org_icon_role' :
                  item.type == 'responsibility' ? 'org_icon_responsibility' :
                  item.type == 'knowledge' ? 'org_icon_knowledge' :
                  item.type == 'course' ? 'org_icon_course' :
                  item.type == 'workshop' ? 'org_icon_workshop' :
                  item.type == 'book' ? 'org_icon_book' :
                  item.type == 'lesson' ? 'org_icon_lesson' : 'org_icon_position'}}),_c('span',{staticClass:"text-row-icons mx-1"},[_vm._v(_vm._s(item.quantity))])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }