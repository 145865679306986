var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],ref:"myButton",class:_vm.classes,style:(_vm.style),attrs:{"type":_vm.type,"disabled":(_vm.fullwidth === 'disable' || _vm.normal === 'normal-disable' || _vm.outline === 'outline-disable' || _vm.loading) ? true : false},on:{"click":_vm.onClick}},[(_vm.icon)?_c('img',{class:{
    'mx-1' : _vm.iconHorizontalMargin,
    },attrs:{"src":require(`@components/assets/${_vm.icon}`),"alt":"icon"}}):_vm._e(),(_vm.plusIcon)?_c('img',{class:['plus-icon', {
      'plus-icon': _vm.size === 'large' || _vm.size === 'x-large',
      'plus-icon-invert': _vm.variantTypes === 'active' || _vm.variantTypes === 'disable',
      'plus-icon-small': _vm.size === 'small'
    }],attrs:{"src":require(`@components/assets/${_vm.plusIcon}`),"alt":"icon"}}):_vm._e(),(_vm.loading?.length > 0 && _vm.loading == 'left')?_c('span',[_c('v-icon',[_vm._v("$ex-white-spinner")])],1):_vm._e(),(_vm.label)?_c('span',{class:{
    'label-highlight': _vm.must,
    '': !_vm.must && _vm.icon
     },staticStyle:{"margin":"0px 12px"}},[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),(_vm.rightIcon)?_c('img',{staticClass:"right-arrow-icon",class:{ rightIcon: _vm.rightIcon },attrs:{"src":require(`@components/assets/${_vm.rightIcon}`),"alt":"icon"}}):_vm._e(),(_vm.loading?.length > 0 && _vm.loading == 'right')?_c('span',[_c('v-icon',[_vm._v("$ex-white-spinner")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }