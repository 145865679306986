import { defineStore } from "pinia";
import {
  getListOfRoles,
  createRole,
  updateRole,
  attachResponsibilityToRole,
  updateRetiredRole,
  updateArchiveRole,
  updateUnArchiveRole,
  deleteReadinessRole,
  updateDeActiveRole,
  updateReActiveRole,
  detachRoleAttachment,
  detachOrgObject,
} from "@/api";
import {
  getRoles as getLocalStorageRoles,
  updateRoles,
} from "@components/helper/localStorageHelper";
import { ref, computed } from "vue";

export const useRolesStore = defineStore("roles", () => {
  const roles = ref([]);
  const currentRole = ref(null);
  const totalRolesCount = ref(0);

  const getRoles = computed(() => roles.value);

  function setCurrentRole(data) {
    currentRole.value = data;
  }

  function resetCurrentRole() {
    currentRole.value = null;
  }

  function setRoles(data) {
    roles.value = data;
    totalRolesCount.value = data.length;
  }

  async function getRoleById(id) {
    const role = roles.value.find((item) => item.id == id);
    if (!role.code_str) {
      role.code_str = `#${role.code} - ${role.name}`;
    }
    return role;
  }

  async function loadRoles() {
    try {
      setRoles(getLocalStorageRoles());
      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const response = await getListOfRoles(accountId, 1, 500);
      if (response.data?.count) {
        totalRolesCount.value = response.data.count;
      }

      if (response.data?.results) {
        const sanitizedResults = response.data.results.map((item) => {
          if (!item.code_str) {
            item.code_str = `#${item.code} - ${item.name}`;
          }
          return {
            ...item,
          };
        });
        updateRoles(sanitizedResults);
        setRoles(sanitizedResults);
      }
    } catch (e) {
      console.error("error fetching roles: ", e);
    }
  }

  async function saveNewRole({ accountId, roleName }) {
    try {
      const payload = {
        name: roleName,
        account: accountId,
      };
      const response = await createRole(accountId, payload);
      if (response.data) {
        await loadRoles();
        return response.data;
      }
    } catch (e) {
      console.error("error creating role: ", e);
      throw e;
    }
  }

  async function activateRole({ role, accountId }) {
    try {
      const idRole = role.id;
      const nameRole = role.data.name;
      const payload = {
        name: nameRole,
        status: "active",
        account: accountId,
      };
      await updateRole(accountId, idRole, payload);
      await loadRoles(); // Refresh roles after update
    } catch (e) {
      console.error("error activating role: ", e);
      throw e;
    }
  }

  async function attachResponsibility({ accountId, objId, attachmentId }) {
    try {
      const payload = {
        attachment_id: attachmentId,
      };
      await attachResponsibilityToRole(accountId, objId, payload);
      await loadRoles();
    } catch (e) {
      console.error("error attaching responsibility: ", e);
      throw e;
    }
  }

  async function retiredRole({ accountId, role }) {
    try {
      const idRole = role.id;
      const nameRole = role.data.name;
      const payload = {
        name: nameRole,
        status: "retired",
        account: accountId,
      };
      await updateRetiredRole(accountId, idRole, payload);
      await loadRoles();
    } catch (e) {
      console.error("error retiring role: ", e);
      throw e;
    }
  }

  async function archiveRole({ accountId, role }) {
    try {
      const idRole = role.id;
      const objTpye = "role";
      await updateArchiveRole(accountId, objTpye, idRole);
      await loadRoles();
    } catch (e) {
      console.error("error archiving role: ", e);
      throw e;
    }
  }

  async function unarchiveRole({ accountId, role }) {
    try {
      const idRole = role.id;
      const objTpye = "role";
      await updateUnArchiveRole(accountId, objTpye, idRole);
      await loadRoles();
    } catch (e) {
      console.error("error unarchiving role: ", e);
      throw e;
    }
  }

  async function renameRole({ role, newName, accountId }) {
    try {
      const idRole = role?.id;
      if (!idRole) {
        throw new Error("no id");
      }
      const payload = {
        name: newName,
        account: accountId,
      };
      await updateRole(accountId, idRole, payload);
      await loadRoles();
    } catch (e) {
      console.error("error renaming role: ", e);
      throw e;
    }
  }

  async function deleteRole({ role, accountId }) {
    try {
      const idRole = role.id;
      await deleteReadinessRole(accountId, idRole);
      await loadRoles();
    } catch (e) {
      console.error("error deleting role: ", e);
      throw e;
    }
  }

  async function deActivateRole({ role, accountId }) {
    try {
      const idRole = role.id;
      const nameRole = role.data.name;
      const payload = {
        name: nameRole,
        status: "inactive",
        account: accountId,
      };
      await updateDeActiveRole(accountId, idRole, payload);
      await loadRoles();
    } catch (e) {
      console.error("error deactivating role: ", e);
      throw e;
    }
  }

  async function reActivateRole({ role, accountId }) {
    try {
      const idRole = role.id;
      const nameRole = role.data.name;
      const payload = {
        name: nameRole,
        status: "inactive",
        account: accountId,
      };
      await updateReActiveRole(accountId, idRole, payload);
      await loadRoles();
    } catch (e) {
      console.error("error reactivating role: ", e);
      throw e;
    }
  }

  async function detachRoleAction({ data, accountId }) {
    try {
      const payload = {
        parent_id: data.id,
      };
      await detachRoleAttachment(accountId, data.parentId, payload);
      await loadRoles();
    } catch (e) {
      console.error("error detaching role: ", e);
      throw e;
    }
  }

  async function detachItems({ data, callback, accountId }) {
    const targetObj = data.target;
    const detachObj = data.detachObj;
    try {
      if (targetObj.objectType.toLowerCase() == "role") {
        if (detachObj.type.toLowerCase() == "position") {
          const payload = {
            childType: targetObj.objectType.toLowerCase(),
            childId: targetObj.objectId,
            parentData: {
              parent_id: detachObj.id,
            },
          };
          await detachOrgObject(accountId, payload);
        } else if (detachObj.type.toLowerCase() == "responsibility") {
          const payload = {
            childType: "responsibility",
            childId: detachObj.id,
            parentData: {
              parent_id: targetObj.objectId,
            },
          };
          await detachOrgObject(accountId, payload);
        }
      } else if (targetObj.objectType.toLowerCase() == "responsibility") {
        const payload = {
          childType: targetObj.objectType.toLowerCase(),
          childId: targetObj.objectId,
          parentData: {
            parent_id: detachObj.id,
          },
        };
        await detachOrgObject(accountId, payload);
      }
      await loadRoles();
      if (data.closeFn) {
        data.closeFn();
      }
      if (callback) {
        callback();
      }
    } catch (e) {
      console.error("error detaching items: ", e);
      throw e;
    }
  }

  return {
    roles,
    currentRole,
    getRoles,
    setCurrentRole,
    resetCurrentRole,
    setRoles,
    getRoleById,
    loadRoles,
    saveNewRole,
    activateRole,
    attachResponsibility,
    retiredRole,
    archiveRole,
    unarchiveRole,
    renameRole,
    deleteRole,
    deActivateRole,
    reActivateRole,
    detachRoleAction,
    detachItems,
  };
});
