var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{class:{
      'small-code': _vm.size === 'small',
      'medium-code': _vm.size === 'medium'
    }},[_vm._v(" "+_vm._s(_vm.code)+" ")]),(!_vm.url && !_vm.clickPopup)?_c('span',{staticClass:"url-name",class:{
      'small-name': _vm.size === 'small',
      'medium-name': _vm.size === 'medium'
    }},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),(_vm.url && !_vm.clickPopup)?_c('router-link',{staticClass:"url-name",class:{
      'small-name': _vm.size === 'small',
      'medium-name': _vm.size === 'medium'
    },attrs:{"to":_vm.url}},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.url)+" "),(!_vm.url && _vm.clickPopup)?_c('a',{staticClass:"url-name",class:{
      'small-name': _vm.size === 'small',
      'medium-name': _vm.size === 'medium'
    },on:{"click":function($event){$event.preventDefault();return _vm.openPopup.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }