<template>
  <v-dialog v-model="showDialog" persistent max-width="400px">
    <div class="pop-up">
      <img
        :src="require('@components/assets/backgrounds/modal-background.svg')"
        class="second-bg"
        alt=""
      />
      <div class="modal_header">
        <div class="d-flex flex-row pa-5">
          <ExAvatar
            v-if="currentUser?.user?.picture || currentUser?.user?.first_name"
            size="large"
            :picture="currentUser?.user?.picture || null"
            :firstName="currentUser?.user?.first_name || ''"
            :lastName="currentUser?.user?.last_name || ''"
          ></ExAvatar>
          <v-spacer />
          <span class="align-self-center" @click="reset">
            <v-btn icon color="grey">
              <Icon class="cursor-pointer" name="popup_close"></Icon>
            </v-btn>
          </span>
        </div>
        <h3 class="text-black-600 main-text px-6 mb-3">
          Change Apps Permissions
        </h3>
        <span class="action_popup_message px-6">
          Change access to apps for {{ currentUser?.user?.first_name }}
          {{ currentUser?.user?.last_name }}
        </span>
      </div>
      <div class="modal_content mt-5 px-6">
        <AppSelectorCheckbox
          :availableItems="availableItems"
          v-model="permissions"
          @change="onChangeAppPermissions"
        />
        <div class="mb-6 mt-5">
          <ExButton
            size="normal"
            :fullwidth="true"
            label="Save"
            buttonWidth="full"
            @onClick="updatePermission"
            :loading="$loader.isAppLoading ? 'left' : null"
            :disabled="!permissions.includes(1)"
            :normal="
            !permissions.includes(1) ? 'normal-disable' : 'normal-hover'
            "
          ></ExButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { userCheckEmail } from "@/api";
import AtTheRateIcon from "@components/svgs/AtTheRateIcon.vue";
import ExTextInput from "@components/TextInput/ExTextInput.vue";
import ExButton from "@components/buttons/Button.vue";
import Icon from "@components/icons/Icon.vue";
import ExAvatar from "@components/common/ExAvatar.vue";
import AppSelectorCheckbox from "@components/inputs/select/AppSelectorCheckbox.vue";
// Props definition
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
  permissions: {
    type: Array,
    required: true,
  },
  availableItems: {
    type: Array,
    required: true,
  },
  currentUser: {
    type: Object,
    required: true,
  },
});
// Emits definition
const emit = defineEmits([
  "closeAddDialog",
  "onUpdatePermissions",
  "onUpdateAppPermissions",
]);
const updatePermission = () => {
  emit("onUpdatePermissions");
};
const reset = () => {
  emit("closeAddDialog", false);
};
const onChangeAppPermissions = (val) => {
  emit("onUpdateAppPermissions", val);
};
</script>
<style scoped>
.text-black-600 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}
.text-black-500 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}
.pop-up {
  width: 400px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
}
.second-bg {
  position: absolute;
  right: 0;
  top: 0;
  /* transform: translateY(-50%); */
  z-index: 1;
  pointer-events: none;
  width: auto;
  height: auto;
}
.background-popup {
  position: absolute;
  z-index: 0;
}
.main-text {
  margin-top: -5px;
}
.modal_header {
  z-index: 1;
  position: relative;
}
.modal_content {
  z-index: 1;
  position: relative;
}
.font-weight-400 {
  font-weight: 400;
}
.font-size-14 {
  font-size: 14px;
}
</style>
