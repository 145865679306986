import { defineStore } from "pinia";
import {
  getListOfRoles,
  getListofResponsibility,
  createRole,
  createResponsibility,
} from "@/api";
import {
  getRoles,
  updateRoles,
  getResponsibilities,
  updateResponsibilities,
} from "@components/helper/localStorageHelper";

export const useReadinessCatalogsStore = defineStore("readinessCatalogs", {
  state: () => ({
    roles: [],
    responsibilities: [],
  }),

  getters: {
    getAllRoles: (state) => state.roles,
    getAllResponsibilities: (state) => state.responsibilities,

    getRoleById: (state) => {
      return (roleId) => state.roles.find((role) => role.id == roleId);
    },

    getAllRolesAndAttachedResponsibilities: (state) => {
      const rolesWithParentId = state.roles.map((role) => ({
        ...role,
        parentId: -1,
      }));

      const responsibilitiesWithParentId = state.responsibilities.map(
        (responsibility) => {
          const attachedRole = responsibility.attached_roles.find(
            (attachment) =>
              state.roles.some((role) => role.id === attachment.id)
          );
          return {
            ...responsibility,
            parentId: attachedRole ? attachedRole.id : null,
          };
        }
      );

      return [...rolesWithParentId, ...responsibilitiesWithParentId];
    },

    getResponsibilitiesFromRoleId: (state) => {
      return (roleId) =>
        state.responsibilities
          .filter((responsibility) =>
            responsibility.attached_roles.some(
              (attachment) => attachment.id === roleId
            )
          )
          .map((responsibility) => ({
            ...responsibility,
            parentId: roleId,
          }));
    },
  },

  actions: {
    setRoles(roles) {
      this.roles = roles;
    },

    setResponsibilities(responsibilities) {
      this.responsibilities = responsibilities;
    },

    async loadRoles() {
      try {
        let rolesList = getRoles();
        this.setRoles(rolesList);

        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        const response = await getListOfRoles(accountId, 1, 500);

        if (response.data?.results) {
          updateRoles(response.data.results);
          this.setRoles(response.data.results);
        }
      } catch (e) {
        console.error("error fetching roles: ", e);
      }
    },

    async loadResponsibilities() {
      try {
        let responsibilitiesList = getResponsibilities();
        this.setResponsibilities(responsibilitiesList);

        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        const response = await getListofResponsibility(accountId, 1, 500);

        if (response.data?.results) {
          updateResponsibilities(response.data.results);
          this.setResponsibilities(response.data.results);
        }
      } catch (e) {
        console.error("error fetching responsibilities: ", e);
      }
    },

    async createNewRole(role, accountId) {
      try {
        const { data } = await createRole(accountId, role);
        if (data) {
          this.roles.push(data);
          return data;
        }
      } catch (e) {
        console.error("error creating role: ", e);
        throw e;
      }
    },

    async createNewResponsibility(name, accountId) {
      try {
        const payload = {
          name,
          account: accountId,
        };
        const response = await createResponsibility(accountId, payload);
        if (response.data) {
          this.responsibilities.push(response.data);
        }
        return response;
      } catch (e) {
        console.error("error creating responsibility: ", e);
        throw e;
      }
    },
  },
});
