import { render, staticRenderFns } from "./DeleteResponsibility.vue?vue&type=template&id=1b75bfe1&scoped=true"
import script from "./DeleteResponsibility.vue?vue&type=script&lang=js"
export * from "./DeleteResponsibility.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b75bfe1",
  null
  
)

export default component.exports