import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import RadioOff from "@components/inputs/radio/RadioOff.vue";
import RadioOn from "@components/inputs/radio/RadioOn.vue";
import CheckboxChecked from "@components/assets/icons/iconComponents/CheckboxChecked.vue";
import CheckboxUnChecked from "@components/assets/icons/iconComponents/CheckboxUnChecked.vue";
import ChevronDown from "@components/assets/icons/iconComponents/ChevronDown.vue";
import ChevronDownRounded from "@components/assets/icons/iconComponents/ChevronDownRounded.vue";
import Pass from "@components/assets/icons/iconComponents/Pass.vue";
import NotificationChevronDown from "@components/assets/icons/iconComponents/NotificationChevronDown.vue";
import exError from "@components/assets/icons/iconComponents/Error.vue";
import exEllipsis from "@components/assets/icons/iconComponents/ExEllipsis.vue";
import RadioChecked from "@components/inputs/radio/RadioChecked.vue";
import RadioCurrentCheck from "@components/inputs/radio/RadioCurrentCheck.vue";
import RadioCheckOff from "@components/inputs/radio/RadioCheckOff.vue";
import RadioMiniNormal from "@components/inputs/radio/RadioMiniNormal.vue";
import RadioMiniChecked from "@components/inputs/radio/RadioMiniChecked.vue";
import ExSpinner from "@components/common/ExSpinner.vue";
import ExWhiteSpinner from "@components/common/ExWhiteSpinner.vue";
import ExRedSpinner from "@components/common/ExRedSpinner.vue";
import ArchiveIcon from "@components/icons/items/Generic/ArchiveIcon.vue";
import BulkDeleteIcon from "@components/icons/items/Generic/BulkDeleteIcon.vue";
import ViewModeNormal from "@components/icons/items/Generic/ViewMode/ViewModeNormal.vue";
import ViewModeMultiselect from "@components/icons/items/Generic/ViewMode/ViewModeMultiselect.vue";
import ViewModeNormalDark from "@components/icons/items/Generic/ViewMode/ViewModeNormalDark.vue";
import ViewModeMultiselectDark from "@components/icons/items/Generic/ViewMode/ViewModeMultiselectDark.vue";
import ExPrimarySpinner from "@components/common/ExPrimarySpinner.vue";
import ExChevronDownSM from "@components/assets/icons/iconComponents/ChevronDownSM.vue";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#7F56D9",
        "primary-darken-1": "#3700B3",
        secondary: "#03DAC6",
        "secondary-darken-1": "#018786",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
  icons: {
    values: {
      "radio-off": {
        component: RadioOff,
      },
      "radio-on": {
        component: RadioOn,
      },
      checkboxOn: {
        component: CheckboxChecked,
      },
      checkboxOff: {
        component: CheckboxUnChecked,
      },
      "ex-chevron-down": {
        component: ChevronDown,
      },
      "ex-pass": {
        component: Pass,
      },
      "ex-error": {
        component: exError,
      },
      "ex-ellipsis": {
        component: exEllipsis,
      },
      "ex-spinner": {
        component: ExSpinner,
      },
      "ex-notification-chevron-down": {
        component: NotificationChevronDown,
      },
      exRadioChecked: {
        component: RadioChecked,
      },
      exRadioCurrentCheck: {
        component: RadioCurrentCheck,
      },
      exRadioCheckOff: {
        component: RadioCheckOff,
      },
      exRadioMiniNormal: {
        component: RadioMiniNormal,
      },
      exRadioMiniChecked: {
        component: RadioMiniChecked,
      },
      "ex-white-spinner": {
        component: ExWhiteSpinner,
      },
      "ex-red-spinner": {
        component: ExRedSpinner,
      },
      "ex-archive": {
        component: ArchiveIcon,
      },
      "ex-bulk-delete": {
        component: BulkDeleteIcon,
      },
      "ex-viewmode-normal": {
        component: ViewModeNormal,
      },
      "ex-viewmode-multiselect": {
        component: ViewModeMultiselect,
      },
      "ex-viewmode-normal-dark": {
        component: ViewModeNormalDark,
      },
      "ex-viewmode-multiselect-dark": {
        component: ViewModeMultiselectDark,
      },
      "ex-primary-spinner": {
        component: ExPrimarySpinner,
      },
      "ex-chevron-down-rounded": {
        component: ChevronDownRounded,
      },
      "ex-chevron-down-sm": {
        component: ExChevronDownSM,
      },
    },
  },
});
