import {
  updateFilters,
  getFilters,
} from "@components/helper/localStorageHelper";

export default {
  namespaced: true,
  state: {
    filters: [],
  },
  mutations: {
    setFilters(state, { key, filters }) {
      state.filters = filters;
      updateFilters(key, filters);
    },
  },
  getters: {},
  actions: {
    updateFilters({ commit }, { key, filters }) {
      commit("setFilters", { key, filters });
    },
    loadFilters({ commit }, key) {
      const savedFilters = getFilters(key);
      commit("setFilters", { key, filters: savedFilters });
    },
  },
};
