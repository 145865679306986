import { defineStore } from "pinia";
import { getListofEmployee } from "@/api";
import {
  getEmployees as getLocalStorageEmployees,
  updateEmployees,
} from "@components/helper/localStorageHelper";
import { ref, computed } from "vue";

export const useEmployeeStore = defineStore("employee", () => {
  const employees = ref([]);
  const currentEmployee = ref(null);
  const activeEmployeesCount = ref(0);
  const totalEmployeesCount = ref(0);

  const getEmployees = computed(() => employees.value);
  const getActiveEmployeesCount = computed(() => activeEmployeesCount.value);
  const getTotalEmployeesCount = computed(() => totalEmployeesCount.value);

  function setCurrentEmployee(data) {
    currentEmployee.value = data;
  }

  function resetCurrentEmployee() {
    currentEmployee.value = null;
  }

  function setEmployees(data) {
    employees.value = data;
    activeEmployeesCount.value = data.filter(
      (el) => el.current_status == "active"
    ).length;
  }

  function setActiveEmployeesCount(data) {
    activeEmployeesCount.value = data;
  }

  function setTotalPositionsCount(count) {
    totalEmployeesCount.value = count;
  }

  async function loadEmployees() {
    try {
      setEmployees(getLocalStorageEmployees());

      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const response = await getListofEmployee(accountId, 1, 500);

      if (response.data?.count) {
        totalEmployeesCount.value = response.data.count;
      }

      if (response.data?.results) {
        updateEmployees(response.data.results);
        setEmployees(response.data.results);
      }
    } catch (e) {
      console.error("error fetching employees: ", e);
    }
  }

  async function loadEmployeesFromServer() {
    try {
      const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
      const { data } = await getListofEmployee(accountId, 1, 500);
      return data?.results || [];
    } catch (e) {
      console.error("error fetching employees from server: ", e);
      return [];
    }
  }

  return {
    employees,
    currentEmployee,
    activeEmployeesCount,
    totalEmployeesCount,
    getEmployees,
    getActiveEmployeesCount,
    getTotalEmployeesCount,
    setCurrentEmployee,
    resetCurrentEmployee,
    setEmployees,
    setActiveEmployeesCount,
    setTotalPositionsCount,
    loadEmployees,
    loadEmployeesFromServer,
  };
});
