import { useRolesStore } from "@/store/pinia/roles";
import { useResponsibilitiesStore } from "@/store/pinia/responsibilities";
import { computed } from "vue";

export function useRoles() {
  const rolesStore = useRolesStore();
  const responsibilitiesStore = useResponsibilitiesStore();

  const allRoles = computed(() => rolesStore.roles);

  const setCurrentRole = (role) => {
    rolesStore.setCurrentRole(role);
  };

  const getCurrentRole = () => {
    return rolesStore.currentRole;
  };

  const loadAllRoles = async () => {
    await rolesStore.loadRoles();
  };

  const getAllRolesAndAttachedResponsibilities = () => {
    const rolesWithParentId = rolesStore.roles.map((role) => {
      if (!role.code_str) {
        role.code_str = `#${role.code} - ${role.name}`;
      }
      return {
        ...role,
        parentId: -1,
      };
    });

    const responsibilitiesWithParentId =
      responsibilitiesStore.responsibilities.map((responsibility) => {
        const attachedRole = responsibility.attached_roles.find((attachment) =>
          rolesStore.roles.some((role) => role.id === attachment.id)
        );
        if (!responsibility.code_str) {
          responsibility.code_str = `#${responsibility.code} - ${responsibility.name}`;
        }
        return {
          ...responsibility,
          parentId: attachedRole ? attachedRole.id : null,
        };
      });

    return [...rolesWithParentId, ...responsibilitiesWithParentId];
  };

  const getAttachedRolesFromPositionId = (positionId) => {
    if (!positionId) return [];
    return allRoles.value
      .filter((role) => {
        return role.attachments.some(
          (attachment) =>
            attachment.type === "position" && attachment.id === positionId
        );
      })
      .map((role) => {
        if (!role.code_str) {
          role.code_str = `#${role.code} - ${role.name}`;
        }
        return {
          ...role,
          parentId: -1,
        };
      });
  };

  const getResponsibilitiesFromRoleId = (roleId) => {
    return responsibilitiesStore.responsibilities
      .filter((responsibility) => {
        return responsibility.attached_roles.some(
          (attachment) => attachment.id === roleId
        );
      })
      .map((responsibility) => {
        if (!responsibility.code_str) {
          responsibility.code_str = `#${responsibility.code} - ${responsibility.name}`;
        }
        return {
          ...responsibility,
          parentId: roleId,
        };
      });
  };

  const saveNewRole = async (params) => {
    return await rolesStore.saveNewRole(params);
  };

  const activateRole = async (params) => {
    await rolesStore.activateRole(params);
  };

  const attachResponsibility = async (params) => {
    await rolesStore.attachResponsibility(params);
  };

  const retiredRole = async (params) => {
    await rolesStore.retiredRole(params);
  };

  const archiveRole = async (params) => {
    await rolesStore.archiveRole(params);
  };

  const unarchiveRole = async (params) => {
    await rolesStore.unarchiveRole(params);
  };

  const renameRole = async (params) => {
    await rolesStore.renameRole(params);
  };

  const deleteRole = async (params) => {
    await rolesStore.deleteRole(params);
  };

  const deActivateRole = async (params) => {
    await rolesStore.deActivateRole(params);
  };

  const reActivateRole = async (params) => {
    await rolesStore.reActivateRole(params);
  };

  const detachRoleAction = async (params) => {
    await rolesStore.detachRoleAction(params);
  };

  const detachItems = async (params) => {
    await rolesStore.detachItems(params);
  };

  return {
    allRoles,
    setCurrentRole,
    getCurrentRole,
    loadAllRoles,
    getAllRolesAndAttachedResponsibilities,
    getResponsibilitiesFromRoleId,
    getAttachedRolesFromPositionId,
    saveNewRole,
    activateRole,
    attachResponsibility,
    retiredRole,
    archiveRole,
    unarchiveRole,
    renameRole,
    deleteRole,
    deActivateRole,
    reActivateRole,
    detachRoleAction,
    detachItems,
  };
}
