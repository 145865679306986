var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.evalRequest === 'Not pending')?_c('div',[_c('ProficiencyBadge',{attrs:{"level":_vm.currentBadge.level,"dot":_vm.currentBadge.dot,"view":_vm.currentBadge.view,"expiration_date":_vm.currentBadge.expiration_date}})],1):_vm._e(),(_vm.evalRequest === 'Pending')?_c('div',[_c('div',{staticClass:"eval-pending",class:{
        'eval-pending-novice':  _vm.currentBadge.level === 'Novice'
      }},[_c('div',{staticClass:"eval-level"},[_c('ProficiencyBadge',{attrs:{"level":_vm.currentBadge.level,"dot":_vm.currentBadge.dot,"view":_vm.currentBadge.view,"mini":_vm.mini,"plain":_vm.plain}})],1),_c('div',{staticClass:"eval-text",class:{
        'eval-text-novice': _vm.currentBadge.level === 'Novice'
      }},[_vm._v(" Pending Eval Request ")]),_c('div',{staticClass:"eval-cross"},[_c('img',{staticClass:"eval-cross-icon",attrs:{"src":require(`@components/assets/eval-cross-icon.svg`),"alt":"icon"}})])])]):_vm._e(),(_vm.evalRequest === 'Accepted')?_c('div',[_c('div',{staticClass:"eval-pending"},[_c('div',{staticClass:"eval-level"},[_c('ProficiencyBadge',{attrs:{"level":_vm.currentBadge.level,"dot":_vm.currentBadge.dot,"view":_vm.currentBadge.view,"mini":_vm.mini,"plain":_vm.plain}})],1),_c('div',{staticClass:"eval-user-text"},[_c('ExAvatar',{staticClass:"custom-avatar",class:{
            'novice-avatar': _vm.currentBadge.level === 'Novice'
          },attrs:{"size":"small","picture":(_vm.user.avatar) ? _vm.user.avatar : '',"firstName":(!_vm.user.avatar) ? _vm.user.firstName : '',"lastName":(!_vm.user.avatar) ? _vm.user.lastName : ''}}),_c('span',{staticClass:"holder-status-label",class:{
            'novice-label': _vm.currentBadge.level === 'Novice'
          }},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")])],1),_c('div',{staticClass:"eval-cross"},[_c('img',{staticClass:"eval-cross-icon",attrs:{"src":require(`@components/assets/eval-cross-icon.svg`),"alt":"icon"},on:{"click":function($event){return _vm.removeEvalBadge(_vm.currentBadge.level)}}})])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }