<template>
  <v-dialog
    v-model="showDialog"
    width="400"
    data-testid="ex-actions-popup"
    @click:outside="$emit('onClose', false)"
    @keydown.esc="$emit('onClose', false)"
  >
    <v-card class="ex_action_popup background-pattern-icon">
      <div class="d-flex items-center px-6 pt-6">
        <Icon v-if="icon" :name="icon"></Icon>
        <v-spacer />
        <span
          class="align-self-center close_icon"
          @click="$emit('onClose', false)"
        >
          <v-btn icon color="grey">
            <Icon class="cursor-pointer" name="popup_close"></Icon>
          </v-btn>
        </span>
      </div>
      <div class="text-container px-5">
        <h3 class="action_popup_title">{{ title }}</h3>
        <p class="action_popup_message">{{ description }}</p>
      </div>
      <div class="d-flex flex-column reason-container px-6">
        <label for="reason">Reason*</label>
        <textarea
          :value="value"
          id="reason"
          :placeholder="placeHolder"
          @input="$emit('input', $event.target.value)"
        ></textarea>
        <p v-if="true" class="text_error" style="color: red;">{{ reasonError }}</p>
      </div>
      <div class="px-6 pb-6">
        <Button
          :label="btnLabel"
          class="add-reason-btn"
          size="normal"
          @onClick="$emit('onAction')"
          :normal="isBtnDisabled ? 'normal-disable' : 'normal-hover'"
          :disabled="isBtnDisabled"
          :loading="$loader.isAppLoading ? 'left' : null"
          :style="
            !isBtnDisabled
              ? `background: ${btnColor}; width: 100%`
              : 'width: 100%'
          "
          :destructive="btnDanger ? true : false"
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { defineProps, computed, ref, watch } from "vue";
import Icon from "@components/icons/Icon.vue";
import Button from "@components/buttons/Button.vue";

const props = defineProps({
  title: {
    type: String,
    default: "Reason Modal",
  },
  description: {
    type: String,
    default: "Please provide a reason why this criteria was not met.",
  },
  showDialog: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: String,
    default: "reason_modal",
  },
  btnLabel: {
    type: String,
    default: "Add Reason",
  },
  disabled: {
    type: Boolean,
  },
  btnColor: {
    type: String,
  },
  minLength: {
    type: Number,
    default: 5,
  },
  maxLength: {
    type: Number,
    default: 500,
  },
  placeHolder: {
    type: String,
    default: "Some reason",
  },
  btnDanger: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    default: "",
  },
});

const reasonError = ref("");
const isBtnDisabled = computed(() => {
  return props.disabled || props.value.length < 5 || props.value.length > 500;
});
watch(
  () => props.value,
  (newValue) => {
    if (newValue.length < props.minLength) {
      reasonError.value = `Reason must be at least ${props.minLength} characters.`;
    } else if (newValue.length > props.maxLength) {
      reasonError.value = `Reason cannot exceed ${props.maxLength} characters.`;
    } else {
      reasonError.value = "";
    }
  }
);

watch(
  () => props.showDialog,
  (isShown) => {
    if (isShown) {
      reasonError.value = "";
    }
  }
);
</script>
<style scoped>
.ex_action_popup .icon-border {
  width: 48px;
  height: 48px !important;
  padding: 12px !important;
  border-radius: 10px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.ex_action_popup {
  border-radius: 12px !important;
}

.background-pattern-icon {
  background-image: url("@components/assets/backgrounds/background_popup_icon.svg");
  background-size: 216px 216px;
  background-position: 0px 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-container {
  margin-top: 16px;
}

.action_popup_title {
  color: var(--Big-Headings, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.action_popup_message {
  margin-top: 24px;
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.reason-container {
  padding-bottom: 9px;
}

.reason-container textarea {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 14px;
  color: #101828;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 130px;
}

.reason-container label {
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 6px;
}

.add-reason-btn {
  margin-top: 32px;
}

.text_error {
  font-size: 14px !important;
  margin-bottom: 0px;
  padding-top: 5px;
}

#reason::placeholder {
  color: #98a2b3;
  font-weight: 400;
}

#reason::-ms-input-placeholder {
  color: #98a2b3;
  font-weight: 400;
}
</style>
