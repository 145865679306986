import { render, staticRenderFns } from "./BulkActionsDropdown.vue?vue&type=template&id=6d1dd8c4&scoped=true"
import script from "./BulkActionsDropdown.vue?vue&type=script&setup=true&lang=js"
export * from "./BulkActionsDropdown.vue?vue&type=script&setup=true&lang=js"
import style0 from "./BulkActionsDropdown.vue?vue&type=style&index=0&id=6d1dd8c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1dd8c4",
  null
  
)

export default component.exports