const LOGIN = "api/common/login/";
const USER = "api/common/user/";
const ACCOUNTList = "api/common/account/";
const RESPONSIBILITY = "api/proficiency/account/";

export const API = {
  LOGIN,
  USER,
  ACCOUNTList,
  RESPONSIBILITY,
};
