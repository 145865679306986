var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"432px"}},_vm._l((_vm.availableItems),function(item){return _c('div',{key:`${_vm.keyPrefix}${item.id}`,staticStyle:{"height":"70px"}},[_c('div',{staticClass:"d-flex justify-space-between align-center px-4 py-1 rounded-xl mb-2",class:{
        selector: _vm.value.includes(item.id),
        'default-app': !_vm.value.includes(item.id) && !item.disabled_message,
        'disabled-app': item.disabled_message,
      },style:({ cursor: item.disabled_message ? 'not-allowed' : 'pointer' }),on:{"click":() => !item.disabled_message && _vm.toggleAppSelect(item.id)}},[_c('span',{staticClass:"d-flex align-center disable-text"},[_c('Icon',{attrs:{"name":item.app.icon}}),_c('span',{staticClass:"check-label ml-4"},[_vm._v(_vm._s(item.app.name))])],1),(item.disabled_message)?_c('div',{staticClass:"app-coming"},[_vm._v(" "+_vm._s(item.disabled_message)+" ")]):_vm._e(),(!item.disabled_message)?_c('v-checkbox',{class:{
          'selected-checkbox': _vm.value.includes(item.id),
          'app-checkbox': !_vm.value.includes(item.id),
        },attrs:{"value":item.id,"input-value":_vm.value,"hide-details":true,"ripple":false,"color":"primary"},on:{"click":function($event){$event.stopPropagation();},"change":() => _vm.toggleAppSelect(item.id)}}):_vm._e()],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }