<template>
  <div class="d-flex ga-2 pay-range-header" :class="hoverEnabled ? 'hover-enabled' : ''">
    <img :src="countryFlag" alt="" class="pay-range-flag" />
    <span class="pay-range-name"
      >{{ payRangeName }} > {{ payRangeLocation }}</span
    >
    <span class="pay-range-expressed"
      >({{ payRangeCurrencyCode }}/{{ payRangeExpressed }})</span
    >
  </div>
</template>
<script setup>
import { ref, defineProps, computed } from "vue";
const props = defineProps({
  countryFlag: {
    type: String,
    required: true,
  },
  payRangeName: {
    type: String,
    required: true,
  },
  payRangeLocation: {
    type: String,
    required: true,
  },
  payRangeExpressedAs: {
    type: String,
    required: true,
  },
  payRangeCurrencyCode: {
    type: String,
    required: true,
  },
  hoverEnabled: {
    type: Boolean,
    required: false,
    default: true
  },
});

const payRangeExpressed = computed(() => {
  return props.payRangeExpressedAs?.replace("per_", "").toUpperCase() || "Hour";
});
</script>
<style scoped>
.pay-range-header {
  display: flex;
  gap: 9px;
  align-items: center;
}
.hover-enabled:hover {
  border-radius: 10px;
  background: #f2edff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  cursor: url("../assets/cursorArrow.svg"), auto;
}
.hover-enabled {
  padding: 12px 10px !important;
}
.pay-range-header .pay-range-flag {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  object-fit: cover;
}
.pay-range-header .pay-range-name {
  color: #101828;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  align-items: center;
}
.pay-range-header .pay-range-expressed {
  color: #101828;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-left: 2px;
}
</style>
