<template>
  <div class="range-container">
    <div class="timeline">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="mb-2 mt-2"
      >
        <path
          d="M21 3H3M12 21V7M12 7L5 14M12 7L19 14"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="timeline-point">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          class="top-point"
        >
          <path
            d="M1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
            fill="#00A3FF"
          />
          <path
            d="M1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
            stroke="#00F0FF"
            stroke-width="2"
          />
          <circle cx="16" cy="16" r="5" fill="white" />
        </svg>
      </div>
      <div class="timeline-line background-top"></div>
      <div class="timeline-point">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          class="mid-point"
        >
          <path
            d="M1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
            fill="#00FF19"
          />
          <path
            d="M1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
            stroke="#A6F8B8"
            stroke-width="2"
          />
          <circle cx="16" cy="16" r="5" fill="white" />
        </svg>
      </div>
      <div class="timeline-line background-mid"></div>
      <div class="timeline-point">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          class="bottom-point"
        >
          <path
            d="M1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
            fill="#FF8181"
          />
          <path
            d="M1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
            stroke="#FF0303"
            stroke-width="2"
          />
          <circle cx="16" cy="16" r="5" fill="white" />
        </svg>
      </div>
      <div class="timeline-line background-bottom"></div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="mt-2"
      >
        <path
          d="M3 21H21M12 3V17M12 17L19 10M12 17L5 10"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div class="inputs-section">
      <div class="input-row">
        <p class="label mb-0">Top of the Range (Max)</p>
        <div class="input-wrapper">
          <ExTextInput
            :class="{ 'changed-field': changedFields.topRangeValue && isEditable }"
            :value="String(topRangeValue)"
            @input="handleTopRangeChange($event)"
            @keypress="validateNumberInput($event)"
            placeholder=""
            class="w-100"
            type="text"
          />
          <span class="unit">USD/Hour</span>
        </div>
        <span v-if="topRangeError" class="error-text">{{ topRangeError }}</span>
        <span class="readiness ml-14">150% Readiness</span>
      </div>

      <div class="input-row">
        <p class="label mb-0">{{ rangePenetration66.toFixed(2) }} USD/Hour</p>
        <span class="mb-0 readiness"
          >100% Readiness (66% Range Penetration)</span
        >
      </div>

      <div class="input-row">
        <p class="label mb-0">{{ rangePenetration33.toFixed(2) }} USD/Hour</p>
        <span class="mb-0 readiness"
          >50% Readiness (33% Range Penetration)</span
        >
      </div>

      <div class="input-row">
        <p class="label mb-0">Bottom of the Range (Min)</p>
        <div class="input-wrapper">
          <ExTextInput
            :class="{ 'changed-field': changedFields.bottomRangeValue && isEditable }"
            :value="String(bottomRangeValue)"
            @input="handleBottomRangeChange($event)"
            @keypress="validateNumberInput($event)"
            placeholder=""
            class="w-100"
            type="text"
          />
          <span class="unit">USD/Hour</span>
        </div>
        <span class="readiness ml-14">0% Readiness</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits } from "vue";
import ExTextInput from "@components/TextInput/ExTextInput.vue";

const emit = defineEmits(["topRangeUpdated", "bottomRangeUpdated"]);

const props = defineProps({
  topRange: {
    type: Number,
    default: 0,
  },
  bottomRange: {
    type: Number,
    default: 0,
  },
  isEditable: {
    type: Boolean,
    default: false
  }
});

const changedFields = ref({
  bottomRangeValue: false,
  topRangeValue: false,
});

const { topRange, bottomRange } = props;

const topRangeValue = ref(topRange);
const bottomRangeValue = ref(bottomRange);
const topRangeError = ref("");

const rangePenetration33 = computed(() => {
  const rangeDifference = topRangeValue.value - bottomRangeValue.value;
  return rangeDifference > 0
    ? (1 / 3) * rangeDifference + bottomRangeValue.value
    : 0;
});

const rangePenetration66 = computed(() => {
  const rangeDifference = topRangeValue.value - bottomRangeValue.value;
  return rangeDifference > 0
    ? (2 / 3) * rangeDifference + bottomRangeValue.value
    : 0;
});

const validateRanges = () => {
  topRangeError.value = "";
  if (bottomRangeValue.value !== 0) {
    if (bottomRangeValue.value >= topRangeValue.value) {
      topRangeError.value = "Top of Range must be greater than Bottom of Range";
    }
  }
};

const validateNumberInput = (event) => {
  const char = String.fromCharCode(event.which);
  if (!/[0-9]/.test(char)) {
    event.preventDefault();
  }
};

watch(
  () => props.topRange,
  (newVal) => {
    topRangeValue.value = newVal;
    validateRanges();
  }
);

watch(
  () => props.bottomRange,
  (newVal) => {
    bottomRangeValue.value = newVal;
    validateRanges();
  }
);

const handleTopRangeChange = (value) => {
  topRangeValue.value = parseFloat(value) || 0;
  changedFields.value.topRangeValue = true;
  validateRanges();
  emit('topRangeUpdated', topRangeValue.value);
};

const handleBottomRangeChange = (value) => {
  bottomRangeValue.value = parseFloat(value) || 0;
  changedFields.value.bottomRangeValue = true;
  validateRanges();
  emit('bottomRangeUpdated', bottomRangeValue.value);
};

onMounted(() => {
  validateRanges();
  console.log("Initial validation:", topRangeError.value);
});
</script>

<style scoped>
.range-container {
  display: flex;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.timeline-point {
  border-radius: 50%;
}

.top-point {
  border-radius: 9999px;
  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
}

.mid-point {
  border-radius: 9999px;
  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
}

.bottom-point {
  border-radius: 9999px;
  background: #f9f5ff;
  box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24);
}

.timeline-line {
  width: 2px;
  border-radius: 2px;
}

.background-top {
  background: #00f0ff;
  height: 45px;
}

.background-mid {
  background: #a6f8b8;
  height: 46px;
}

.background-bottom {
  background: #ff8181;
  height: 62px;
}

.inputs-section {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.input-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.unit {
  color: #344054;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  right: 14px !important;
}

.label {
  color: #344054;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
  margin-bottom: 0px !important;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.readiness {
  color: #475467;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.w-100 {
  width: 100% !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

::v-deep .changed-field fieldset {
  border: 1px solid #7f56d9 !important;
  background: #f2edff !important;
}
</style>
