<template>
  <v-container fluid>
    <UniversalTable
      :config="tableConfig.tableParams.value"
      :items="filteredPayRanges"
      @onActionClick="viewState.state.addPayRangeModal.show = true"
      @onSearch="viewState.state.filters.searchText = $event"
      @onClick="viewState.state.filters.showFilterDialog = true"
      @removeFilter="viewState.removeFilters"
      :emptyObjectData="emptyObjectData"
    >
      <template #Dropdown-activator>
        <div class="icon-container">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-svg"
          >
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </template>
    </UniversalTable>
    <PayRangeModal
      @closeAddDialog="viewState.state.addPayRangeModal.show = false"
      :showDialog="viewState.state.addPayRangeModal.show"
    />
    <SearchModal
      :dialog="viewState.state.filters.showFilterDialog"
      @back="viewState.state.filters.showFilterDialog = false"
      @checkedItemsChanged="viewState.applyFilters"
      :clearSelection="viewState.state.filters.clearSelection"
      filterType="Employee"
      :filterGroups="viewState.state.filters.filterGroups"
      :statusOptions="viewState.state.filters.statusOptions"
      :modalWidth="800"
      :defaultSelected="viewState.state.filters.values"
    />
  </v-container>
</template>

<script setup>
import { onMounted, getCurrentInstance, computed } from "vue";
import UniversalTable from "@components/UniversalTable/UniversalTable.vue";
import { useTableConfig } from "@/views/PayRanges/composables/useTableConfig";
import { usePayRangeViewState } from "@/views/PayRanges/composables/usePayRangeViewState";
import PayRangeModal from "@components/PayRangeModal/PayRangeModal.vue";
import SearchModal from "@components/SearchBar/SearchModal.vue";
const app = getCurrentInstance();
const { proxy } = app;

const tableConfig = useTableConfig();
const viewState = usePayRangeViewState();
const emptyObjectData = {
  singular: "Pay Range",
  plural: "Pay Ranges",
};

const payRanges = [
  {
    pay_range_header: {
      country_flag: "https://flagcdn.com/us.svg",
      location: "New York",
      name: "A-12-HR Mid",
      pay_range_expressed_as: "per_hour",
      pay_range_currency_code: "GBP",
    },
    pay_range_line: {
      expressed: "per_hour",
      zeroPenetration: 100,
      oneThirdPenetration: 1000,
      twoThirdPenetration: 1010,
      fullPenetration: 1400,
    },
    used: 15,
    status: "draft",
  },
  {
    pay_range_header: {
      country_flag: "https://flagcdn.com/fr.svg",
      location: "Paris",
      name: "A-12-HR Expert",
      pay_range_expressed_as: "per_year",
      pay_range_currency_code: "GBP",
    },
    pay_range_line: {
      expressed: "per_year",
      zeroPenetration: 100,
      oneThirdPenetration: 1000,
      twoThirdPenetration: 1010,
      fullPenetration: 1400,
    },
    used: 10,
    status: "active",
  },
];

const filteredPayRanges = computed(() => {
  let payRangeList = payRanges;

  if (
    viewState.state.filters.searchText &&
    viewState.state.filters.searchText.length > 0
  ) {
    const searchText = viewState.state.filters.searchText.toLowerCase();

    payRangeList = payRangeList.filter((el) => {
      const fullName = `${el.first_name} ${el.last_name}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        el.first_name.toLowerCase().includes(searchText) ||
        el.last_name.toLowerCase().includes(searchText) ||
        el.title.toLowerCase().includes(searchText)
      );
    });
  }

  if (
    viewState.state.filters.values.some((val) =>
      viewState.state.filters.statusOptions.includes(val)
    )
  ) {
    payRangeList = payRangeList.filter((el2) =>
      viewState.state.filters.values.includes(el2.current_status)
    );
  }
  const availabilityFilters = ["Availability 0.5 FTE", "Availability 1 FTE"];
  if (
    viewState.state.filters.values.some((val) =>
      availabilityFilters.includes(val)
    )
  ) {
    payRangeList = payRangeList.filter((el2) => {
      return viewState.state.filters.values.some((el3) => {
        let val = false;
        if (el3 === "Availability 0.5 FTE") {
          val = el2.availability === 0.5;
        } else if (el3 === "Availability 1 FTE") {
          val = el2.availability === 1;
        }
        return val;
      });
    });
  }
  const archivedFilters = ["Show Archived", "No Archived"];
  if (
    viewState.state.filters.values.some((val) => archivedFilters.includes(val))
  ) {
    payRangeList = payRangeList.filter((el2) => {
      return viewState.state.filters.values.some((el3) => {
        let val = false;
        if (el3 === "No Archived") {
          val = el2.archived === false;
        } else if (el3 === "Show Archived") {
          val = el2.archived === true;
        }
        return val;
      });
    });
  }

  payRangeList = payRangeList.sort((a, b) => {
    const firstNameA = a?.first_name?.toLowerCase() || "";
    const firstNameB = b?.first_name?.toLowerCase() || "";
    const lastNameA = a?.last_name?.toLowerCase() || "";
    const lastNameB = b?.last_name?.toLowerCase() || "";

    if (firstNameA < firstNameB) return -1;
    if (firstNameA > firstNameB) return 1;

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;

    return 0;
  });
  return payRangeList;
});

onMounted(async () => {
  proxy.$loader.show();
  proxy.$loader.hide();
});
</script>

<style scoped>
.icon-container {
  cursor: pointer;
}
</style>
